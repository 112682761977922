import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 80" height="100%">
      <g transform="translate(0,-952.36218)">
        <path
          fill={color}
          d="m 51.0938,974.54589 43,16.99998 c 1.1192,0.4346 1.9143,1.5963 1.9143,2.7969 0,1.2006 -0.7951,2.3623 -1.9143,2.7969 l -3.0938,1.2187 0,11.06253 c 1.1994,0.919 2,2.3329 2,3.9375 0,2.7318 -2.2682,5 -5,5 -2.7318,0 -5,-2.2682 -5,-5 0,-1.6046 0.8006,-3.0185 2,-3.9375 l 0,-8.6875 -5,1.9688 0,14.6562 c 0,2.4849 -1.4767,4.2017 -3.1875,5.625 -1.7108,1.4233 -3.9134,2.6457 -6.5625,3.7187 -5.2981,2.146 -12.3823,3.6563 -20.25,3.6563 -7.8677,0 -14.9519,-1.5103 -20.25,-3.6563 -2.6491,-1.073 -4.8517,-2.2954 -6.5625,-3.7187 C 21.4767,1021.5601 20,1019.8433 20,1017.3584 l 0,-14.6562 -14.0938001,-5.56253 c -1.1192,-0.4346 -1.9143,-1.5963 -1.9143,-2.7969 0,-1.2006 0.7951,-2.3623 1.9143,-2.7969 L 48.9062,974.54589 c 0.8098,-0.29637 1.665,-0.17591 2.1876,0 z M 50,980.57714 15.1562,994.35837 50,1008.1084 84.8438,994.35837 z m 24,24.50006 -22.9062,9.0625 c -0.695,0.2721 -1.4926,0.2721 -2.1876,0 L 26,1005.0772 l 0,12.2812 c 0,-0.3917 0.017,0.1559 1.0312,1 1.0146,0.8441 2.769,1.8775 5,2.7813 4.4624,1.8074 10.8785,3.2187 17.9688,3.2187 7.0903,0 13.5064,-1.4113 17.9688,-3.2187 2.231,-0.9038 3.9854,-1.9372 5,-2.7813 0.7608,-0.6331 0.9789,-1.0967 1.0312,-1.125 z"
          fillOpacity="1"
          stroke="none"
          visibility="visible"
          display="inline"
          overflow="visible"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
