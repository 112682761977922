import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 460"
      xmlSpace="preserve"
      fill={color}
      height="100%"
  >
      <g>
        <path
          d="M146.7,152.3c-0.6-3.2-2.7-5.9-5.7-7.2c-2.9-1.3-6.4-1.2-9.2,0.4l-67.8,39.2c-2.8,1.6-4.6,4.5-5,7.7s1,6.4,3.4,8.5   c9.3,8.1,21.3,12.6,33.7,12.6c9,0,17.9-2.4,25.7-6.9C140.9,195.6,150.9,173.8,146.7,152.3z M111.9,189.3   c-4.8,2.8-10.2,4.2-15.7,4.2c-2.1,0-4.3-0.2-6.3-0.6L126,172C123.6,179.1,118.7,185.3,111.9,189.3z"
      />
        <path
          d="M503.5,224.8c-50.7-61.9-114.1-93.3-188.5-93.5c-3-18.1-7.1-36.8-12.2-55.8c-0.8-3.1-3.1-5.6-6.1-6.8   c-32.3-12.2-64.1-18.3-94.5-18.3c-69.5,0-134.5,32-193.3,95.1c-2.3,2.5-3.2,6.1-2.3,9.4C25.8,226.8,55.1,286,91.2,326   c33,36.6,71.5,56.7,108.5,56.7c2.9,0,5.9-0.1,8.8-0.4c5.5,13.2,12.3,25.2,20.3,35.7c21.5,28,51.2,43.5,83.5,43.5   c37,0,75.5-20.2,108.5-56.8c36.1-40.1,65.4-99.2,84.6-171.1C506.3,230.6,505.5,227.3,503.5,224.8z M210.9,150.7   c0,0.1-0.1,0.1-0.2,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.8-0.6-1.5-1.1-2.2-1.8h3.5C211.3,150.2,211.1,150.5,210.9,150.7z M190.7,284.3   l-43.1,11.5c6.4-20.4,22.8-36.6,44-42.3c0,0,0,0,0,0C191,264.4,190.7,274.6,190.7,284.3z M199.7,362.8   c-53.3,0-129.9-54.5-172.3-207.6C81.2,98.9,140,70.4,202.2,70.4c26.5,0,54.3,5.1,82.7,15.3c4.1,15.7,7.4,31.2,10,46.3   c-6.2,0.4-12.6,1.1-19,2c-1.9-2.4-4.8-3.9-7.9-3.9h-78.3c-3.2,0-6.3,1.6-8.1,4.2s-2.4,6-1.3,9.1c4.3,12.4,13.1,22.6,24.5,28.6   c-5.2,21.9-8.7,42.1-10.9,60.8c-2.4,0.4-4.8,0.9-7.2,1.6c-34.3,9.2-59.1,39.2-61.6,74.7c-0.2,3.2,1.1,6.4,3.6,8.4   c1.8,1.5,4.1,2.3,6.4,2.3c0.9,0,1.7-0.1,2.6-0.3l53.9-14.5c0.6,8,1.4,15.6,2.4,22.7c1.8,12.4,4.3,24.2,7.6,35.3   C200.9,362.8,200.3,362.8,199.7,362.8z M312.3,441.6c-26.3,0-49.7-12.3-67.6-35.7c-8-10.5-14.6-22.7-19.8-36.3   c-0.1-0.5-0.2-1-0.4-1.5c-6.6-17.8-12.3-42.4-13.5-74.7c0-0.2,0-0.3,0-0.5c0-0.4,0.1-0.8,0.1-1.3c-0.5-15.5,0.2-32,1.9-49   c0-0.1,0-0.2,0-0.3c2.4-23.2,6.7-47.7,13.1-73.3c0.1-0.2,0.1-0.4,0.2-0.6l0.4-1.8c0.2-0.6,0.3-1.3,0.5-1.9c0.3-0.1,0.5-0.1,0.8-0.2   c1.6-0.4,3.1-0.9,4.7-1.3c0.7-0.2,1.4-0.4,2.1-0.6c1.1-0.3,2.2-0.6,3.4-0.9c0.7-0.2,1.5-0.4,2.2-0.6c1.1-0.3,2.1-0.5,3.2-0.8   c0.4-0.1,0.9-0.2,1.3-0.3c0.3-0.1,0.6-0.1,0.9-0.2c1.1-0.3,2.1-0.5,3.2-0.7c0.7-0.2,1.5-0.3,2.2-0.5c1.1-0.2,2.1-0.5,3.2-0.7   c0.7-0.1,1.4-0.3,2.1-0.4c1.2-0.2,2.3-0.5,3.5-0.7c0.6-0.1,1.2-0.2,1.7-0.3c1.5-0.3,3-0.6,4.5-0.8c0.2,0,0.4-0.1,0.7-0.1   c16.5-2.8,32.5-4.3,47.7-4.3c67.7,0,123.5,26.9,170.3,82.2C442.4,387,365.6,441.6,312.3,441.6z"
      />
        <path
          d="M312.4,266c1.4,0,2.8-0.3,4.1-0.9c2.9-1.3,5.1-4,5.7-7.2c4.2-21.5-5.8-43.3-24.8-54.3c-7.8-4.5-16.7-6.9-25.7-6.9   c-12.4,0-24.4,4.5-33.7,12.6c-2.4,2.1-3.7,5.3-3.4,8.5c0.3,3.2,2.2,6.1,5,7.7l67.8,39.2C309,265.5,310.7,266,312.4,266z    M265.5,217.3c2.1-0.4,4.2-0.6,6.3-0.6c5.5,0,10.9,1.5,15.7,4.2c6.8,3.9,11.7,10.1,14.1,17.3L265.5,217.3z"
      />
        <path
          d="M404.3,232.2c-21.9,0-41.5,13.9-48.6,34.6c-1.1,3.1-0.6,6.4,1.3,9.1c1.9,2.6,4.9,4.2,8.1,4.2h78.3c0,0,0,0,0,0   c5.5,0,10-4.5,10-10c0-1.4-0.3-2.7-0.8-3.9C445.4,245.8,426,232.2,404.3,232.2z M383.5,260.1c5.6-5,13-7.9,20.8-7.9   c7.9,0,15.2,2.9,20.8,7.9H383.5z"
      />
        <path
          d="M392.4,330.9L258.5,295c-3.1-0.8-6.5-0.1-9,1.9c-2.5,2.1-3.8,5.2-3.6,8.4c2.5,35.5,27.3,65.5,61.6,74.6   c7.1,1.9,14.4,2.9,21.6,2.9c27.7,0,53.5-13.7,69-36.7c1.8-2.7,2.2-6.1,1.1-9.1C398.1,334.1,395.5,331.8,392.4,330.9z M329.1,362.9   c-5.5,0-11.1-0.7-16.5-2.2c-21.2-5.7-37.7-21.9-44-42.2l103.3,27.7C360.4,356.8,345.2,362.9,329.1,362.9z"
      />
      </g>
    </svg>

  )
}

export default SvgComponent
