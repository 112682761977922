import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg height="100%" viewBox="0 0 38 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.7187 14.2682C28.033 14.2682 29.0985 13.1993 29.0985 11.8808C29.0985 10.5623 28.033 9.49341 26.7187 9.49341C25.4043 9.49341 24.3389 10.5623 24.3389 11.8808C24.3389 13.1993 25.4043 14.2682 26.7187 14.2682Z"
        fill={color}
      />
      <path
        d="M33.8216 14.011C34.2243 13.6437 34.2243 13.0193 33.8216 12.6153C33.4188 12.2113 32.833 12.2113 32.4303 12.6153L29.6111 15.4067C29.4281 15.1496 29.1352 15.0026 28.8057 15.0026H24.6684C24.3389 15.0026 24.046 15.1496 23.863 15.4067L21.0072 12.6153C20.6044 12.248 19.982 12.248 19.6159 12.6153C19.2498 12.9826 19.2498 13.6437 19.6159 14.011L23.6067 17.9777V19.5937L21.776 22.8993C21.7028 23.0462 21.8127 23.1932 21.9591 23.1932H24.3755V28.1883C24.3755 28.7392 24.8149 29.18 25.3641 29.18C25.9133 29.18 26.3526 28.7392 26.3526 28.1883V23.1932H26.7554H27.1581V28.1883C27.1581 28.7392 27.5974 29.18 28.1466 29.18C28.6958 29.18 29.1352 28.7392 29.1352 28.1883V23.1932H31.5516C31.6981 23.1932 31.8079 23.0095 31.7347 22.8993L29.904 19.5937V17.9777L33.8216 14.011ZM26.7187 20.6221C26.7187 20.6221 24.7417 19.4468 24.7417 18.0511C24.7417 17.5002 25.181 17.0595 25.7302 17.0595C26.2794 17.0595 26.7187 17.5002 26.7187 18.0511C26.7187 17.5002 27.1581 17.0595 27.7073 17.0595C28.2565 17.0595 28.6958 17.5002 28.6958 18.0511C28.6958 19.4468 26.7187 20.6221 26.7187 20.6221Z"
        fill={color}
      />
      <path
        d="M11.0483 14.2682C12.3626 14.2682 13.4281 13.1993 13.4281 11.8808C13.4281 10.5623 12.3626 9.49341 11.0483 9.49341C9.73394 9.49341 8.66846 10.5623 8.66846 11.8808C8.66846 13.1993 9.73394 14.2682 11.0483 14.2682Z"
        fill={color}
      />
      <path
        d="M18.1512 12.6153C17.7851 12.2113 17.1626 12.2113 16.7599 12.6153L13.9407 15.4067C13.7577 15.1496 13.4648 15.0026 13.1353 15.0026H9.03466C8.70514 15.0026 8.41224 15.1496 8.22918 15.4067L5.41001 12.6153C5.00727 12.248 4.38486 12.248 4.01873 12.6153C3.65261 12.9826 3.65261 13.6437 4.01873 14.011L8.0095 17.9777V22.1648V22.2015V28.1883C8.0095 28.629 8.26579 29.033 8.66853 29.1432C9.32756 29.3636 9.98658 28.8494 9.98658 28.225V24.2583C9.98658 23.6706 10.4625 23.2299 11.0117 23.2299H11.1582C11.744 23.2299 12.1833 23.7074 12.1833 24.2583V28.1883C12.1833 28.629 12.4396 29.033 12.8424 29.1432C13.5014 29.3636 14.1604 28.8494 14.1604 28.225V22.2015V18.0144L18.1512 14.0477C18.5173 13.6437 18.5173 13.0193 18.1512 12.6153ZM11.085 20.5487C11.085 20.5487 9.10788 19.3734 9.10788 17.9777C9.10788 17.4267 9.54723 16.986 10.0964 16.986C10.6456 16.986 11.085 17.4267 11.085 17.9777C11.085 17.4267 11.5243 16.986 12.0735 16.986C12.6227 16.986 13.062 17.4267 13.062 17.9777C13.062 19.3734 11.085 20.5487 11.085 20.5487Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
