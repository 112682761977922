import React, { createContext, useContext, useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  useAuthenticateUserWithPasswordMutation,
  useGetAuthenticatedUserQuery,
  UserFragment,
} from '../generated/graphql'
import { useSessionUser } from './sessionContext'

type CurrentUserContextType = {
  user: UserFragment | undefined
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  loading: boolean
}

const defaultValue: CurrentUserContextType = {
  user: undefined,
  login: async () => undefined,
  logout: () => undefined,
  loading: false,
}

const currentUserContext = createContext<CurrentUserContextType>(defaultValue)

export const CurrentUserContextProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient()
  const { connectUser, disconnectUser } = useSessionUser()

  const { mutateAsync } = useAuthenticateUserWithPasswordMutation()

  const { data, isLoading } = useGetAuthenticatedUserQuery()

  const history = useHistory()

  const login = async (email: string, password: string) => {
    const res = await mutateAsync({ email, password })
    await queryClient.refetchQueries(['user'], { active: true })
    return connectUser(res.authenticateUserWithPassword.token)
  }

  const logout = () => {
    history.replace('/')
    return disconnectUser()
  }

  return (
    <currentUserContext.Provider
      value={{
        user: data?.authenticatedUser,
        login,
        logout,
        loading: isLoading,
      }}>
      {children}
    </currentUserContext.Provider>
  )
}

export const useCurrentUser = () => {
  const context = useContext(currentUserContext)
  return context
}
