import { Box, Stack } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/react'
import React from 'react'
import { useCharities } from '../../contexts/charitiesContext'
import CategoryCard, { Category } from '../molecules/CategoryCard'

const CategoriesList: React.FC = () => {
  const { clickedCategories, onClickCategory, categories, loadingCategories } =
    useCharities()

  if (loadingCategories) {
    return (
      <Stack flexDirection='row' justifyContent='space-around'>
        <Skeleton
          height={['70px', '80px']}
          width={['70px', '80px']}
          borderRadius='16px'
          marginTop='8px'
        />
        <Skeleton
          height={['70px', '80px']}
          width={['70px', '80px']}
          borderRadius='16px'
        />
        <Skeleton
          height={['70px', '80px']}
          width={['70px', '80px']}
          borderRadius='16px'
        />
        <Skeleton
          height={['70px', '80px']}
          width={['70px', '80px']}
          borderRadius='16px'
        />
        <Skeleton
          height={['70px', '80px']}
          width={['70px', '80px']}
          borderRadius='16px'
        />
      </Stack>
    )
  }

  return (
    <Box display='flex' flexWrap='wrap' width='100%' justifyContent='center'>
      {categories.map((category, index) => {
        return (
          <CategoryCard
            key={index}
            reference={category?.reference as Category}
            categoryName={category?.name}
            lightColor={category?.lightColor}
            darkColor={category?.darkColor}
            onClick={() => onClickCategory(category?.name)}
            isActive={
              clickedCategories.length === 0 ||
              clickedCategories.includes(category?.name!)
            }
          />
        )
      })}
    </Box>
  )
}

export default CategoriesList
