import React from 'react'

const WebsiteIcon: React.FC = () => {
  return (
    <svg
      width="39"
      height="50"
      viewBox="0 0 39 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.5 1C9.28198 1 0.974976 9.52 0.974976 20C0.974976 30.48 9.28198 39 19.5 39C29.718 39 38.025 30.48 38.025 20C38.025 9.52 29.718 1 19.5 1ZM23.478 10.88C22.191 11.12 20.865 11.24 19.5 11.24C18.135 11.24 16.809 11.12 15.522 10.88C16.536 6.16 18.213 3.36 19.5 3.36C20.787 3.36 22.464 6.16 23.478 10.88ZM23.439 3.88C26.247 4.6 28.782 6.08 30.81 8.12C29.328 9.08 27.612 9.84 25.74 10.36C25.194 7.8 24.414 5.56 23.439 3.88ZM14.664 18.8C14.703 16.76 14.859 14.88 15.132 13.2C16.575 13.44 18.018 13.6 19.539 13.6C21.021 13.6 22.503 13.44 23.946 13.2C24.18 14.88 24.336 16.76 24.414 18.8H14.664ZM24.336 21.2C24.297 23.24 24.141 25.12 23.907 26.8C22.464 26.56 21.021 26.4 19.5 26.4C18.018 26.4 16.536 26.56 15.093 26.8C14.859 25.12 14.703 23.24 14.664 21.2H24.336ZM13.26 10.36C11.388 9.84 9.67198 9.08 8.18998 8.12C10.218 6.08 12.753 4.64 15.561 3.88C14.586 5.56 13.806 7.8 13.26 10.36ZM12.87 12.72C12.558 14.68 12.402 16.76 12.363 18.84H3.39298C3.62698 15.52 4.83598 12.44 6.70798 9.92C8.42398 11.12 10.569 12.08 12.87 12.72ZM12.324 21.2C12.363 23.28 12.558 25.32 12.831 27.32C10.53 27.96 8.42398 28.88 6.62998 30.12C4.75798 27.6 3.58798 24.52 3.35398 21.2H12.324ZM13.26 29.64C13.806 32.2 14.586 34.48 15.561 36.12C12.753 35.4 10.218 33.92 8.18998 31.88C9.67198 30.92 11.388 30.16 13.26 29.64ZM15.522 29.12C16.809 28.88 18.135 28.76 19.5 28.76C20.865 28.76 22.191 28.88 23.478 29.12C22.464 33.84 20.787 36.64 19.5 36.64C18.213 36.64 16.536 33.84 15.522 29.12ZM25.74 29.64C27.612 30.16 29.328 30.92 30.81 31.92C28.782 33.96 26.247 35.4 23.439 36.16C24.414 34.44 25.194 32.2 25.74 29.64ZM26.13 27.28C26.442 25.32 26.598 23.24 26.637 21.16H35.607C35.373 24.48 34.164 27.56 32.292 30.08C30.537 28.88 28.431 27.92 26.13 27.28ZM26.676 18.8C26.637 16.72 26.442 14.68 26.169 12.68C28.47 12.04 30.576 11.12 32.37 9.88C34.242 12.4 35.451 15.44 35.685 18.8H26.676Z"
          fill="#FF636F"
        />
      </g>
    </svg>
  )
}

export default WebsiteIcon
