import React from 'react'
import * as Yup from 'yup'

const UpdateMissionValidationSchema = Yup.object().shape({
  name: Yup.string().max(40, 'Le nom de la mission est trop long!'),
  description: Yup.string().min(20, 'Description trop courte!'),
  address: Yup.string().min(10, 'Adresse trop courte!'),
  date: Yup.date(),
  duration: Yup.string(),
  category: Yup.array().min(0, ''),
})

export default UpdateMissionValidationSchema
