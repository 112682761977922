import React from 'react'

type props = {
  color: string
}

const Elderly: React.FC<props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 103.854 235"
      fill={color}
      height="100%"
    >
      <g>
        <path d="M89.212,108.663c2.772,1.659,4.634,4.68,4.641,8.146v113.5c0,2.763,2.24,5,5,5c2.763,0,5-2.237,5-5v-113.5   c-0.001-8.254-5.136-15.293-12.38-18.139c0.497,0.972,0.808,2.059,0.869,3.22C92.487,104.628,91.235,107.113,89.212,108.663z" />
        <path d="M74.854,116.81c0.004-2.469,0.955-4.71,2.499-6.398c-3.941-0.073-7.631-0.379-11.068-0.915   c-0.915,2.259-1.431,4.724-1.431,7.313c0,2.761,2.238,5,5,5C72.615,121.81,74.854,119.57,74.854,116.81z" />
        <ellipse
          transform="matrix(0.2268 -0.9739 0.9739 0.2268 22.2427 73.0167)"
          cx="57.108"
          cy="22.5"
          rx="22.494"
          ry="22.495"
        />
        <path d="M19.089,154.044l9.979,70.515c0.883,6.242,6.234,10.749,12.361,10.749c0.583,0,1.173-0.041,1.767-0.125   c6.835-0.967,11.592-7.292,10.625-14.127l-12.67-89.542c0.01-1.283-0.042-2.574-0.164-3.859   c-0.986-9.639-0.558-19.051,1.058-27.996c-3.337-2.595-6.093-5.502-8.259-8.532c-7.647-10.758-8.544-22.231-8.58-28.104   c0-2.398,0.174-3.916,0.206-4.181c0.532-4.386,4.52-7.511,8.905-6.979c4.386,0.532,7.511,4.52,6.979,8.905v0.014   c-0.008,0.07-0.02,0.209-0.031,0.406c-0.028,0.395-0.058,1.023-0.056,1.834c-0.014,3.013,0.448,8.473,2.707,13.673   c0.812,1.837,1.802,3.639,3.063,5.36c0.586-1.515,1.197-3.015,1.855-4.484c4.449-10.154,2.842-22.5-5.148-30.448   c-7.852-7.87-22.356-5.024-29.737,9.791C3.912,77.349-1.11,100.837,0.207,125.216C0.854,139.29,9.816,150.951,19.089,154.044z" />
        <path d="M56.6,92.24c-4.323-2.036-7.747-4.639-10.444-7.977c-1.604-1.984-2.951-4.226-4.074-6.767   c-2.627-6.043-2.886-12.141-2.874-14.468c-0.002-0.892,0.032-1.567,0.059-1.958c0.014-0.246,0.03-0.418,0.042-0.541   c0.193-1.591-0.245-3.16-1.233-4.422c-0.987-1.261-2.408-2.063-4-2.256c-0.247-0.029-0.491-0.045-0.734-0.045   c-3.024,0-5.58,2.27-5.943,5.273c-0.045,0.375-0.192,1.771-0.192,3.933c0.063,10.311,2.825,19.382,8.206,26.954   c1.979,2.769,4.356,5.285,7.055,7.491c2.727,2.229,5.782,4.142,9.092,5.671c4.606,2.139,9.833,3.628,15.605,4.479   c3.762,0.554,7.749,0.844,11.966,0.843c0.244,0,0.496-0.008,0.742-0.01c1.575-0.012,3.18-0.055,4.799-0.139   c0.835-0.043,1.621-0.258,2.328-0.606c2.087-1.028,3.477-3.231,3.347-5.699c-0.09-1.74-0.917-3.271-2.166-4.305   c-1.04-0.861-2.37-1.38-3.82-1.38c-0.104,0-0.213,0.002-0.318,0.008c-1.66,0.09-3.312,0.133-4.911,0.133   C70.192,96.447,62.616,95.031,56.6,92.24z" />
      </g>
    </svg>
  )
}

export default Elderly
