/* eslint-disable quotes */
import React, { useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Link,
  Button,
  Input,
  InputRightElement,
  InputGroup,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react'
import { Link as RouteLink, useHistory } from 'react-router-dom'
import { FormikHelpers, useFormik } from 'formik'
import { FormLabel } from '@chakra-ui/react'
import { useCurrentUser } from '../contexts/currentUserContext'
import LoginValidationSchema from '../utils/validationSchemas/LoginValidationSchema'
import mainTheme from '../theme/mainTheme'
import EyeIcon from '../static/svg/EyeIcon'
import ErrorModal from '../components/organisms/ErrorModal'

interface Values {
  email: string
  password: string
}

function LoginPage() {
  const history = useHistory()
  const { login } = useCurrentUser()
  const [showPassword, setShowPassword] = useState(false)
  const [loginError, setLoginError] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState<string | null>(null)

  const handleClick = () => setShowPassword(!showPassword)

  const formik = useFormik<Values>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginValidationSchema,
    onSubmit: async (values, { setSubmitting }: FormikHelpers<Values>) => {
      try {
        await login(values.email, values.password)
        setSubmitting(false)
        history.replace('/back-office')
      } catch (err) {
        if (
          String(err).includes(`email provided didn't identify`) ||
          String(err).includes('passwordAuth:secret:mismatch') ||
          String(err).includes('Authentication failed')
        ) {
          setError('Erreur: email ou mot de passe incorrect')
          setLoginError(true)
        }
        if (err.message.includes('Failed to fetch')) {
          setError(
            'Oh non ! Il y a une erreur de notre part. Excusez-nous pour la gêne occasionnée. Revenez plus tard.'
          )
        }
      }
    },
  })

  return (
    <Flex
      padding={[
        '12px 32px 10px 32px',
        '16px 50px 15px 50px',
        '21px 100px 20px 100px',
        '45px 200px 50px 200px',
      ]}
      flexGrow={1}>
      <Flex flexDirection='column' flexGrow={1} justifyContent='space-between'>
        <Box>
          <Text textStyle='h2' textAlign='center'>
            Se connecter en tant qu'association
          </Text>
          <Flex flexDirection='column' alignItems='center'>
            <Flex padding={30} maxWidth='520px' width='100%'>
              <ErrorModal
                isOpen={!!error}
                error={error}
                onClose={() => setError(null)}
              />

              <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                <FormControl
                  isInvalid={!!formik.errors.email && formik.touched.email}>
                  <FormLabel htmlFor='email'>Email </FormLabel>
                  <Input
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    id='email'
                    name='email'
                    placeholder='Entrez email'
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={
                    (!!formik.errors.password || loginError) &&
                    formik.touched.password
                  }>
                  <FormLabel htmlFor='password' mt={2}>
                    Mot de passe
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type={showPassword ? 'text' : 'password'}
                      id='password'
                      name='password'
                      placeholder='Entrez mot de passe'
                    />
                    <InputRightElement width='3.5rem'>
                      <Button
                        onClick={handleClick}
                        pt='7px'
                        _focus={{ outline: 0 }}>
                        <EyeIcon color={mainTheme.colors.darkGrey} />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                </FormControl>
                <Flex mt='10' justifyContent='center'>
                  <Button
                    color={mainTheme.colors.primaryBlue}
                    backgroundColor={mainTheme.colors.secondaryBlue}
                    type='submit'
                    width='100%'
                    p={6}
                    _focus={{ outline: 0 }}
                    _hover={{
                      borderColor: mainTheme.colors.primaryBlue,
                    }}>
                    ENVOYER
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Box>

        <Flex flexDirection='column' alignItems='center'>
          <Text textStyle='h3' textAlign='center'>
            Vous n'avez pas encore de compte Association ?
          </Text>
          <Link
            as={RouteLink}
            fontSize='16'
            to='/create-account'
            margin='4'
            padding='2'
            textDecoration='none !important'
            _focus={{ outline: 0 }}
            borderRadius={16}
            fontWeight='bold'
            height='40px'
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <Text textStyle='h4' fontWeight='bold'>
              Créer un compte
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LoginPage
