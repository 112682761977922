import React from 'react'

const PinIcon: React.FC = () => {
  return (
    <svg
      width="27"
      height="35"
      viewBox="0 0 27 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 0C20.9071 0 27 5.75658 27 13.0099C27 18.8816 22.9381 22.4507 19.4735 27.0559L13.5 35L7.40708 27.0559C3.94248 22.4507 0 18.8816 0 13.0099C0 5.75658 5.97345 0 13.5 0ZM13.5 7.13816C16.8451 7.13816 19.4735 9.78618 19.4735 13.0099C19.4735 16.2336 16.8451 18.7664 13.5 18.7664C10.1549 18.7664 7.40708 16.2336 7.40708 13.0099C7.40708 9.78618 10.1549 7.13816 13.5 7.13816Z"
        fill="#FF636F"
      />
    </svg>
  )
}

export default PinIcon
