import React from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Flex, Link, Text, Box } from '@chakra-ui/react'
import Card from '../components/molecules/Card'
import { useAllElements } from '../contexts/allElementsContext'

const SearchPage = () => {
  const { charities, missions, searchedElement } = useAllElements()

  if ((charities.length <= 0 && missions.length <= 0) || !searchedElement) {
    return (
      <Box
        padding={[
          '12px 32px 10px 32px',
          '21px 100px 20px 100px',
          '45px 200px 50px 200px',
        ]}
      >
        <Text textStyle="h2" margin="20px 0px 20px 0px">
          Aucun résultat pour votre recherche
        </Text>
      </Box>
    )
  }

  return (
    <Box
      padding={[
        '12px 32px 10px 32px',
        '21px 100px 20px 100px',
        '45px 200px 100px 200px',
      ]}
    >
      <Text textStyle="h2" margin="20px 0px 20px 0px">
        Résultats de la recherche
      </Text>
      <Flex wrap="wrap">
        {charities.map((charity, index) => {
          return (
            <Link
              key={index}
              as={RouteLink}
              to={`/association/${charity.id}`}
              _hover={{ textDecoration: 'none' }}
            >
              <Card
                name={charity.name}
                description={charity.description}
                image={charity.image.publicUrlTransformed}
                height={[120, 177]}
              />
            </Link>
          )
        })}

        {missions.map((mission, index) => {
          return (
            <Link
              key={index}
              as={RouteLink}
              to={`/mission/${mission.id}`}
              _hover={{ textDecoration: 'none' }}
            >
              <Card
                name={mission.name}
                description={mission.description}
                image={mission.image.publicUrlTransformed}
                height={[120, 177]}
              />
            </Link>
          )
        })}
      </Flex>
    </Box>
  )
}

export default SearchPage
