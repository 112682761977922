import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      x="0px"
      y="0px"
      height="100%"
      fill={color}>
      <g
    >
        <path
          d="M28,9a5,5,0,1,0-5,5A5,5,0,0,0,28,9ZM20,9a3,3,0,1,1,3,3A3,3,0,0,1,20,9Z"
      />
        <path
          d="M15,17a4,4,0,1,0-4,4A4,4,0,0,0,15,17Zm-4,2a2,2,0,1,1,2-2A2,2,0,0,1,11,19Z"
      />
        <path
          d="M8,27a3,3,0,1,0,3,3A3,3,0,0,0,8,27Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,8,31Z"
      />
        <path
          d="M15,36a3,3,0,1,0,3,3A3,3,0,0,0,15,36Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,15,40Z"
      />
        <path
          d="M42.49.11a3.07,3.07,0,0,0-3.86,1.83l-2,5.44a13.77,13.77,0,0,0-5.26-5.76C23.27-3.07,11.93,3.38,6.46,9.45-1.26,18-2.18,31.16,4.36,39.38,8.1,44.09,15,48,21.05,48A11.53,11.53,0,0,0,25,47.34c1.57-.56,5.26-2.23,5.79-5.56a5.73,5.73,0,0,0-.38-3l0,0,4.34-3.52A7.81,7.81,0,0,0,37.55,28l-.06-.36,7-23.75A3.07,3.07,0,0,0,42.49.11ZM28.81,41.47c-.29,1.86-2.51,3.28-4.49,4-5.84,2.1-14.22-2.08-18.39-7.32C0,30.66.86,18.64,7.94,10.79,12.82,5.38,23.31-.73,30.32,3.35A12.49,12.49,0,0,1,35.56,10s0,0,0,0L31,22.23c-.9.39-1.87.73-2.81,1.06-3.19,1.09-6.48,2.23-7.56,5.62C20,31,20.08,34.05,22,35.69a5.07,5.07,0,0,0,2.84,1.12,4.4,4.4,0,0,1,2.34.83A4.29,4.29,0,0,1,28.81,41.47Zm-1.28-8.71L28.31,36a6.5,6.5,0,0,0-3.22-1.2,3.23,3.23,0,0,1-1.82-.64c-1.13-1-1.22-3.15-.73-4.67.77-2.44,3.33-3.32,6.3-4.34l1.22-.43-.14.37-.32.32A7.83,7.83,0,0,0,27.53,32.76Zm6,1-3.09,2.53-1-4A5.86,5.86,0,0,1,31,26.85l0,0,2.76.92,1.77.59A5.79,5.79,0,0,1,33.51,33.72Zm9-30.39-6.75,23-3-1-.68-.22L40.51,2.64A1.07,1.07,0,0,1,41.85,2,1.09,1.09,0,0,1,42.55,3.33Z"
      />
      </g>
    </svg>

  )
}

export default SvgComponent
