import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  useDisclosure,
  Spinner,
  Image,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link as RouteLink } from 'react-router-dom'
import ModalDeleteMission from '../components/organisms/ModalDeleteMission'
import { useCurrentUser } from '../contexts/currentUserContext'
import BinIcon from '../static/svg/BinIcon'
import ModifyIcon from '../static/svg/ModifyIcon'
import PlusIcon from '../static/svg/PlusIcon'
import mainTheme from '../theme/mainTheme'
import moment from 'moment'
import 'moment/locale/fr'
import EyeIcon from '../static/svg/EyeIcon'
import { UserFragment } from '../generated/graphql'
moment.locale('fr')

const UserBackOffice: React.FC = () => {
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user, loading, logout } = useCurrentUser()
  const [error, setError] = useState(false)

  const returnError = () => {
    if (!user) setError(true)
  }

  useEffect(() => {
    const refetchUser = async () => {
      // TODO: think to optimise refetch
      await queryClient.refetchQueries()
    }
    refetchUser()
  }, [user])

  useEffect(() => {
    setTimeout(() => returnError(), 5000)
  }, [])

  if (loading) {
    return (
      <Flex justifyContent='center' height='100%' alignItems='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color={mainTheme.colors.primaryBlue}
          size='xl'
        />
      </Flex>
    )
  }

  if (!user && !error) {
    return (
      <Flex justifyContent='center' alignItems='center' height='100%'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color={mainTheme.colors.primaryBlue}
          size='xl'
        />
      </Flex>
    )
  }

  if (!user && error) {
    return (
      <>
        <Text textAlign='center' textStyle='notFound' paddingTop='50px'>
          Oups ! Il y a un problème de notre côté 🤭 &nbsp; Essayez de vous
          déconnecter et de vous reconnecter
        </Text>
        <Flex justifyContent='center' marginBottom='10'>
          <Button onClick={logout}>Se déconnecter</Button>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Flex
        padding={[
          '12px 32px 10px 32px',
          '16px 50px 15px 50px',
          '21px 100px 20px 100px',
          '45px 200px 50px 200px',
        ]}
        flexGrow={1}
        flexDirection='column'>
        <Flex justifyContent='center'>
          <Text
            textStyle='h2'
            textAlign='center'
            mb={30}
            color={mainTheme.colors.primaryBlue}>
            Bienvenue &nbsp;
          </Text>
          <Text
            minWidth='fit-content'
            textStyle='h2'
            textAlign='center'
            mb={30}>
            {user.charity.name}
          </Text>
        </Flex>
        {user.charity.missions.length <= 0 ? (
          <Flex
            pb={200}
            justifyContent='center'
            textAlign='center'
            textStyle='h3'>
            <Text>
              Vous n'avez pas encore de mission 🙃
              <br />
              Pour créer votre première mission, cliquez &nbsp;
              <Link
                as={RouteLink}
                to='/creation-mission'
                textDecoration='none !important'
                _focus={{ outline: 0 }}
                textDecor='underline'>
                ici
              </Link>
              &nbsp; 😉
            </Text>
          </Flex>
        ) : (
          <Box>
            <Text mb={30} textStyle='h2'>
              Vos missions
            </Text>
            <Box>
              {user.charity.missions.map((mission, index) => (
                <Flex
                  key={index}
                  alignItems='center'
                  width='auto'
                  justifyContent='space-between'>
                  <Flex alignItems='center'>
                    <Image
                      boxSize={['80px', '150px']}
                      src={mission.image.publicUrlTransformed}
                      alt='image mission'
                      mb={2}
                      mt={2}
                    />
                    <Flex
                      marginLeft='20px'
                      alignItems={['start', 'start', 'start', 'center']}
                      flexDirection={['column', 'column', 'column', 'row']}
                      ml={[5]}>
                      <Text textStyle={['body', 'h4']} mr={5} ml={[2, 2, 5]}>
                        {mission.name.substring(0, 20)}
                        {`${mission.name.length > 20 ? '...' : ''}`}
                      </Text>
                      <Text
                        textStyle={['cardText', 'body']}
                        ml={[2, 2, 5]}
                        mr={2}>
                        {moment(mission.date).format('L')}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex key={index} alignItems='center'>
                    <Link
                      as={RouteLink}
                      to={`/mission/${mission.id}`}
                      display='flex'
                      alignItems='center'
                      pt='10px'
                      mr='10px'
                      _hover={{ textDecoration: 'none' }}>
                      <EyeIcon color={mainTheme.colors.primaryBlue} />
                    </Link>

                    <Flex>
                      <Link
                        as={RouteLink}
                        to={`/modification-mission/${mission.id}`}
                        textDecoration='none !important'
                        _focus={{ outline: 0 }}
                        alignItems='center'
                        display='flex'
                        backgroundColor='#EDF2F6'
                        pl='16px'
                        pr='16px'
                        borderRadius='md'>
                        <ModifyIcon color={mainTheme.colors.primaryBlue} />
                      </Link>
                      <Button ml={3} onClick={onOpen}>
                        <ModalDeleteMission
                          isOpen={isOpen}
                          onClose={onClose}
                          missionId={mission.id}
                          missionName={mission.name}
                        />
                        <BinIcon color='red' />
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </Box>
            <Flex
              justifyContent='center'
              mt={5}
              mb={5}
              p={2}
              borderRadius={12}
              backgroundColor={mainTheme.colors.lightOrange}>
              <Link
                as={RouteLink}
                to='/creation-mission'
                textDecoration='none !important'
                _focus={{ outline: 0 }}
                alignItems='center'
                display='flex'>
                <PlusIcon color={mainTheme.colors.primaryBlue} />
                <Text p={2} textStyle='h3'>
                  Créer une nouvelle mission
                </Text>
              </Link>
            </Flex>
          </Box>
        )}
      </Flex>
      <Flex justifyContent='center' marginBottom='10px'>
        <Button marginBottom={['10px', '20px']} onClick={logout}>
          Se déconnecter
        </Button>
      </Flex>
      <Flex justifyContent='center'>
        <Text marginBottom='10px' textStyle='body'>
          Une question ? Besoin d'aide ? &nbsp;
        </Text>
        <Link textDecoration='underline' href='mailto: lisa-lou.k@hotmail.fr'>
          Contactez-moi
        </Link>
      </Flex>
    </>
  )
}

export default UserBackOffice
