import React from 'react'
import { Box, Image } from '@chakra-ui/react'

type CardProps = {
  image: string
  name?: string
  description?: string
  backgroundColor?: string
  maxWidth?: number
  height: number[]
  city?: string
}

const Card: React.FC<CardProps> = ({
  name,
  description,
  image,
  backgroundColor,
  maxWidth,
  height,
  city,
}) => {
  const getCityName = () => {
    if (!city) return
    const pieces = city.split(/[\s,]+/)
    return pieces[pieces.length - 2]
  }
  return (
    <Box
      display='flex'
      borderRadius='20px'
      margin={['3', '6']}
      height={height}
      minWidth={['200', '318']}
      maxWidth={maxWidth}
      border='none'
      background={backgroundColor}>
      <Image
        src={image}
        alt='image association'
        maxWidth='30%'
        minWidth='35%'
        borderRadius='20px 0px 0px 20px'
        objectFit='fill'
      />
      <Box display='flex' flexDirection='column' padding='5'>
        <Box textStyle='cardTitle'>{name}</Box>

        {city ? <Box>{getCityName()}</Box> : null}
        <Box noOfLines={[2, 3]} textStyle='cardText' textAlign='left'>
          {description}
        </Box>
      </Box>
    </Box>
  )
}

export default Card
