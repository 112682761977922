import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      width="28"
      height="100%"
      viewBox="0 0 28 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.17935 18.9052C5.17935 18.9052 17.3778 18.9052 19.0496 18.9052C20.7214 18.9052 22.0373 17.8989 22.0373 15.8867C22.0373 13.8741 20.2571 12.899 19.0032 12.899C17.7493 12.899 7.2307 15.6003 5.45048 16.297C3.67025 16.9934 2.87311 18.8744 5.17935 18.9052ZM24.6011 21.7785V27.3976H22.3721V21.7785H5.93208V27.3976H3.70307V21.7785H0.777344V20.1995H27.5268V21.7785H24.6011ZM6.41699 0.389648C8.46868 0.389648 10.1319 2.05287 10.1319 4.10489C10.1319 6.15657 8.46868 7.82013 6.41699 7.82013C4.36497 7.82013 2.70175 6.15657 2.70175 4.10489C2.70175 3.8268 2.73224 3.55634 2.79025 3.29581C3.0637 4.24575 3.93939 4.94081 4.97717 4.94081C6.23403 4.94081 7.25291 3.92193 7.25291 2.66506C7.25291 1.62729 6.55786 0.751593 5.60792 0.478146C5.86844 0.420473 6.1389 0.389648 6.41699 0.389648ZM24.727 12.829C25.9093 12.829 26.8672 13.7872 26.8672 14.9692C26.8672 16.1512 25.9093 17.1094 24.727 17.1094C23.5451 17.1094 22.5869 16.1512 22.5869 14.9692C22.5869 13.7872 23.5451 12.829 24.727 12.829Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
