import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 60"
      x="0px"
      y="0px"
      height="100%"
    >
      <g data-name="Anti-Terrorism 12">
        <path
          fill={color}
          d="M25.94,14.93h0a1,1,0,0,0-1,.87A6,6,0,1,1,18.2,9.06a1,1,0,0,0,0-2A8.49,8.49,0,0,0,17,7,10,10,0,1,0,27,17a8.49,8.49,0,0,0-.08-1.19A1,1,0,0,0,25.94,14.93ZM17,25a8,8,0,0,1-4.55-14.58A8,8,0,0,0,23.58,21.55,8,8,0,0,1,17,25Z"
        />
        <path
          fill={color}
          d="M17.64,10.16a1,1,0,0,0-.41,1l.27,1.67L16.36,14a1,1,0,0,0-.24,1,1,1,0,0,0,.8.67l1.55.24.7,1.49a1,1,0,0,0,.9.58A1,1,0,0,0,21,17.4l.7-1.49,1.54-.24A1,1,0,0,0,23.79,14l-1.15-1.17.27-1.67a1,1,0,0,0-.41-1,1,1,0,0,0-1.06-.06l-1.37.76L18.7,10.1A1,1,0,0,0,17.64,10.16Zm1.65,3.68a1,1,0,0,0,.27-.86l0-.13.05,0a1,1,0,0,0,1,0l.05,0,0,.13a1,1,0,0,0,.27.86L21,14l-.15,0a1,1,0,0,0-.76.57l0,0,0,0A1,1,0,0,0,19.3,14l-.15,0Z"
        />
        <path
          fill={color}
          d="M54.17,18l2.69-4.49A1,1,0,0,0,56,12H50.57L47.86,7.49a1,1,0,0,0-1.72,0L43.43,12H38a1,1,0,0,0-.86,1.51L39.83,18l-2.69,4.49A1,1,0,0,0,38,24h5.43l2.71,4.51a1,1,0,0,0,1.72,0L50.57,24H56a1,1,0,0,0,.86-1.51Zm.06-4L53,16.06,51.77,14Zm-2.4,4-2.4,4H44.57l-2.4-4,2.4-4h4.86ZM47,9.94,48.23,12H45.77ZM39.77,14h2.46L41,16.06Zm0,8L41,19.94,42.23,22ZM47,26.06,45.77,24h2.46ZM51.77,22,53,19.94,54.23,22Z"
        />
        <path
          fill={color}
          d="M22,42H18V38a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1v4H8a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h4v8a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V48h4a1,1,0,0,0,1-1V43A1,1,0,0,0,22,42Zm-1,4H17a1,1,0,0,0-1,1v8H14V47a1,1,0,0,0-1-1H9V44h4a1,1,0,0,0,1-1V39h2v4a1,1,0,0,0,1,1h4Z"
        />
        <path
          fill={color}
          d="M57,46.65v-.07a9.85,9.85,0,0,0-.16-1.4,10,10,0,0,0-19.66,0h0a8.46,8.46,0,0,0-.16,1.4c0,.14,0,.28,0,.42a10,10,0,0,0,20,0C57,46.88,57,46.76,57,46.65ZM42.5,45A1.5,1.5,0,1,1,41,46.5,1.5,1.5,0,0,1,42.5,45ZM47,39a8,8,0,0,1,7.87,6.54A7.37,7.37,0,0,1,55,46.65a3.5,3.5,0,0,1-7-.15A5.51,5.51,0,0,0,42.5,41a4.94,4.94,0,0,0-.87.07A8,8,0,0,1,47,39Z"
        />
        <circle fill={color} cx="51.5" cy="46.5" r="1.5" />
      </g>
    </svg>
  )
}

export default SvgComponent
