import { QueryClientProvider } from 'react-query'
import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import client from './contexts/reactQueryContext'
import { CharitiesContextProvider } from './contexts/charitiesContext'
import CharityDescription from './pages/CharityDescription'
import Home from './pages/Home'
import LoginPage from './pages/LoginPage'
import MissionDescription from './pages/MissionDescription'
import mainTheme from './theme/mainTheme'
import Missions from './pages/Missions'
import Charities from './pages/Charities'
import Header from './components/organisms/Header'
import { AllElementsContextProvider } from './contexts/allElementsContext'
import SearchPage from './pages/SearchPage'
import CreateUser from './pages/CreateUser'
import Protectedroutes from './routes/ProtectedRoutes'
import { CurrentUserContextProvider } from './contexts/currentUserContext'
import { SessionUserContextProvider } from './contexts/sessionContext'

function App() {
  return (
    <ChakraProvider theme={mainTheme}>
      <QueryClientProvider client={client}>
        <Router>
          <CharitiesContextProvider>
            <AllElementsContextProvider>
              <SessionUserContextProvider>
                <CurrentUserContextProvider>
                  <Header />
                  <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/connection' component={LoginPage} />
                    <Route path='/associations' component={Charities} />
                    <Route
                      path='/association/:assoId'
                      component={CharityDescription}
                    />
                    <Route
                      path='/mission/:missionId'
                      component={MissionDescription}
                    />
                    <Route path='/missions' component={Missions} />
                    <Route path='/search' component={SearchPage} />
                    {/* <Route path="/test" component={CreateMission} /> */}
                    <Route path='/login' component={LoginPage} />
                    <Route path='/create-account' component={CreateUser} />
                    <Protectedroutes />
                  </Switch>
                </CurrentUserContextProvider>
              </SessionUserContextProvider>
            </AllElementsContextProvider>
          </CharitiesContextProvider>
        </Router>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App
