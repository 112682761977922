import React from 'react'
import * as Yup from 'yup'

const MissionValidationSchema = Yup.object().shape({
  name: Yup.string().max(40, 'Le nom est trop long').required('Champ requis'),
  description: Yup.string()
    .min(20, 'Description trop courte!')
    .required('Champ requis'),
  address: Yup.string()
    .min(10, 'Adresse trop courte!')
    .required('Champ requis'),
  date: Yup.date().required('Champ requis'),
  duration: Yup.string().required('Champ requis'),
  category: Yup.array()
    .min(1, 'Selectionnez au moins une catégorie')
    .required('Requis'),
})

export default MissionValidationSchema
