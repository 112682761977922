import React from 'react'
import { Flex, Box } from '@chakra-ui/react'
import Logo from '../molecules/logo'
import Nav from '../organisms/Nav'
import BurgerMenu from './BurgerMenu'

const Header: React.FC = () => {
  return (
    <Box mt={['10px', '0']}>
      <Flex
        display={['none', 'flex']}
        justifyContent='space-between'
        paddingX={['18px', '28px', '38px', '48px']}
        paddingY={['3px', '5px']}>
        <Logo />
        <Nav />
      </Flex>

      <Flex
        display={['flex', 'none']}
        justifyContent='space-between'
        paddingX='48px'
        paddingY='5px'>
        <Logo />
        <BurgerMenu />
      </Flex>
    </Box>
  )
}

export default Header
