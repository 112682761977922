import React from 'react'

const LogoIcon: React.FC = () => {
  return (
    <svg
      width="33"
      height="37"
      viewBox="0 0 33 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.5"
        cy="20.1299"
        r="15.0857"
        stroke="#302929"
        strokeWidth="2.82857"
      />
      <path
        d="M9.23996 15.497C10.0502 15.497 10.7077 16.1536 10.7077 16.9568C10.7077 17.4589 11.116 17.8624 11.616 17.8624C12.1159 17.8624 12.5242 17.4589 12.5242 16.9568C12.5242 15.1515 11.0499 13.6857 9.23996 13.6857C7.43005 13.6857 5.95567 15.1515 5.95567 16.9568C5.95567 17.4589 6.36402 17.8624 6.86396 17.8624C7.36389 17.8624 7.77224 17.4589 7.77224 16.9568C7.77224 16.1536 8.42974 15.497 9.23996 15.497Z"
        fill="#302929"
        stroke="#302929"
        strokeWidth="0.628571"
      />
      <path
        d="M23.4959 13.6857C21.686 13.6857 20.2116 15.1515 20.2116 16.9568C20.2116 17.4589 20.6199 17.8624 21.1199 17.8624C21.6198 17.8624 22.0282 17.4589 22.0282 16.9568C22.0282 16.1536 22.6857 15.497 23.4959 15.497C24.3061 15.497 24.9636 16.1536 24.9636 16.9568C24.9636 17.4589 25.3719 17.8624 25.8719 17.8624C26.3718 17.8624 26.7802 17.4589 26.7802 16.9568C26.7802 15.1515 25.3058 13.6857 23.4959 13.6857Z"
        fill="#302929"
        stroke="#302929"
        strokeWidth="0.628571"
      />
      <path
        d="M24.1254 19.7911L24.3187 20.039L7.58389 20.1413C7.38193 20.3991 7.42766 20.7699 7.68665 20.9709C7.81733 21.0726 10.9542 23.4617 16.368 23.4617C21.7817 23.4617 24.9186 21.0726 25.0493 20.9709L25.242 21.2192C25.2421 21.2191 25.2422 21.219 25.2423 21.2189C25.6382 20.9113 25.7088 20.3422 25.3997 19.9478C25.0913 19.5528 24.5196 19.4837 24.1254 19.7911ZM7.33641 19.9475C7.02724 20.3423 7.09739 20.9111 7.49361 21.2189C7.49371 21.219 7.49381 21.2191 7.49392 21.2192L7.33641 19.9475ZM8.60695 19.7882C8.21216 19.484 7.64406 19.5546 7.33647 19.9475L24.1265 19.7902C24.1133 19.8003 21.2699 21.9647 16.368 21.9647C11.5533 21.9647 8.69186 19.8516 8.60695 19.7882Z"
        fill="#302929"
        stroke="#302929"
        strokeWidth="0.628571"
      />
      <path
        d="M31.5857 5.445C31.5857 5.57154 31.5088 5.92243 30.8334 6.44939C30.1755 6.96263 29.1355 7.48637 27.7241 7.95215C24.9163 8.87873 20.9478 9.47572 16.5 9.47572C12.0522 9.47572 8.08375 8.87873 5.27594 7.95215C3.86448 7.48637 2.82446 6.96263 2.16663 6.44939C1.49122 5.92243 1.41429 5.57154 1.41429 5.445C1.41429 5.31846 1.49122 4.96757 2.16663 4.44062C2.82446 3.92737 3.86448 3.40363 5.27594 2.93785C8.08375 2.01128 12.0522 1.41429 16.5 1.41429C20.9478 1.41429 24.9163 2.01128 27.7241 2.93785C29.1355 3.40363 30.1755 3.92737 30.8334 4.44062C31.5088 4.96757 31.5857 5.31846 31.5857 5.445Z"
        stroke="#302929"
        strokeWidth="2.82857"
      />
    </svg>
  )
}

export default LogoIcon
