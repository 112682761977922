import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      version="1.1"
      fill={color}
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      width={30}
>
      <g>
        <path
          d="M80.3,82c0,5.3-4.3,9.6-9.5,9.6H18c-5.3,0-9.6-4.3-9.6-9.6V29.2c0-5.3,4.3-9.6,9.6-9.6h30.6l6-6H18     c-8.6,0-15.5,7-15.5,15.5V82c0,8.6,7,15.5,15.5,15.5h52.8c8.5,0,15.5-7,15.5-15.5V45.4l-6,6V82z"
        />
        <path
          d="M94.9,15.7L84.3,5.1c-3.5-3.5-9.2-3.5-12.7,0L26,50.8c-0.4,0.4-0.7,1-0.8,1.6l-4.2,23.2c-0.2,1,0.1,2,0.8,2.6     c0.7,0.7,1.7,1,2.6,0.8l23.2-4.2c0.6-0.1,1.1-0.4,1.6-0.8l45.7-45.7C98.4,24.9,98.4,19.2,94.9,15.7z M67.4,17.8l5.3,5.3     L40.1,55.7l-5.3-5.3L67.4,17.8z M27.6,72.4l3.2-17.6l14.4,14.4L27.6,72.4z M49.6,65.2l-5.3-5.3l32.6-32.6l5.3,5.3L49.6,65.2z      M90.7,24.1l-4.2,4.2L71.6,13.6l4.2-4.2c1.2-1.2,3.1-1.2,4.2,0l10.6,10.6C91.8,21.1,91.8,23,90.7,24.1z"
        />
      </g>

    </svg>

  )
}

export default SvgComponent
