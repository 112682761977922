import React from 'react'

const PhoneIcon: React.FC = () => {
  return (
    <svg
      width="32"
      height="39"
      viewBox="0 0 32 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2449 22.7932C8.00771 17.5652 6.56759 14.6193 10.8205 12.9197C14.5812 11.4166 15.6604 9.61792 13.572 4.60225C11.6424 -0.0315421 10.7176 -1.23847 6.56487 1.31071C-2.26954 6.73429 -1.7077 14.3987 5.38487 25.853C12.4774 37.3072 19.403 41.7352 28.8573 37.3133C33.3018 35.2349 32.6431 33.884 29.3035 30.0094C25.6896 25.8152 23.4796 25.7871 20.203 28.0723C16.4982 30.6567 14.4815 28.0208 11.2449 22.7932L11.2449 22.7932Z"
        fill="#FF636F"
      />
    </svg>
  )
}

export default PhoneIcon
