// import { ApolloClient, InMemoryCache } from '@apollo/client'

// const uri = 'http://localhost:3000/admin/api'
// const cache = new InMemoryCache()

// const client = new ApolloClient({ uri, cache })

// export default client

import { QueryClient } from 'react-query'

export const uri =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/admin/api'
    : 'https://doogood-cec21653ea78.herokuapp.com/admin/api'

const queryClient = new QueryClient()

export default queryClient
