import React, { useState } from 'react'
import * as ReactDOM from 'react-dom'
import {
  Box,
  Text,
  Button,
  CheckboxGroup,
  Stack,
  Checkbox,
  Input,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import { FormikHelpers, useFormik } from 'formik'
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { UserFragment } from '../generated/graphql'
import { useCurrentUser } from '../contexts/currentUserContext'
import MissionValidationSchema from '../utils/validationSchemas/MissionValidationSchema'
import PlacesAutocomplete from '../components/organisms/PlacesAutocomplete'
import { useHistory } from 'react-router-dom'
import ErrorModal from '../components/organisms/ErrorModal'
import mainTheme from '../theme/mainTheme'
import axios from 'axios'
import { uri } from '../contexts/reactQueryContext'

interface Values {
  name: string
  description: string
  address: string
  date: string
  duration: string
  image: File
  category: string[]
  charity: string
}

const CATEGORIES = [
  { name: 'Animaux', id: '607ab9cc3cb0ec2aa371faab' },
  { name: 'Commerce équitable', id: '606ddd3896d3f04898e894c0' },
  { name: 'Écologie', id: '60fd7f00aad0f754912db3d9' },
  { name: 'Éducation', id: '606ddd2196d3f04898e894bd' },
  { name: 'Enfants', id: '606ddd3296d3f04898e894bf' },
  { name: 'Handicapés', id: '606ddd1896d3f04898e894bc' },
  { name: 'Minorités', id: '606ddd3e96d3f04898e894c1' },
  { name: 'Personnes âgées', id: '606ddd0d96d3f04898e894bb' },
  { name: 'Religion', id: '60fd815095e19c572c3b4dce' },
  { name: 'Sans abris', id: '606ddd2d96d3f04898e894be' },
  { name: 'Sport', id: '60fd7c1baad0f754912db3d8' },
]

const query = `mutation CreateMission(
  $name: String
  $description: String
  $address: String
  $date: String
  $image: Upload
  $duration: String
  $category: CategoryRelateToManyInput
  $charity: CharityRelateToOneInput
) {
  createMission(
    data: {
      name: $name
      description: $description
      address: $address
      date: $date
      image: $image
      duration: $duration
      category: $category
      charity: $charity
    }
  ) {
    name
    description
    address {
      lat
      lng
    }
    date
    image {
      filename
    }
    duration
    category {
      name
    }
    charity {
      name
    }
  }
}
`

const CreateMission = () => {
  const history = useHistory()
  const [error, setError] = useState<string | null>(null)
  const [sendingForm, setSendingForm] = useState(false)
  const { user, loading } = useCurrentUser()

  const handleChange = (event: React.ChangeEvent<any>) => {
    formik.setFieldValue('image', event.currentTarget.files[0])
  }

  const formik = useFormik<Values>({
    initialValues: {
      name: '',
      description: '',
      address: '',
      date: '',
      duration: '',
      image: null,
      category: [] as string[],
      charity: '',
    },
    validationSchema: MissionValidationSchema,
    onSubmit: async (values, { setSubmitting }: FormikHelpers<Values>) => {
      setSendingForm(true)
      try {
        if (user) await createMission(values, user)
        setSubmitting(false)
        setSendingForm(false)
        history.replace('/back-office')
      } catch (e) {
        console.log('Error occured', e)
        setError(
          'Erreur dans la création de la mission. Veuillez vérifier les informations fournies dans les champs'
        )
      }
    },
  })

  const createMission = async (values: Values, user: UserFragment) => {
    try {
      const formData = new FormData()
      const operations = JSON.stringify({
        query,
        variables: {
          name: values.name,
          description: values.description,
          address: values.address,
          date: values.date,
          duration: values.duration,
          image: null,
          category: { connect: values.category.map(id => ({ id })) },
          charity: { connect: { id: user.charity.id } },
        },
      })
      formData.append('operations', operations)
      const map = {
        0: ['variables.image'],
      }
      formData.append('map', JSON.stringify(map))

      const file = values.image
      formData.append('0', file)

      const resCreationMission = await axios({
        url: uri,
        method: 'post',
        data: formData,
      })
      return resCreationMission
    } catch (e) {
      console.log('Error occured in create mission', e.response.request)
      throw e
    }
  }

  const onSelect = (placeId: string) => {
    formik.setFieldValue('address', placeId)
  }

  if (loading || sendingForm) {
    return (
      <Flex justifyContent='center' height='100%' alignItems='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color={mainTheme.colors.primaryBlue}
          size='xl'
        />
      </Flex>
    )
  }

  if (!user) return null

  return (
    <Box
      padding={[
        '12px 32px 10px 32px',
        '16px 50px 15px 50px',
        '21px 100px 20px 100px',
        '45px 200px 50px 200px',
      ]}>
      <Text textStyle='h2' margin='20px 0px 20px 0px'>
        Créer une mission
      </Text>
      <ErrorModal
        isOpen={!!error}
        error={error}
        onClose={() => setError(null)}
      />

      <form onSubmit={formik.handleSubmit}>
        <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
          <FormLabel pt={4} htmlFor='name'>
            Nom
          </FormLabel>
          <Input
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            id='name'
            name='name'
            placeholder='Nom de la mission'
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!formik.errors.description && formik.touched.description}>
          <FormLabel pt={4} htmlFor='description'>
            Description
          </FormLabel>
          <Input
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            id='description'
            name='description'
            placeholder='Décrire la mission'
          />
          <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!formik.errors.address && formik.touched.address}>
          <FormLabel pt={4} htmlFor='address'>
            Adresse complète
          </FormLabel>
          <PlacesAutocomplete onSelect={onSelect} formik={formik} />
          <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.date && formik.touched.date}>
          <FormLabel pt={4} htmlFor='date'>
            Date
          </FormLabel>
          <Input
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            id='date'
            name='date'
            placeholder='Date de la mission'
            type='date'
            variant='outline'
          />
          <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!formik.errors.duration && formik.touched.duration}>
          <FormLabel pt={4} htmlFor='duration'>
            Durée (facultatif)
          </FormLabel>
          <Input
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            id='duration'
            name='duration'
            placeholder='Ex: De 8h à 12h'
            variant='outline'
          />
          <FormErrorMessage>{formik.errors.duration}</FormErrorMessage>
        </FormControl>

        <FormLabel pt={4} htmlFor='image'>
          Image
        </FormLabel>
        <Input
          onBlur={formik.handleBlur}
          onChange={handleChange}
          id='image'
          name='image'
          placeholder='Choisir image'
          type='file'
        />

        <FormControl
          isInvalid={!!formik.errors.category && formik.touched.category}>
          <FormLabel pt={4} htmlFor='category'>
            Choisir une ou plusieurs catégories
          </FormLabel>
          <CheckboxGroup colorScheme='green'>
            <Stack>
              {CATEGORIES.map((category, index) => {
                return (
                  <FormLabel pt={1} key={index}>
                    <Checkbox
                      p={1}
                      name='category'
                      value={category.id}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {category.name}
                  </FormLabel>
                )
              })}
            </Stack>
          </CheckboxGroup>
          <FormErrorMessage>{formik.errors.category}</FormErrorMessage>
        </FormControl>
        <Flex p={4} justifyContent='center'>
          <Button type='submit'>Submit</Button>
        </Flex>
      </form>
    </Box>
  )
}
export default CreateMission
