import React, { createContext, useContext, useEffect } from 'react'
import { useState } from 'react'

type SessionUserContextType = {
    token: string | null
    connectUser: (token:string) => void
    disconnectUser: () => void
    isConnected: boolean
}

const defaultValue: SessionUserContextType = {
  token: null,
  connectUser: () => undefined,
  disconnectUser: () => undefined,
  isConnected: false,
}

const sessionUserContext = createContext<SessionUserContextType>(defaultValue)

export const SessionUserContextProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    setToken(localStorage.getItem('token') ?? null)
  }, [])

  const connectUser = (token: string) => {
    localStorage.setItem('token', token)
    setToken(token)
  }

  const disconnectUser = () => {
    localStorage.removeItem('token')
    setToken(null)
  }

  return (
    <sessionUserContext.Provider
      value={{
        token,
        connectUser,
        disconnectUser,
        isConnected: !!token,
      }}>
      {children}
    </sessionUserContext.Provider>
  )
}

export const useSessionUser = () => {
  const context = useContext(sessionUserContext)
  return context
}
