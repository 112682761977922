/* eslint-disable curly */
import React, { useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Button,
  CheckboxGroup,
  Stack,
  FormControl,
  Checkbox,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react'
import { FormikHelpers, useFormik } from 'formik'
import { FormLabel, Input } from '@chakra-ui/react'
import {
  useCreateCharityMutation,
  useCreateUserMutation,
} from '../generated/graphql'
import PlacesAutocomplete from '../components/organisms/PlacesAutocomplete'
import { useHistory } from 'react-router-dom'
import UserValidationSchema from '../utils/validationSchemas/UserValidationSchema'
import mainTheme from '../theme/mainTheme'
import EyeIcon from '../static/svg/EyeIcon'
import { useCurrentUser } from '../contexts/currentUserContext'
import ErrorModal from '../components/organisms/ErrorModal'
import axios from 'axios'
import { uri } from '../contexts/reactQueryContext'

export interface Values {
  email: string
  password: string
  name: string
  description: string
  address: string
  telephone: string
  website: string
  image: File
  category: string[]
  user: string
}

const CATEGORIES = [
  { name: 'Animaux', id: '607ab9cc3cb0ec2aa371faab' },
  { name: 'Commerce équitable', id: '606ddd3896d3f04898e894c0' },
  { name: 'Écologie', id: '60fd7f00aad0f754912db3d9' },
  { name: 'Éducation', id: '606ddd2196d3f04898e894bd' },
  { name: 'Enfants', id: '606ddd3296d3f04898e894bf' },
  { name: 'Handicapés', id: '606ddd1896d3f04898e894bc' },
  { name: 'Minorités', id: '606ddd3e96d3f04898e894c1' },
  { name: 'Personnes âgées', id: '606ddd0d96d3f04898e894bb' },
  { name: 'Religion', id: '60fd815095e19c572c3b4dce' },
  { name: 'Sans abris', id: '606ddd2d96d3f04898e894be' },
  { name: 'Sport', id: '60fd7c1baad0f754912db3d8' },
]

const query = `mutation CreateCharity(
  $name: String
  $description: String
  $address: String
  $telephone: String
  $website: String
  $image: Upload
  $category: CategoryRelateToManyInput
  $user: UserRelateToOneInput
) {
  createCharity(
    data: {
      name: $name
      description: $description
      address: $address
      telephone: $telephone
      website: $website
      image: $image
      category: $category
      user: $user
    }
  ) {
    name
    id
    user {
      id
    }
  }
}
`

function CreateUser() {
  const history = useHistory()
  const { mutateAsync: createUser } = useCreateUserMutation()
  const { login } = useCurrentUser()

  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [sendingForm, setSendingForm] = useState(false)

  const handleClick = () => setShowPassword(!showPassword)

  const handleChange = (event: React.ChangeEvent<any>) => {
    formik.setFieldValue('image', event.currentTarget.files[0])
  }

  const onSelect = (placeId: string) => {
    formik.setFieldValue('address', placeId)
  }

  const formik = useFormik<Values>({
    initialValues: {
      email: '',
      password: '',
      name: '',
      description: '',
      address: '',
      telephone: '',
      website: '',
      image: null,
      category: [] as string[],
      user: '',
    },
    validationSchema: UserValidationSchema,
    onSubmit: async (values, { setSubmitting }: FormikHelpers<Values>) => {
      try {
        const res = await createNewUser(values)
        const resLogin = await login(values.email, values.password)
        setSubmitting(false)
        setSendingForm(false)
        history.replace('/back-office')
      } catch (err: any) {
        if (err.message.includes('duplicate key error')) {
          setError('Email déjà existant')
        }
        throw new Error(err)
      }
    },
  })

  const createNewUser = async (values: Values) => {
    try {
      setSendingForm(true)

      const res = await createUser({
        password: values.password,
        email: values.email,
      })
      const userId = res.createUser.id

      const formData = new FormData()

      const operations = JSON.stringify({
        query,
        variables: {
          name: values.name,
          description: values.description,
          telephone: values.telephone,
          address: values.address,
          website: values.website,
          image: null,
          category: { connect: values.category.map(id => ({ id })) },
          user: { connect: { id: userId } },
        },
      })
      formData.append('operations', operations)
      const map = {
        0: ['variables.image'],
      }
      formData.append('map', JSON.stringify(map))

      const file = values.image
      formData.append('0', file)

      const resCreationCharity = await axios({
        url: uri,
        method: 'post',
        data: formData,
      })
      return resCreationCharity
    } catch (e) {
      console.log('Error occured in create charity', e.response)
      throw new Error(e)
    }
  }

  if (sendingForm)
    return (
      <Flex justifyContent='center' height='100%' alignItems='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color={mainTheme.colors.primaryBlue}
          size='xl'
        />
      </Flex>
    )

  return (
    <Box
      padding={[
        '12px 32px 10px 32px',
        '16px 50px 15px 50px',
        '21px 100px 20px 100px',
        '45px 200px 50px 200px',
      ]}>
      <Box background={mainTheme.colors.lightOrange} p={5} borderRadius={12}>
        <Text textStyle='h2'>Créer un compte association</Text>
        <Text textStyle='body'>
          Un compte association va vous permettre de créer des missions pour les
          bénévoles autour de vous
        </Text>
      </Box>
      <ErrorModal
        isOpen={!!error}
        error={error}
        onClose={() => setError(null)}
      />
      <form onSubmit={formik.handleSubmit}>
        <Flex justifyContent='center' padding={30} flexDirection='column'>
          <Text textStyle='h3' fontWeight='bold' textAlign='center' p={4}>
            Créez votre compte utilisateur
          </Text>

          <FormControl
            isInvalid={!!formik.errors.email && formik.touched.email}>
            <FormLabel pt={4} htmlFor='email'>
              Email
            </FormLabel>

            <Input
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              id='email'
              name='email'
              placeholder='Entrez email'
            />

            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.password && formik.touched.password}>
            <FormLabel pt={4} htmlFor='password'>
              Mot de passe
            </FormLabel>
            <InputGroup>
              <Input
                id='password'
                name='password'
                type={showPassword ? 'text' : 'password'}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder='Entrez mot de passe'
              />
              <InputRightElement width='3.5rem'>
                <Button onClick={handleClick} pt='7px' _focus={{ outline: 0 }}>
                  <EyeIcon color={mainTheme.colors.darkGrey} />
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
          </FormControl>

          <Text
            textStyle='h3'
            fontWeight='bold'
            textAlign='center'
            p={5}
            mt={8}>
            Rentrez les informations de votre association
          </Text>
          <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
            <FormLabel pt={4} htmlFor='name'>
              Nom de l'association
            </FormLabel>
            <Input
              id='name'
              name='name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='Entrez le nom'
            />
            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!formik.errors.description && formik.touched.description
            }>
            <FormLabel pt={4} htmlFor='description'>
              Description de votre association
            </FormLabel>
            <Input
              id='description'
              name='description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='Entrez une description'
            />
            <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.address && formik.touched.address}>
            <FormLabel pt={4} htmlFor='address'>
              Adresse complète
            </FormLabel>
            <PlacesAutocomplete onSelect={onSelect} formik={formik} />
            <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.telephone && formik.touched.telephone}>
            <FormLabel pt={4} htmlFor='telephone'>
              Téléphone (facultatif mais recommandé)
            </FormLabel>
            <Input
              id='telephone'
              name='telephone'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='Entrez numéro'
            />
            <FormErrorMessage>{formik.errors.telephone}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formik.errors.website && formik.touched.website}>
            <FormLabel pt={4} htmlFor='website'>
              Site web (facultatif mais recommandé)
            </FormLabel>
            <Input
              id='website'
              name='website'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='Entrez adresse site web'
            />
            <FormErrorMessage>{formik.errors.website}</FormErrorMessage>
          </FormControl>

          <FormLabel pt={4} htmlFor='image'>
            Sélectionnez une image pour votre association
          </FormLabel>
          <Input
            onBlur={formik.handleBlur}
            onChange={handleChange}
            id='image'
            name='image'
            type='file'
          />

          <FormControl
            isInvalid={!!formik.errors.category && formik.touched.category}>
            <FormLabel pt={4} htmlFor='category'>
              Choisir une ou plusieurs catégories
            </FormLabel>
            <CheckboxGroup colorScheme='green'>
              <Stack>
                {CATEGORIES.map((category, index) => {
                  return (
                    <FormLabel pt={1} key={index}>
                      <Checkbox
                        p={1}
                        name='category'
                        value={category.id}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {category.name}
                    </FormLabel>
                  )
                })}
              </Stack>
            </CheckboxGroup>
            <FormErrorMessage>{formik.errors.category}</FormErrorMessage>
          </FormControl>

          <Button
            mt={10}
            type='submit'
            color={mainTheme.colors.primaryBlue}
            backgroundColor={mainTheme.colors.secondaryBlue}
            width='100%'
            p={6}
            _focus={{ outline: 0 }}
            _hover={{
              borderColor: mainTheme.colors.primaryBlue,
            }}>
            ENVOYER
          </Button>
        </Flex>
      </form>
    </Box>
  )
}

export default CreateUser
