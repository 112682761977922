import React from 'react'
import { Box, Flex, Link, Text, Spinner } from '@chakra-ui/react'
import { Link as RouteLink } from 'react-router-dom'
import { useGetAllMissionsQuery } from '../generated/graphql'
import Card from '../components/molecules/Card'
import mainTheme from '../theme/mainTheme'
import { useSessionUser } from '../contexts/sessionContext'

const Missions: React.FC = () => {
  const { data: missions, status, isLoading } = useGetAllMissionsQuery()
  const { token } = useSessionUser()

  if (isLoading) {
    return (
      <Flex
        padding={[
          '12px 32px 10px 32px',
          '21px 100px 20px 100px',
          '45px 200px 50px 200px',
        ]}
        flexDirection='column'>
        <Text textStyle='h2' margin='20px 0px 20px 0px'>
          Toutes les missions
        </Text>
        <Flex justifyContent='center' height='100%' alignItems='center'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color={mainTheme.colors.primaryBlue}
            size='xl'
          />
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex
      padding={[
        '12px 32px 10px 32px',
        '16px 50px 15px 50px',
        '21px 100px 20px 100px',
        '45px 200px 50px 200px',
      ]}
      flexDirection='column'>
      <Text textStyle='h2' margin='20px 0px 20px 0px'>
        Toutes les missions
      </Text>
      {!token ? (
        <Flex>
          <Text>Vous voulez poster une mission ? Cliquez </Text>
          &nbsp;
          <Link
            as={RouteLink}
            to='/login'
            textDecoration='none !important'
            _focus={{ outline: 0 }}>
            ici
          </Link>
        </Flex>
      ) : null}

      {missions?.allMissions.length > 0 ? (
        missions.allMissions.map((mission, index) => {
          return (
            <Link
              as={RouteLink}
              to={`/mission/${mission.id}`}
              _hover={{ textDecoration: 'none' }}>
              <Card
                key={index}
                name={mission.name}
                description={mission.description}
                image={mission.image.publicUrlTransformed}
                backgroundColor='#F9FAFB'
                height={[120, 177]}
                city={mission.address.formattedAddress}
              />
            </Link>
          )
        })
      ) : (
        <Text textAlign='center' textStyle='notFound'>
          Aucune mission à afficher 🤷‍♀️
        </Text>
      )}
    </Flex>
  )
}

export default Missions
