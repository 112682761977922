import React from 'react'

type props = {
  color: string
}

const Handicaped: React.FC<props> = ({ color }) => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="-5.333 4.167 86.666 114"
      enableBackground="new -5.333 4.167 86.666 114.5"
      fill={color}
      height="100%"
    >
      <g>
        <path d="M52.159,85.207c-0.082,10.605-8.729,19.21-19.354,19.21c-10.676,0-19.361-8.686-19.361-19.361   c0-9.104,6.324-16.74,14.804-18.797c-0.157-2.534-0.271-5.531-0.347-9.131C14.593,59.458,4.444,71.089,4.444,85.055   c0,15.639,12.723,28.361,28.361,28.361c12.088,0,22.428-7.604,26.502-18.277c0.433-6.738-0.477-8.377-0.838-8.752   C58.288,86.2,57.164,85.312,52.159,85.207z" />
        <circle cx="36.127" cy="22.91" r="9.869" />
        <path d="M68.533,76.655c-3.917-4.051-9.565-5.451-17.325-5.451c-0.898,0-1.823,0.02-2.779,0.055   c-0.223-0.366-0.467-0.717-0.74-1.044c-0.181-1.091-0.357-2.836-0.508-5.528c3.766,2.616,8.453,3.98,13.986,3.98   c2.485,0,4.5-2.015,4.5-4.5s-2.015-4.5-4.5-4.5c-2.967,0-11.057-0.002-14.362-10.847c-0.008-1.355-0.013-2.763-0.013-4.278   c0-4.694-3.806-8.5-8.5-8.5s-8.5,3.806-8.5,8.5c0,4.757,0.034,8.809,0.104,12.301c0.071,3.561,0.181,6.533,0.333,9.043   c0.061,1.01,0.128,1.952,0.203,2.822c0.277,3.23,0.657,5.593,1.195,7.434c1.187,4.062,3.415,6.073,5.47,7.068   c0.001,0.001,0.002,0.001,0.003,0.002c0.1,0.048,0.198,0.097,0.297,0.141c0.167,0.078,0.339,0.147,0.514,0.21   c0.056,0.021,0.109,0.04,0.164,0.059c0.048,0.016,0.096,0.032,0.144,0.047c1.26,0.419,2.354,0.499,2.949,0.499   c1.158,0,2.263-0.233,3.27-0.653c2.055-0.171,4.436-0.317,6.725-0.317c3.83,0,7.4,0.41,8.746,1.801   c0.016,0.017,0.027,0.041,0.043,0.059c0.515,0.562,0.865,1.453,1.097,2.547c0.942,4.455-0.136,12.295-0.548,15.279   c-0.455,3.282,1.839,6.311,5.121,6.765c0.279,0.039,0.556,0.058,0.829,0.058c2.949,0,5.521-2.176,5.937-5.18   C74.168,91.622,74.146,82.459,68.533,76.655z" />
      </g>
    </svg>
  )
}
export default Handicaped
