import { uri } from '../contexts/reactQueryContext'

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    // try {
    const res = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0] || 'Error..'
      console.log('error thrown in fetcher', message)
      throw new Error(message)
    }

    return json.data
    // }
    // catch(err) {
    //   console.log('Error in fetch', err)
    //   throw new Error();
    // }
  }
}
