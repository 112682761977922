import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg width="50" height="100%" viewBox="0 0 36 30">
      <path
        d="M27.4419 21.6255C27.4383 21.6291 27.4347 21.6327 27.4312 21.6359C27.4316 21.6359 27.4316 21.6359 27.4316 21.6359C27.4351 21.6327 27.4383 21.6291 27.4419 21.6255Z"
        fill={color}
      />
      <path
        d="M27.4305 21.6355C27.4308 21.6351 27.4308 21.6351 27.4308 21.6348C25.7176 23.3508 23.8251 24.4428 21.2342 24.0348C20.7417 23.958 20.2499 23.898 19.7651 23.823C20.2499 23.8987 20.7414 23.9587 21.2342 24.0352C23.8248 24.4435 25.7176 23.3515 27.4305 21.6355Z"
        fill={color}
      />
      <path
        d="M17.4082 23.1487C17.4275 22.9667 17.4447 22.7853 17.464 22.6036C17.464 22.6033 17.4636 22.6033 17.4633 22.6033C17.4443 22.785 17.4268 22.9667 17.4082 23.1487Z"
        fill={color}
      />
      <path
        d="M28.9258 20.2555C29.7168 19.6799 30.9347 19.7946 31.4086 20.4046C31.7156 20.8013 31.7857 21.3654 31.6451 21.9035C31.7861 21.3654 31.7156 20.8006 31.4086 20.4039C30.9351 19.7939 29.7175 19.6795 28.9258 20.2548C28.3852 20.648 27.9173 21.1486 27.4409 21.6257C27.9173 21.1486 28.3849 20.6487 28.9258 20.2555Z"
        fill={color}
      />
      <path
        d="M10.4848 18.8349C11.1453 18.4873 12.1047 18.5911 12.9115 18.6613C15.6718 18.897 18.4276 19.2022 21.183 19.4983C21.8481 19.5695 22.5209 19.6491 23.167 19.8196C23.9735 20.0297 24.4832 20.5436 24.4046 21.4556C24.3828 21.7103 24.3043 21.9552 24.1797 22.1754C24.3043 21.9552 24.3832 21.71 24.4056 21.4553C24.4835 20.5418 23.9738 20.029 23.167 19.8185C22.5209 19.6481 21.8481 19.5684 21.1837 19.4976C18.4279 19.2022 15.6722 18.8963 12.9119 18.6606C12.1054 18.5908 11.146 18.487 10.4855 18.8339C8.16087 20.0567 5.90498 21.4093 3.64453 22.7535C5.90498 21.4104 8.16052 20.0574 10.4848 18.8349Z"
        fill={color}
      />
      <path
        d="M12.6252 27.6163C16.0892 27.7889 19.5672 27.6188 23.0393 27.6812C24.5476 27.7096 25.8213 27.4108 26.9617 26.3384C28.2175 25.157 29.6182 24.1316 30.9438 23.0221C31.2978 22.7253 31.5356 22.3223 31.6451 21.9038C31.5356 22.3219 31.2978 22.7253 30.9438 23.0214C29.6193 24.1313 28.2179 25.1566 26.9617 26.3377C25.8213 27.4104 24.5476 27.7096 23.0393 27.6809C19.5679 27.6184 16.0892 27.7882 12.6256 27.6163C10.9579 27.5332 9.49448 27.8826 8.10889 28.5399C8.10889 28.5403 8.10924 28.5403 8.10924 28.5403C9.49483 27.8826 10.9583 27.5335 12.6252 27.6163Z"
        fill={color}
      />
      <path
        d="M27.4419 21.6255C27.4383 21.6291 27.4347 21.6327 27.4312 21.6359C27.4316 21.6359 27.4316 21.6359 27.4316 21.6359C27.4351 21.6327 27.4383 21.6291 27.4419 21.6255Z"
        fill={color}
      />
      <path
        d="M22.7898 23.085C22.2026 23.1471 21.5919 23.045 20.9956 22.9843C19.8173 22.8696 18.6421 22.731 17.4639 22.6027C17.4639 22.603 17.4639 22.6034 17.4639 22.6037C18.6425 22.7314 19.8173 22.8703 20.9956 22.985C21.5908 23.0453 22.2026 23.1485 22.7898 23.085C23.4051 23.0194 23.9046 22.6588 24.1796 22.175C23.9043 22.6584 23.4051 23.0197 22.7898 23.085Z"
        fill={color}
      />
      <path
        d="M17.4082 23.1487C18.1575 23.5247 18.952 23.6966 19.7658 23.8229C18.952 23.6959 18.1571 23.5244 17.4082 23.1487Z"
        fill={color}
      />
      <path
        d="M21.0353 15.9001V12.8307C21.6341 12.3344 22.7215 11.2631 22.7215 10.0178C22.7215 8.30036 20.6487 6.90845 20.6487 6.90845C20.6487 6.90845 18.5759 8.30071 18.5759 10.0178C18.5759 11.2841 19.7019 12.3719 20.2934 12.8567V15.899C17.7341 15.9772 15.4813 16.7932 14.0547 18.0199C16.1661 18.2131 18.2883 18.4397 20.3527 18.6625L21.2619 18.7607C21.9701 18.8361 22.6636 18.9193 23.3564 19.1024C24.5771 19.4202 25.2464 20.3238 25.144 21.5189C25.1131 21.8774 25.0058 22.2212 24.8255 22.5404C24.7687 22.6407 24.7048 22.7365 24.6357 22.827C25.3155 22.4909 25.9894 21.9946 26.6892 21.3239V21.3046L26.9347 21.0836L27.1256 20.89C27.5458 20.4648 27.9804 20.0256 28.4898 19.6549C28.5199 19.6331 28.555 19.6173 28.5859 19.5966C27.5911 17.5182 24.6055 15.992 21.0353 15.9001Z"
        fill={color}
      />
      <path
        d="M23.167 19.8194C22.5209 19.6489 21.8477 19.5692 21.183 19.498C18.4279 19.202 15.6722 18.8968 12.9115 18.6611C12.1047 18.5909 11.1453 18.4871 10.4848 18.8347C8.16087 20.0572 5.90498 21.4102 3.64453 22.7533C4.73126 24.9741 6.25681 26.941 8.1093 28.5395C9.4949 27.8821 10.9587 27.5327 12.626 27.6159C16.0893 27.7874 19.5683 27.618 23.0397 27.6804C24.5481 27.7092 25.8218 27.41 26.9622 26.3373C28.2183 25.1562 29.6193 24.1308 30.9442 23.021C31.2982 22.7249 31.536 22.3218 31.6455 21.9034C31.7861 21.3653 31.716 20.8008 31.409 20.4045C30.9348 19.7944 29.7172 19.6801 28.9262 20.2554C28.3853 20.6486 27.9177 21.1485 27.4413 21.6255C27.4382 21.6287 27.435 21.6319 27.4322 21.6347C27.4322 21.6347 27.4322 21.6347 27.4319 21.6347C27.4319 21.635 27.4319 21.635 27.4315 21.6354C25.7186 23.3514 23.8258 24.4434 21.2353 24.0351C20.7428 23.9582 20.251 23.8983 19.7662 23.8228C18.9527 23.6966 18.1578 23.5247 17.4086 23.1486C17.4275 22.9666 17.445 22.7849 17.4636 22.6032C17.464 22.6032 17.4643 22.6032 17.4643 22.6035C17.4643 22.6032 17.4643 22.6028 17.4643 22.6025C18.6426 22.7309 19.8177 22.8694 20.996 22.9841C21.5924 23.0448 22.2031 23.1469 22.7903 23.0848C23.4056 23.0192 23.9047 22.6582 24.1801 22.1749C24.3043 21.9546 24.3832 21.7097 24.4049 21.4551C24.4832 20.5434 23.9731 20.0291 23.167 19.8194Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
