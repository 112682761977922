import React, { useState } from 'react'
import * as ReactDOM from 'react-dom'
import {
  Box,
  Text,
  Button,
  CheckboxGroup,
  Stack,
  Checkbox,
  Input,
  Flex,
  Spinner,
  Image,
} from '@chakra-ui/react'
import { FormikHelpers, useFormik } from 'formik'
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import {
  useGetMissionByIdQuery,
  useUpdateMissionMutation,
} from '../generated/graphql'
import { useCurrentUser } from '../contexts/currentUserContext'
import PlacesAutocomplete from '../components/organisms/PlacesAutocomplete'
import { useHistory, useParams } from 'react-router-dom'
import UpdateMissionValidationSchema from '../utils/validationSchemas/UpdateMissionValidationSchema'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import mainTheme from '../theme/mainTheme'
import axios from 'axios'
import { uri } from '../contexts/reactQueryContext'

interface Values {
  name: string
  description: string
  address: string
  date: string
  duration: string
  oldImage: string
  image?: File
  category: string[]
  charity: string
}

const query = `
mutation UpdateMission(
  $id: ID!
  $image: Upload
) {
  updateMission(
    id: $id
    data: {
      image: $image
    }
  ) {
    id
    name
    description
    date
    duration
  }
}
`
const CATEGORIES = [
  { name: 'Animaux', id: '607ab9cc3cb0ec2aa371faab' },
  { name: 'Commerce équitable', id: '606ddd3896d3f04898e894c0' },
  { name: 'Écologie', id: '60fd7f00aad0f754912db3d9' },
  { name: 'Éducation', id: '606ddd2196d3f04898e894bd' },
  { name: 'Enfants', id: '606ddd3296d3f04898e894bf' },
  { name: 'Handicapés', id: '606ddd1896d3f04898e894bc' },
  { name: 'Minorités', id: '606ddd3e96d3f04898e894c1' },
  { name: 'Personnes âgées', id: '606ddd0d96d3f04898e894bb' },
  { name: 'Religion', id: '60fd815095e19c572c3b4dce' },
  { name: 'Sans abris', id: '606ddd2d96d3f04898e894be' },
  { name: 'Sport', id: '60fd7c1baad0f754912db3d8' },
]
export interface MissionId {
  missionId: string
}

const UpdateMission = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { missionId } = useParams<MissionId>()
  const { user, loading } = useCurrentUser()
  const [removedImage, setRemovedImage] = useState(false)
  const { data } = useGetMissionByIdQuery({ missionId })
  let formattedAddress = ''

  const { mutate: updateMission } = useUpdateMissionMutation({
    onSuccess: () => queryClient.invalidateQueries('GetAuthenticatedUser'),
  })

  const handleChange = (event: React.ChangeEvent<any>) => {
    setRemovedImage(true)
    formik.setFieldValue('image', event.currentTarget.files[0])
  }

  const formik = useFormik<Values>({
    initialValues: {
      name: '',
      description: '',
      address: '',
      date: '',
      duration: '',
      oldImage: null,
      image: null,
      category: [] as string[],
      charity: '',
    },
    validationSchema: UpdateMissionValidationSchema,
    onSubmit: async (values, { setSubmitting }: FormikHelpers<Values>) => {
      const verifyAddress = () => {
        if (values.address.includes(',')) {
          formattedAddress = data.Mission.address.googlePlaceID
          return
        }
        formattedAddress = values.address
      }

      if (user) {
        verifyAddress()
        const res = updateMission({
          id: missionId,
          data: {
            name: values.name,
            description: values.description,
            address: formattedAddress,
            date: values.date,
            duration: values.duration,
            category: {
              disconnectAll: true,
              connect: values.category.map(id => ({ id })),
            },
          },
        })
        updateMissionImage(values)
        setSubmitting(false)
        history.replace('/back-office')
      }
    },
  })

  useEffect(() => {
    if (!data) return
    formik.setFormikState({
      values: {
        name: data.Mission.name,
        description: data.Mission.description,
        address: data.Mission.address.formattedAddress,
        date: data.Mission.date,
        duration: data.Mission.duration,
        oldImage: data.Mission.image.publicUrlTransformed,
        category: data.Mission.category.map(c => c.id),
        charity: data.Mission.charity.name,
      },
      isSubmitting: false,
      isValidating: false,
      submitCount: 0,
      touched: {},
      errors: {},
    })
  }, [data, formik.setFieldValue])

  const updateMissionImage = async (values: Values) => {
    try {
      const formData = new FormData()
      const operations = JSON.stringify({
        query,
        variables: {
          id: missionId,
          image: null,
        },
      })
      formData.append('operations', operations)
      const map = {
        0: ['variables.image'],
      }
      formData.append('map', JSON.stringify(map))

      const file = values.image
      formData.append('0', file || null)

      const resUpdatedImage = await axios({
        url: uri,
        method: 'post',
        data: formData,
      })
      return resUpdatedImage
    } catch (err) {
      console.log('error in updating mission image', err)
      throw err
    }
  }

  const onSelect = (placeId: string) => {
    formik.setFieldValue('address', placeId)
  }

  const date = new Date(formik.values.date) as Date

  const formattedDate = `${date.getFullYear()}-${`0${
    date.getMonth() + 1
  }`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`

  if (loading) {
    return (
      <Flex justifyContent='center' height='100%' alignItems='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color={mainTheme.colors.primaryBlue}
          size='xl'
        />
      </Flex>
    )
  }

  if (!user) return null

  if (!data) return null

  return (
    <Box
      padding={[
        '12px 32px 10px 32px',
        '16px 50px 15px 50px',
        '21px 100px 20px 100px',
        '45px 200px 50px 200px',
      ]}>
      <Flex justifyContent='center'>
        <Text textStyle='h2' margin='20px 0px 20px 0px'>
          Modifier:&nbsp;
        </Text>
        <Text
          textStyle='h2'
          margin='20px 0px 20px 0px'
          color={mainTheme.colors.primaryBlue}>
          {data.Mission.name}
        </Text>
      </Flex>
      <form onSubmit={formik.handleSubmit}>
        <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
          <FormLabel pt={4} htmlFor='name'>
            Nom
          </FormLabel>
          <Input
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            id='name'
            name='name'
            placeholder='Nom de la mission'
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!formik.errors.description && formik.touched.description}>
          <FormLabel pt={4} htmlFor='description'>
            Description
          </FormLabel>
          <Input
            value={formik.values.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            id='description'
            name='description'
            placeholder='Décrire la mission'
          />
          <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!formik.errors.address && formik.touched.address}>
          <FormLabel pt={4} htmlFor='address'>
            Adresse complète
          </FormLabel>
          <PlacesAutocomplete
            onSelect={onSelect}
            formik={formik}
            addressValue={formik.values.address}
          />
          <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.date && formik.touched.date}>
          <FormLabel pt={4} htmlFor='date'>
            Date
          </FormLabel>
          <Input
            value={formattedDate}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            id='date'
            name='date'
            placeholder='Date de la mission'
            type='date'
            variant='outline'
          />
          <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!formik.errors.duration && formik.touched.duration}>
          <FormLabel pt={4} htmlFor='duration'>
            Durée (facultatif)
          </FormLabel>
          <Input
            value={formik.values.duration}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            id='duration'
            name='duration'
            placeholder='Ex: De 8h à 12h'
            variant='outline'
          />
          <FormErrorMessage>{formik.errors.duration}</FormErrorMessage>
        </FormControl>

        <FormLabel pt={4} htmlFor='image'>
          Image
        </FormLabel>
        <Box boxSize='s'>
          {!removedImage ? (
            <Image src={formik.values.oldImage} alt='image association' />
          ) : null}
        </Box>
        <Input
          onBlur={formik.handleBlur}
          onChange={handleChange}
          id='image'
          name='image'
          placeholder='Choisir image'
          type='file'
        />

        <FormControl
          isInvalid={!!formik.errors.category && formik.touched.category}>
          <FormLabel pt={4} htmlFor='category'>
            Choisir une ou plusieurs catégories
          </FormLabel>
          <CheckboxGroup colorScheme='green'>
            <Stack>
              {CATEGORIES.map((category, index) => {
                return (
                  <FormLabel pt={1} key={index}>
                    <Checkbox
                      p={1}
                      name='category'
                      onBlur={formik.handleBlur}
                      onChange={e => {
                        if (e.target.checked) {
                          formik.setFieldValue('category', [
                            ...formik.values.category,
                            category.id,
                          ])
                        } else {
                          formik.setFieldValue(
                            'category',
                            formik.values.category.filter(
                              c => c !== category.id
                            )
                          )
                        }
                      }}
                      isChecked={formik.values.category.includes(category.id)}
                    />
                    {category.name}
                  </FormLabel>
                )
              })}
            </Stack>
          </CheckboxGroup>
          <FormErrorMessage>{formik.errors.category}</FormErrorMessage>
        </FormControl>
        <Flex p={4} justifyContent='center'>
          <Button type='submit'>MODIFIER</Button>
        </Flex>
      </form>
    </Box>
  )
}
export default UpdateMission
