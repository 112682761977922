/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import { Box, chakra, Flex, Text, Spinner } from '@chakra-ui/react'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaMapMarkerAlt } from 'react-icons/fa'
import CategoriesList from '../components/organisms/CategoriesList'
import CharitiesList from '../components/organisms/CharitiesList'
import { useCharities } from '../contexts/charitiesContext'
import mainTheme from '../theme/mainTheme'

const Marker: React.FC<{ lat?: number; lng?: number }> = () => (
  <Box transform='translate(-50%, -100%)' width={30}>
    <FaMapMarkerAlt size={30} color='#FF636F' />
  </Box>
)

function createMapOptions(maps: any) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    // zoomControlOptions: {
    //   position: 8,
    //   style: maps.ZoomControlStyle.SMALL,
    // },
  }
}
const span: React.FC = ({ children }) => <span>{children}</span>

function Home() {
  const { charities, loading } = useCharities()
  const [gpsLocalisation, setGpsLocalisation] = useState({
    lat: 48.8566,
    long: 2.3522,
  })
  const [bounds, setBounds] = useState(null)

  const charitiesAround = charities.filter(
    charity =>
      charity.address.lat < bounds?.ne.lat &&
      charity.address.lng < bounds?.ne.lng &&
      charity.address.lat > bounds?.se.lat &&
      charity.address.lng > bounds?.nw.lng
  )

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setGpsLocalisation({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      })
    })
  }, [gpsLocalisation])

  const displayCharitiesAround = () => {
    if (charitiesAround) {
      return (
        <>
          <CharitiesList charities={charitiesAround} />
          {charitiesAround.length === 0 ? (
            <Text textAlign='center' textStyle='notFound' paddingTop='50px'>
              Aucune association à afficher autour de vous 🤷‍♀️
            </Text>
          ) : null}
        </>
      )
    }
  }
  return (
    <Box marginX={[0, '32px']}>
      <Helmet
        title='Bénévoles: Trouvez une association et des missions associatives près de chez vous'
        meta={[
          {
            name: 'description',
            content:
              'Recherchez une association ou une mission associative près de chez vous grace à la carte intéractive',
          },
          {
            name: 'keywords',
            content:
              'association, bénévoles, description, adresse, mission,carte',
          },
        ]}
      />
      <Flex
        flexDirection={['column', 'row']}
        // minH="calc(100vh - 94px - 32px)"
        // maxH="calc(100vh - 94px - 32px)"
        paddingBottom={['20px', '30px', '0px']}>
        <Box
          marginTop={['20px', undefined]}
          marginLeft={['16px', undefined]}
          flex='1'
          borderRadius={24}
          mr='16px'
          overflow='hidden'
          minHeight={['30vh', 'auto']}
          height={['30vh', 'auto']}>
          <GoogleMapReact
            onChange={e => setBounds(e.bounds)}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_KEY as string,
            }}
            defaultCenter={{
              lat: 48.8566,
              lng: 2.3522,
            }}
            center={{ lat: gpsLocalisation.lat, lng: gpsLocalisation.long }}
            defaultZoom={11}
            options={createMapOptions}>
            {charitiesAround?.map((charity, index) => {
              return (
                <Marker
                  key={index}
                  lat={charity?.address?.lat}
                  lng={charity?.address?.lng}
                />
              )
            })}
          </GoogleMapReact>
        </Box>
        <Box flex='1' ml='16px' overflow='hidden'>
          <Flex flexDirection='column'>
            <Text
              color={mainTheme.colors.textPrimary}
              textStyle='h1'
              marginBottom={[0, '16px']}
              p={[2, 0]}>
              Les &nbsp;
              <chakra.span
                color={mainTheme.colors.primaryBlue}
                fontWeight='800'>
                associations
              </chakra.span>
              &nbsp; autour de vous
            </Text>
            <Box
              overflowY='scroll'
              height='calc(100vh - 42px - 16px - 94px - 32px)'
              boxShadow='rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'>
              <CategoriesList />
              {loading ? (
                <Flex justifyContent='center' height='40%' alignItems='center'>
                  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color={mainTheme.colors.primaryBlue}
                    size='xl'
                  />
                </Flex>
              ) : (
                displayCharitiesAround()
              )}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default Home
