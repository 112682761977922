import React from 'react'
import * as Yup from 'yup'

const UserValidationSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Champ requis'),
  password: Yup.string()
    .min(8, 'Mot de passe trop court!')
    .required('Champ requis'),
  name: Yup.string().required('Champ requis'),
  description: Yup.string()
    .min(20, 'Description trop courte!')
    .required('Champ requis'),
  address: Yup.string()
    .min(10, 'Adresse trop courte!')
    .required('Champ requis'),
  telephone: Yup.number()
    .min(10, 'Numéro de téléphone invalide')
    .required('Champ requis'),
  website: Yup.string()
    .max(60, 'Nom de site trop long')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Entrez un nom de site valide'
    )
    .required('Champ requis'),
  category: Yup.array()
    .min(1, 'Selectionnez au moins une catégorie')
    .required('Requis'),
})

export default UserValidationSchema
