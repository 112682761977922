import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 72"
      x="0px"
      y="0px"
      fill={color}
      width={23}
  >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path
            d="M17.66,47.12A2.15,2.15,0,0,0,19.81,45V27.73a2.15,2.15,0,1,0-4.3,0V45A2.15,2.15,0,0,0,17.66,47.12Z"
        />
          <path
            d="M28,47.12A2.15,2.15,0,0,0,30.15,45V27.73a2.15,2.15,0,1,0-4.3,0V45A2.15,2.15,0,0,0,28,47.12Z"
        />
          <path
            d="M38.34,47.12A2.15,2.15,0,0,0,40.49,45V27.73a2.15,2.15,0,1,0-4.3,0V45A2.15,2.15,0,0,0,38.34,47.12Z"
        />
          <path
            d="M53.85,10.41H40.09L38.32,4.48a6.32,6.32,0,0,0-6-4.48H23.69a6.32,6.32,0,0,0-6,4.48l-1.77,5.93H2.15a2.15,2.15,0,0,0,0,4.3H4.71V56.1A5.94,5.94,0,0,0,10.64,62H45.43a5.94,5.94,0,0,0,5.93-5.93V14.64h2.49A2.15,2.15,0,0,0,56,12.49,2.09,2.09,0,0,0,53.85,10.41Zm-32-4.7A2,2,0,0,1,23.69,4.3h8.69a1.92,1.92,0,0,1,1.87,1.42l1.38,4.69H20.36ZM47.06,56.54h-.15a1.67,1.67,0,0,1-1.54,1.12H10.64A1.67,1.67,0,0,1,9,56V14.64H47.06Z"
        />
        </g>
      </g>

    </svg>

  )
}

export default SvgComponent
