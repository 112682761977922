/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import { Box, Input, List, Select } from '@chakra-ui/react'
import { FormikValues } from 'formik'
import React, { useEffect, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'

/* eslint react/require-default-props: 0 */

type props = {
  onSelect: (placeId: string) => void
  formik: FormikValues
  addressValue?: string
}

const FormikPlacesAutocomplete: React.FC<props> = ({
  onSelect,
  formik,
  addressValue,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })

  useEffect(() => {
    if (addressValue && !value) setValue(addressValue)
  }, [addressValue])

  const [placeId, setPlaceId] = useState('')

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const handleInput: React.ChangeEventHandler<HTMLInputElement> = e => {
    // Update the keyword of the input element
    setValue(e.target.value)
  }

  const handleSelect =
    ({ description }: { description: string }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false)
      clearSuggestions()

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then(results => {
          onSelect(results[0].place_id)
          setPlaceId(results[0].place_id)
        })
        .catch(error => {
          console.log('😱 Error: ', error)
        })
    }

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <Box key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong>, &ensp;
          {secondary_text}
        </Box>
      )
    })

  return (
    <Box ref={ref}>
      <Input
        id='address'
        name='address'
        placeholder="Entrez l'adresse"
        value={value}
        onBlur={formik.handleBlur}
        onChange={handleInput}
        disabled={!ready}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <List border='1px solid #E2E8F0' p={1}>
          {renderSuggestions()}
        </List>
      )}
    </Box>
  )
}
export default FormikPlacesAutocomplete
