import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      version="1.1"
      viewBox="0 0 394 400"
      width={27}
      x="0px"
      y="0px"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
 >
      <g>
        <path
          className="fil0"
          d="M197 97c11,0 20,9 20,20l0 60 60 0c11,0 20,9 20,20 0,11 -9,20 -20,20l-60 0 0 60c0,11 -9,20 -20,20 -11,0 -20,-9 -20,-20l0 -60 -60 0c-11,0 -20,-9 -20,-20 0,-11 9,-20 20,-20l60 0 0 -60c0,-11 9,-20 20,-20z"
    />
        <path
          className="fil0"
          d="M197 0c109,0 197,88 197,197 0,109 -88,197 -197,197 -109,0 -197,-88 -197,-197 0,-109 88,-197 197,-197zm0 26c94,0 171,77 171,171 0,94 -77,171 -171,171 -94,0 -171,-77 -171,-171 0,-94 77,-171 171,-171z"
    />
      </g>
    </svg>

  )
}

export default SvgComponent
