import React from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Box, Link } from '@chakra-ui/react'
import LogoIcon from '../../static/svg/LogoIcon'
import mainTheme from '../../theme/mainTheme'

const Logo: React.FC = () => {
  return (
    <Link
      as={RouteLink}
      to="/"
      display="flex"
      alignItems="center"
      _hover={{ textDecoration: 'none' }}
      _focus={{ outline: 0 }}
    >
      <LogoIcon />
      <Box
        fontFamily="Sriracha"
        color="#302929"
        fontSize={['24', '32', '40']}
        paddingLeft="10px"
      >
        Doo
      </Box>
      <Box
        fontFamily="Sriracha"
        color={mainTheme.colors.primaryBlue}
        fontSize={['24', '32', '40']}
      >
        Good
      </Box>
    </Link>
  )
}

export default Logo
