import React from 'react'

const SearchIcon: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9171 20.0385L15.6811 14.5928C17.0273 12.9924 17.765 10.9788 17.765 8.88249C17.765 3.98476 13.7802 0 8.88249 0C3.98476 0 0 3.98476 0 8.88249C0 13.7802 3.98476 17.765 8.88249 17.765C10.7212 17.765 12.4733 17.2104 13.9714 16.1576L19.2472 21.6447C19.4677 21.8737 19.7643 22 20.0822 22C20.383 22 20.6684 21.8853 20.8851 21.6768C21.3454 21.2338 21.3601 20.4992 20.9171 20.0385ZM8.88249 2.31717C12.5027 2.31717 15.4478 5.2623 15.4478 8.88249C15.4478 12.5027 12.5027 15.4478 8.88249 15.4478C5.2623 15.4478 2.31717 12.5027 2.31717 8.88249C2.31717 5.2623 5.2623 2.31717 8.88249 2.31717Z"
        fill="#302929"
      />
    </svg>
  )
}

export default SearchIcon
