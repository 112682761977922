import React from 'react'
import * as Yup from 'yup'

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Veuillez rentrer un email valide')
    .required('Champ requis'),
  password: Yup.string()
    .min(8, 'Mot de passe trop court - au moins 8 charactères')
    .required('Champ requis'),
})

export default LoginValidationSchema
