import React from 'react'
import { Route } from 'react-router-dom'
import CreateMission from '../pages/CreateMission'
import UserBackOffice from '../pages/UserBackOffice'
import { useSessionUser } from '../contexts/sessionContext'
import UpdateMission from '../pages/UpdateMission'
// import MyForm from '../screens/UpdateMission'

const Protectedroutes = () => {
  const { isConnected } = useSessionUser()

  console.log('isConnected', isConnected)

  return (
    <>
      {isConnected && <Route path='/back-office' component={UserBackOffice} />}
      {isConnected && (
        <Route path='/creation-mission' component={CreateMission} />
      )}
      {isConnected && (
        <Route
          path='/modification-mission/:missionId'
          component={UpdateMission}
        />
      )}
      {/* {isConnected
            && <Route path="/modify-mission/:missionId" component={MyForm} />} */}
    </>
  )
}

export default Protectedroutes
