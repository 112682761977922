import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      width="70"
      height="100%"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.6928 36.5428C35.6928 36.5429 35.6927 36.5429 35.6927 36.5429L35.4879 36.0867L35.6928 36.5428ZM35.6928 36.5428C35.8078 36.4912 35.9115 36.4283 36.0067 36.3577L35.709 35.9561L35.6928 36.5428ZM52.7295 5.86733L52.6891 5.84272L52.686 5.84158C47.3673 2.60851 41.2525 0.899902 35 0.899902C16.1965 0.899902 0.900024 16.1964 0.900024 34.9999C0.900024 53.8034 16.1965 69.0999 35 69.0999C53.8036 69.0999 69.1 53.8034 69.1 34.9999C69.1 23.0098 62.9785 12.1173 52.7295 5.86733ZM52.3889 9.69543C60.7566 15.436 65.7 24.772 65.7 34.9999C65.7 51.9274 51.9276 65.6999 35 65.6999C18.0725 65.6999 4.30002 51.9274 4.30002 34.9999C4.30002 18.643 17.1604 5.2322 33.3 4.34645V34.9999C33.3 35.7619 33.8071 36.4309 34.541 36.6368L34.5424 36.6372C34.6932 36.679 34.8466 36.6999 35 36.6999C35.0322 36.6999 35.0589 36.6971 35.0773 36.6946C35.0826 36.6939 35.0877 36.6932 35.0922 36.6924C35.1237 36.6899 35.1493 36.6859 35.1635 36.6837L35.1768 36.6815L35.185 36.6802C35.1862 36.68 35.187 36.6799 35.1874 36.6799L52.3889 9.69543ZM35.0537 36.6946L35.0582 36.6944L35.0537 36.6946ZM45.1612 6.03665C46.6467 6.55511 48.0934 7.1829 49.4845 7.92675L36.7 28.9361V27.6869C37.3949 27.4734 37.9 26.8265 37.9 26.0616C37.9 25.2967 37.3949 24.6498 36.7 24.4363V19.4751C37.3949 19.2616 37.9 18.6148 37.9 17.8499C37.9 17.085 37.3949 16.4381 36.7 16.2246V11.2635C37.3949 11.05 37.9 10.4032 37.9 9.63826C37.9 8.87337 37.3949 8.22649 36.7 8.01298V4.34681C38.46 4.44537 40.2009 4.70111 41.9077 5.09637C41.8371 5.2777 41.797 5.47631 41.797 5.68822C41.797 6.62713 42.5581 7.38822 43.497 7.38822C44.3165 7.38822 45.0005 6.80842 45.1612 6.03665ZM35.112 36.689C35.1149 36.6884 35.1141 36.6886 35.1135 36.6887L35.112 36.689Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M41.097 10.6437C41.7597 10.6437 42.297 10.1064 42.297 9.44365C42.297 8.78091 41.7597 8.24365 41.097 8.24365C40.4342 8.24365 39.897 8.78091 39.897 9.44365C39.897 10.1064 40.4342 10.6437 41.097 10.6437Z"
        fill={color}
      />
      <path
        d="M38.6 6.88828C39.2628 6.88828 39.8 6.35102 39.8 5.68828C39.8 5.02554 39.2628 4.48828 38.6 4.48828C37.9373 4.48828 37.4 5.02554 37.4 5.68828C37.4 6.35102 37.9373 6.88828 38.6 6.88828Z"
        fill={color}
      />
      <path
        d="M45.9939 10.6437C46.6567 10.6437 47.1939 10.1064 47.1939 9.44365C47.1939 8.78091 46.6567 8.24365 45.9939 8.24365C45.3312 8.24365 44.7939 8.78091 44.7939 9.44365C44.7939 10.1064 45.3312 10.6437 45.9939 10.6437Z"
        fill={color}
      />
      <path
        d="M41.097 18.8556C41.7597 18.8556 42.297 18.3183 42.297 17.6556C42.297 16.9928 41.7597 16.4556 41.097 16.4556C40.4342 16.4556 39.897 16.9928 39.897 17.6556C39.897 18.3183 40.4342 18.8556 41.097 18.8556Z"
        fill={color}
      />
      <path
        d="M38.6 15.0997C39.2628 15.0997 39.8 14.5624 39.8 13.8997C39.8 13.237 39.2628 12.6997 38.6 12.6997C37.9373 12.6997 37.4 13.237 37.4 13.8997C37.4 14.5624 37.9373 15.0997 38.6 15.0997Z"
        fill={color}
      />
      <path
        d="M43.497 15.0997C44.1597 15.0997 44.697 14.5624 44.697 13.8997C44.697 13.237 44.1597 12.6997 43.497 12.6997C42.8342 12.6997 42.297 13.237 42.297 13.8997C42.297 14.5624 42.8342 15.0997 43.497 15.0997Z"
        fill={color}
      />
      <path
        d="M38.6 23.3116C39.2628 23.3116 39.8 22.7744 39.8 22.1116C39.8 21.4489 39.2628 20.9116 38.6 20.9116C37.9373 20.9116 37.4 21.4489 37.4 22.1116C37.4 22.7744 37.9373 23.3116 38.6 23.3116Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
