import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 95 90"
      enableBackground="new 0 0 95 0"
      xmlSpace="preserve"
      height="100%"
      fill={color}
>
      <g>
        <path
          d="M54.77,88.906H40.231c-3.619,0-6.565-2.945-6.565-6.565V61.334H12.658c-3.619,0-6.564-2.945-6.564-6.565V40.23   c0-3.619,2.945-6.564,6.564-6.564h21.008V12.658c0-3.619,2.946-6.564,6.565-6.564H54.77c3.619,0,6.565,2.945,6.565,6.564v21.008   h21.006c3.62,0,6.565,2.945,6.565,6.564v14.538c0,3.62-2.945,6.565-6.565,6.565H61.335v21.007   C61.335,85.961,58.389,88.906,54.77,88.906z M12.658,39.293c-0.518,0-0.938,0.42-0.938,0.938v14.538   c0,0.518,0.42,0.938,0.938,0.938h26.636v26.635c0,0.518,0.42,0.938,0.938,0.938H54.77c0.518,0,0.938-0.42,0.938-0.938V55.706   h26.634c0.518,0,0.938-0.42,0.938-0.938V40.23c0-0.518-0.42-0.938-0.938-0.938H55.707V12.658c0-0.518-0.42-0.938-0.938-0.938   H40.231c-0.518,0-0.938,0.42-0.938,0.938v26.635H12.658z"
    />
      </g>
    </svg>

  )
}

export default SvgComponent
