import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      height='100%'
      fill={color}
      version='1.1'
      viewBox='0 0 700 480'
      xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <symbol id='m' overflow='visible'>
          <path d='m31.266-1.875c-1.6172 0.84375-3.2969 1.4766-5.0469 1.8906-1.7422 0.42578-3.5586 0.64062-5.4531 0.64062-5.668 0-10.156-1.582-13.469-4.75-3.3125-3.1641-4.9688-7.4609-4.9688-12.891 0-5.4375 1.6562-9.7383 4.9688-12.906 3.3125-3.1641 7.8008-4.75 13.469-4.75 1.8945 0 3.7109 0.21484 5.4531 0.64062 1.75 0.42969 3.4297 1.0586 5.0469 1.8906v7.0469c-1.625-1.1133-3.2305-1.9258-4.8125-2.4375-1.5742-0.51953-3.2344-0.78125-4.9844-0.78125-3.1367 0-5.6016 1.0078-7.3906 3.0156-1.793 2-2.6875 4.7617-2.6875 8.2812 0 3.5117 0.89453 6.2734 2.6875 8.2812 1.7891 2 4.2539 3 7.3906 3 1.75 0 3.4102-0.25391 4.9844-0.76562 1.582-0.51953 3.1875-1.332 4.8125-2.4375z' />
        </symbol>
        <symbol id='d' overflow='visible'>
          <path d='m22.875-18.578c-0.71094-0.33203-1.418-0.57812-2.125-0.73438-0.71094-0.15625-1.418-0.23438-2.125-0.23438-2.1055 0-3.7266 0.67188-4.8594 2.0156-1.125 1.3438-1.6875 3.2656-1.6875 5.7656v11.766h-8.1562v-25.531h8.1562v4.2031c1.0508-1.6758 2.2539-2.8945 3.6094-3.6562 1.3633-0.76953 2.9922-1.1562 4.8906-1.1562 0.26953 0 0.56641 0.011719 0.89062 0.03125 0.32031 0.023437 0.78516 0.074219 1.3906 0.15625z' />
        </symbol>
        <symbol id='b' overflow='visible'>
          <path d='m29.391-12.828v2.3281h-19.062c0.19531 1.9062 0.88281 3.3398 2.0625 4.2969 1.1875 0.96094 2.8438 1.4375 4.9688 1.4375 1.7188 0 3.4766-0.25391 5.2812-0.76562 1.8008-0.50781 3.6484-1.2812 5.5469-2.3125v6.2969c-1.9297 0.73047-3.8594 1.2773-5.7969 1.6406-1.9297 0.375-3.8555 0.5625-5.7812 0.5625-4.6172 0-8.2031-1.1719-10.766-3.5156s-3.8438-5.6328-3.8438-9.875c0-4.1641 1.2539-7.4414 3.7656-9.8281 2.5195-2.3828 5.9844-3.5781 10.391-3.5781 4.0078 0 7.2188 1.2109 9.625 3.625 2.4062 2.418 3.6094 5.6484 3.6094 9.6875zm-8.375-2.7188c0-1.5391-0.45312-2.7852-1.3594-3.7344-0.90625-0.95703-2.0898-1.4375-3.5469-1.4375-1.5859 0-2.8711 0.44922-3.8594 1.3438-0.98047 0.88672-1.5898 2.1641-1.8281 3.8281z' />
        </symbol>
        <symbol id='c' overflow='visible'>
          <path d='m15.359-11.484c-1.6992 0-2.9805 0.29297-3.8438 0.875-0.85547 0.57422-1.2812 1.4219-1.2812 2.5469 0 1.0312 0.34375 1.8398 1.0312 2.4219 0.69531 0.58594 1.6602 0.875 2.8906 0.875 1.5312 0 2.8164-0.54688 3.8594-1.6406 1.0508-1.1016 1.5781-2.4844 1.5781-4.1406v-0.9375zm12.469-3.0781v14.562h-8.2344v-3.7812c-1.0938 1.5547-2.3242 2.6836-3.6875 3.3906-1.3672 0.69531-3.0273 1.0469-4.9844 1.0469-2.6484 0-4.7969-0.76953-6.4531-2.3125-1.6484-1.5391-2.4688-3.5391-2.4688-6 0-2.9883 1.0312-5.1797 3.0938-6.5781 2.0625-1.4062 5.2969-2.1094 9.7031-2.1094h4.7969v-0.64062c0-1.2891-0.51172-2.2344-1.5312-2.8281-1.0117-0.60156-2.5938-0.90625-4.75-0.90625-1.75 0-3.3828 0.17969-4.8906 0.53125-1.5 0.34375-2.8984 0.86719-4.1875 1.5625v-6.2188c1.75-0.42578 3.5039-0.75 5.2656-0.96875 1.7578-0.21875 3.5234-0.32812 5.2969-0.32812 4.5938 0 7.9102 0.91406 9.9531 2.7344 2.0508 1.8125 3.0781 4.7617 3.0781 8.8438z' />
        </symbol>
        <symbol id='a' overflow='visible'>
          <path d='m12.828-32.766v7.2344h8.4062v5.8438h-8.4062v10.828c0 1.1797 0.23438 1.9805 0.70312 2.4062 0.47656 0.41797 1.4141 0.625 2.8125 0.625h4.1875v5.8281h-7c-3.2188 0-5.5-0.67188-6.8438-2.0156s-2.0156-3.625-2.0156-6.8438v-10.828h-4.0625v-5.8438h4.0625v-7.2344z' />
        </symbol>
        <symbol id='i' overflow='visible'>
          <path d='m21.281-21.781v-13.672h8.2031v35.453h-8.2031v-3.6875c-1.125 1.5-2.3672 2.6016-3.7188 3.2969-1.3438 0.69531-2.9062 1.0469-4.6875 1.0469-3.1484 0-5.7305-1.25-7.75-3.75-2.0234-2.5-3.0312-5.7109-3.0312-9.6406 0-3.9375 1.0078-7.1562 3.0312-9.6562 2.0195-2.5 4.6016-3.75 7.75-3.75 1.7578 0 3.3164 0.35547 4.6719 1.0625 1.3633 0.71094 2.6094 1.8086 3.7344 3.2969zm-5.375 16.516c1.75 0 3.082-0.63281 4-1.9062 0.91406-1.2812 1.375-3.1328 1.375-5.5625 0-2.4375-0.46094-4.2891-1.375-5.5625-0.91797-1.2812-2.25-1.9219-4-1.9219-1.7305 0-3.0586 0.64062-3.9844 1.9219-0.91797 1.2734-1.375 3.125-1.375 5.5625 0 2.4297 0.45703 4.2812 1.375 5.5625 0.92578 1.2734 2.2539 1.9062 3.9844 1.9062z' />
        </symbol>
        <symbol id='h' overflow='visible'>
          <path d='m17.5-5.2656c1.75 0 3.082-0.63281 4-1.9062 0.91406-1.2812 1.375-3.1328 1.375-5.5625 0-2.4375-0.46094-4.2891-1.375-5.5625-0.91797-1.2812-2.25-1.9219-4-1.9219s-3.0938 0.64844-4.0312 1.9375c-0.92969 1.2812-1.3906 3.1328-1.3906 5.5469 0 2.418 0.46094 4.2656 1.3906 5.5469 0.9375 1.2812 2.2812 1.9219 4.0312 1.9219zm-5.4219-16.516c1.125-1.4883 2.3672-2.5859 3.7344-3.2969 1.3633-0.70703 2.9375-1.0625 4.7188-1.0625 3.1445 0 5.7266 1.25 7.75 3.75 2.0195 2.5 3.0312 5.7188 3.0312 9.6562 0 3.9297-1.0117 7.1406-3.0312 9.6406-2.0234 2.5-4.6055 3.75-7.75 3.75-1.7812 0-3.3555-0.35156-4.7188-1.0469-1.3672-0.70703-2.6094-1.8047-3.7344-3.2969v3.6875h-8.1562v-35.453h8.1562z' />
        </symbol>
        <symbol id='g' overflow='visible'>
          <path d='m0.5625-25.531h8.1719l6.8594 17.328 5.8281-17.328h8.1562l-10.734 27.953c-1.0742 2.832-2.3281 4.8164-3.7656 5.9531-1.4375 1.1328-3.3359 1.7031-5.6875 1.7031h-4.7188v-5.3594h2.5469c1.3828 0 2.3906-0.22656 3.0156-0.67188 0.63281-0.4375 1.1289-1.2266 1.4844-2.3594l0.21875-0.70312z' />
        </symbol>
        <symbol id='f' overflow='visible'>
          <path d='m0.23438-34.031h8.8125l9.0312 25.125 9-25.125h8.8125l-12.594 34.031h-10.469z' />
        </symbol>
        <symbol id='l' overflow='visible'>
          <path d='m24.547-24.719v6.6406c-1.1172-0.75781-2.2305-1.3203-3.3438-1.6875-1.1172-0.36328-2.2734-0.54688-3.4688-0.54688-2.2812 0-4.0586 0.66797-5.3281 2-1.2734 1.3359-1.9062 3.1953-1.9062 5.5781 0 2.3867 0.63281 4.2461 1.9062 5.5781 1.2695 1.3242 3.0469 1.9844 5.3281 1.9844 1.2695 0 2.4766-0.1875 3.625-0.5625 1.1445-0.38281 2.207-0.94531 3.1875-1.6875v6.6719c-1.2812 0.46875-2.5781 0.81641-3.8906 1.0469-1.3125 0.23828-2.6328 0.35938-3.9531 0.35938-4.6055 0-8.2109-1.1758-10.812-3.5312-2.5938-2.3633-3.8906-5.6484-3.8906-9.8594 0-4.207 1.2969-7.4922 3.8906-9.8594 2.6016-2.3633 6.207-3.5469 10.812-3.5469 1.332 0 2.6484 0.12109 3.9531 0.35938 1.3008 0.23047 2.5977 0.58594 3.8906 1.0625z' />
        </symbol>
        <symbol id='k' overflow='visible'>
          <path d='m16.062-20.312c-1.8047 0-3.1836 0.65234-4.1406 1.9531-0.94922 1.3047-1.4219 3.1797-1.4219 5.625 0 2.4492 0.47266 4.3242 1.4219 5.625 0.95703 1.293 2.3359 1.9375 4.1406 1.9375 1.7812 0 3.1406-0.64453 4.0781-1.9375 0.94531-1.3008 1.4219-3.1758 1.4219-5.625 0-2.4453-0.47656-4.3203-1.4219-5.625-0.9375-1.3008-2.2969-1.9531-4.0781-1.9531zm0-5.8281c4.3945 0 7.8281 1.1875 10.297 3.5625 2.4688 2.3672 3.7031 5.6484 3.7031 9.8438 0 4.1875-1.2344 7.4688-3.7031 9.8438-2.4688 2.3672-5.9023 3.5469-10.297 3.5469-4.4062 0-7.8555-1.1797-10.344-3.5469-2.4805-2.375-3.7188-5.6562-3.7188-9.8438 0-4.1953 1.2383-7.4766 3.7188-9.8438 2.4883-2.375 5.9375-3.5625 10.344-3.5625z' />
        </symbol>
        <symbol id='j' overflow='visible'>
          <path d='m23.859-24.719v6.1875c-1.75-0.72656-3.4375-1.2734-5.0625-1.6406-1.625-0.36328-3.1562-0.54688-4.5938-0.54688-1.5547 0-2.7109 0.19922-3.4688 0.59375-0.75 0.38672-1.125 0.98047-1.125 1.7812 0 0.65625 0.28516 1.1641 0.85938 1.5156 0.57031 0.34375 1.5938 0.60156 3.0625 0.76562l1.4375 0.20312c4.1758 0.53125 6.9844 1.4062 8.4219 2.625 1.4453 1.2109 2.1719 3.1172 2.1719 5.7188 0 2.7188-1.0078 4.7617-3.0156 6.125-2 1.3672-4.9922 2.0469-8.9688 2.0469-1.6875 0-3.4336-0.13672-5.2344-0.40625-1.793-0.25781-3.6406-0.65625-5.5469-1.1875v-6.2031c1.625 0.79297 3.2891 1.3867 5 1.7812 1.7188 0.39844 3.457 0.59375 5.2188 0.59375 1.5938 0 2.7891-0.21875 3.5938-0.65625 0.8125-0.4375 1.2188-1.0938 1.2188-1.9688 0-0.72656-0.28125-1.2695-0.84375-1.625-0.55469-0.35156-1.6562-0.62891-3.3125-0.82812l-1.4375-0.1875c-3.625-0.44531-6.168-1.2852-7.625-2.5156-1.4609-1.2383-2.1875-3.1094-2.1875-5.6094 0-2.707 0.92188-4.7109 2.7656-6.0156 1.8516-1.3125 4.6953-1.9688 8.5312-1.9688 1.5 0 3.0781 0.11719 4.7344 0.34375 1.6562 0.23047 3.457 0.58984 5.4062 1.0781z' />
        </symbol>
        <symbol id='e' overflow='visible'>
          <path d='m3.9219-35.453h8.1562v35.453h-8.1562z' />
        </symbol>
      </defs>
      <g>
        <path d='m583.33 182.84c0-23.055-18.762-41.906-41.906-41.906h-100.52v-31.547c0-17.453-14.188-31.734-31.734-31.734h-118.44c-17.453 0-31.734 14.188-31.734 31.734v31.547h-100.43c-23.148 0.09375-41.906 18.852-41.906 41.906v91.375 0.09375 0.09375 165.95c0 23.055 18.762 41.906 41.906 41.906h382.85c23.055 0 41.906-18.762 41.906-41.906v-165.86-0.1875-0.1875zm-305.57-73.359c0-7.1875 5.8789-13.066 13.066-13.066h118.44c7.1875 0 13.066 5.8789 13.066 13.066v31.547h-144.57zm-119.19 50.211h382.94c12.785 0 23.238 10.453 23.238 23.238v82.133l-148.49 0.003906c-4.0117-13.254-16.238-22.961-30.707-22.961h-71.121c-14.559 0-26.691 9.707-30.707 22.961h-148.4v-82.133c0-12.879 10.453-23.242 23.242-23.242zm142.24 114.62c0-7.4648 6.1602-13.535 13.625-13.535h71.121c7.4648 0 13.625 6.0664 13.625 13.625 0 7.5586-6.0664 13.625-13.625 13.625h-71.121c-7.4648 0-13.535-6.0664-13.625-13.535 0-0.089843 0.09375-0.089843 0-0.17969 0.09375 0 0 0 0 0zm240.61 189.28h-382.85c-12.789 0-23.242-10.453-23.242-23.238v-156.71h148.4c4.0117 13.254 16.238 22.961 30.707 22.961h71.121c14.559 0 26.691-9.707 30.707-22.961h148.4v156.71c0 12.785-10.453 23.238-23.242 23.238z' />
      </g>
    </svg>
  )
}

export default SvgComponent
