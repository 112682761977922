import React from 'react'
import { Link as RouteLink } from 'react-router-dom'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Link,
  Button,
  Box,
  Flex,
} from '@chakra-ui/react'
import BurgerIcon from '../../static/svg/BurgerIcon'
import mainTheme from '../../theme/mainTheme'
import { useSessionUser } from '../../contexts/sessionContext'
import SearchBar from '../molecules/SearchBar'
import { useCurrentUser } from '../../contexts/currentUserContext'

const BurgerMenu = () => {
  const { token } = useSessionUser()
  const { logout } = useCurrentUser()

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Options'
        icon={<BurgerIcon color={mainTheme.colors.black} />}
        _focus={{ outline: 0, WebkitTapHighlightColor: 'transparent' }}
      />
      <MenuList>
        {/* <MenuItem>
          <SearchBar/>
        </MenuItem> */}
        <MenuItem>
          <Link
            as={RouteLink}
            to='/'
            fontFamily='Raleway'
            fontSize={['16', '18', '20']}
            textDecoration='none !important'
            _focus={{ outline: 0, WebkitTapHighlightColor: 'transparent' }}
          >
            Home
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            as={RouteLink}
            to='/associations'
            fontFamily='Raleway'
            fontSize={['16', '18', '20']}
            textDecoration='none !important'
            _focus={{ outline: 0, WebkitTapHighlightColor: 'transparent' }}
          >
            Les associations
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            as={RouteLink}
            to='/missions'
            fontFamily='Raleway'
            fontSize={['16', '18', '20']}
            textDecoration='none !important'
            _focus={{ outline: 0, WebkitTapHighlightColor: 'transparent' }}
          >
            Les missions
          </Link>
        </MenuItem>

        <MenuItem>
          {token ? (
            <Flex flexDirection='column'>
              <Link
                as={RouteLink}
                to='/back-office'
                fontFamily='Raleway'
                fontSize={['16', '18', '20']}
                textDecoration='none !important'
                _focus={{ outline: 0, WebkitTapHighlightColor: 'transparent' }}
              >
                Mon asso
              </Link>
              <Button
                variant='ghost'
                padding='0px'
                marginTop='12px'
                size='xs'
                fontSize={['14', '16']}
                textDecoration='none !important'
                _focus={{ outline: 0, WebkitTapHighlightColor: 'transparent' }}
                color={mainTheme.colors.primaryBlue}
                fontWeight='bold'
                onClick={logout}
              >
                Se déconnecter
              </Button>
            </Flex>
          ) : (
            <Link
              as={RouteLink}
              fontSize={['14', '16']}
              to='/login'
              textDecoration='none !important'
              _focus={{ outline: 0, WebkitTapHighlightColor: 'transparent' }}
              color={mainTheme.colors.primaryBlue}
              fontWeight='bold'
            >
              Se connecter
            </Link>
          )}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default BurgerMenu
