import React, { createContext, useContext, useState } from 'react'
import {
  CharityFragment,
  useGetAllCharitiesQuery,
  CategoryFragment,
  useGetAllCategoriesQuery,
} from '../generated/graphql'

type CharitiesContextType = {
  charities: CharityFragment[]
  loadingCharities: boolean
  categories: CategoryFragment[]
  loadingCategories: boolean
  clickedCategories: string[]
  loading: boolean
  onClickCategory: (categoryName: string) => void
}

const defaultValue: CharitiesContextType = {
  charities: [],
  loadingCharities: false,
  categories: [],
  loadingCategories: false,
  loading: false,
  clickedCategories: [],
  onClickCategory: () => null,
}

const charitiesContext = createContext<CharitiesContextType>(defaultValue)

export const CharitiesContextProvider: React.FC = ({ children }) => {
  const [clickedCategories, setClickedCategories] = useState<string[]>([])
  const {
    data: charitiesData,
    isLoading: loadingCharities,
  } = useGetAllCharitiesQuery()
  const {
    data: categoriesData,
    isLoading: loadingCategories,
  } = useGetAllCategoriesQuery()

  const onClickCategory = (categoryName: string) => {
    if (!categoryName) return
    if (clickedCategories.includes(categoryName)) {
      setClickedCategories(
        clickedCategories.filter(cat => cat !== categoryName)
      )
    } else {
      setClickedCategories([...clickedCategories, categoryName])
    }
  }

  return (
    <charitiesContext.Provider
      value={{
        charities:
          (clickedCategories.length === 0
            ? charitiesData?.allCharities
            : charitiesData?.allCharities.filter(charity => charity.category.find(cat => clickedCategories.includes(cat.name)))) || [],
        loadingCharities,
        categories: categoriesData?.allCategories || [],
        loadingCategories,
        clickedCategories,
        loading: loadingCharities || loadingCategories,
        onClickCategory,
      }}
    >
      {children}
    </charitiesContext.Provider>
  )
}

export const useCharities = () => {
  const context = useContext(charitiesContext)
  return context
}
