import React from 'react'
import { Box, Text, Image, Flex, Spinner } from '@chakra-ui/react'
import { useParams } from 'react-router'
import { useGetMissionByIdQuery } from '../generated/graphql'
import mainTheme from '../theme/mainTheme'
import UsefulInfos from '../components/organisms/UsefulInfos'
import { Helmet } from 'react-helmet'

export interface idMission {
  missionId: string
}

const MissionDescription: React.FC = () => {
  const { missionId } = useParams<idMission>()
  const { data: missionData, status } = useGetMissionByIdQuery({
    missionId,
  })

  if (status === 'loading') {
    return (
      <Flex justifyContent='center' height='100%' alignItems='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color={mainTheme.colors.primaryBlue}
          size='xl'
        />
      </Flex>
    )
  }

  return (
    <Box
      padding={[
        '12px 32px 10px 32px',
        '16px 50px 15px 50px',
        '21px 100px 20px 100px',
        '45px 200px 50px 200px',
      ]}>
      <Helmet
        title={`Description de la mission associative: ${missionData?.Mission.name}`}
        meta={[
          {
            name: 'description',
            content: missionData?.Mission.description,
          },
          {
            name: 'keywords',
            content: 'association, bénévoles, description, adresse, mission',
          },
        ]}
      />
      <Text textStyles='body' color='#FF636F' fontWeight='700'>
        Fiche mission
      </Text>
      <Text textStyle='pageTitle' marginBottom='20px'>
        {missionData?.Mission.name}
      </Text>
      <Flex justifyContent='center'>
        <Image
          src={missionData?.Mission.image.publicUrlTransformed}
          height={[150, 200, 250]}
          width={740}
          objectFit='cover'
          borderRadius={24}
          objectPosition='60% 20%'
          marginBottom='8px'
        />
      </Flex>
      <Box p={3}>
        <Text
          textAlign='center'
          textStyle='h4'
          textDecoration='underline'
          minWidth='fit-content'>
          Description de la mission:
        </Text>
        <Text textAlign='center' textStyle='body'>
          {missionData?.Mission.description}
        </Text>
      </Box>
      <Box p={3}>
        <Text
          textAlign='center'
          textStyle='h4'
          textDecoration='underline'
          minWidth='fit-content'>
          Adresse de la mission:
        </Text>
        <Text textAlign='center' textStyle='body'>
          {missionData?.Mission.address.formattedAddress}
        </Text>
      </Box>
      <Box p={3}>
        <Text
          textAlign='center'
          textStyle='h4'
          textDecoration='underline'
          minWidth='fit-content'>
          Durée de la mission:
        </Text>
        <Text textAlign='center' textStyle='body'>
          {missionData?.Mission.duration}
        </Text>
      </Box>

      <Flex pt={5} flexDirection={['column', 'column', 'column', 'row']}>
        <Text textStyle='h2' minWidth='fit-content'>
          Informations sur l'association: &nbsp;
        </Text>
        <Text
          minWidth='fit-content'
          textStyle='h2'
          mb={30}
          color={mainTheme.colors.primaryBlue}>
          {missionData?.Mission.charity.name}
        </Text>
      </Flex>

      <UsefulInfos
        isMission
        email={missionData?.Mission.charity.user.email}
        telephone={missionData?.Mission.charity.telephone}
        website={missionData?.Mission.charity.website}
        address={missionData?.Mission.charity.address.formattedAddress}
      />
    </Box>
  )
}

export default MissionDescription
