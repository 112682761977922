/* eslint-disable */
import React, { createContext, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CharityFragment,
  MissionFragment,
  useGetAllQuery,
} from '../generated/graphql'

type AllElementsContextType = {
  charities: CharityFragment[]
  missions: MissionFragment[]
  onSearchElement: (element: string) => void
  searchedElement: string
}

const defaultValue: AllElementsContextType = {
  charities: [],
  missions: [],
  onSearchElement: () => null,
  searchedElement: '',
}

const allElementsContext = createContext<AllElementsContextType>(defaultValue)

export const AllElementsContextProvider: React.FC = ({ children }) => {
  const [searchedElement, setSearchedElement] = useState<string>('')
  const history = useHistory()

  const onSearchElement = (element: string) => {
    setSearchedElement(element)
    history.push('/search')
  }

  const { data: allElementsData } = useGetAllQuery()

  return (
    <allElementsContext.Provider
      value={{
        charities:
          allElementsData?.allCharities.filter(element =>
            element.name.toLowerCase().includes(searchedElement.toLowerCase())
          ) || [],
        missions:
          allElementsData?.allMissions.filter(element =>
            element.name.toLowerCase().includes(searchedElement.toLowerCase())
          ) || [],
        onSearchElement,
        searchedElement,
      }}>
      {children}
    </allElementsContext.Provider>
  )
}

export const useAllElements = () => {
  const context = useContext(allElementsContext)
  return context
}
