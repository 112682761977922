import React from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Flex, Link } from '@chakra-ui/react'
import { CharityFragment } from '../../generated/graphql'
import Card from '../molecules/Card'

type CharitiesListProps = {
  charities: CharityFragment[]
}

const CharitiesList: React.FC<CharitiesListProps> = ({ charities }) => {
  return (
    <>
      {charities.map((charity, index) => {
        return (
          <Flex key={index} flexDirection='column'>
            <Link
              as={RouteLink}
              to={`/association/${charity.id}`}
              _hover={{ textDecoration: 'none' }}>
              <Card
                city={charity.address.formattedAddress}
                name={charity.name}
                description={charity.description}
                image={charity.image.publicUrlTransformed}
                maxWidth={500}
                height={[110, 160]}
              />
            </Link>
          </Flex>
        )
      })}
    </>
  )
}

export default CharitiesList
