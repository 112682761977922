import * as React from 'react'
type props = {
  color: string
}
const SvgComponent: React.FC<props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 60"
      x="0px"
      y="0px"
      height="100%"
    >
      <g data-name="Layer 13">
        <path
          fill={color}
          d="M49.05,51.43c5.87-9,2.63-22-6.42-27.4a.89.89,0,0,0-.31-.19,20.19,20.19,0,0,0-4.46-2c5.31-2,6.06-8.91,5.88-10.66a1,1,0,0,0-1-1c-.44-.15-7.15.17-9.6,4,0-1.49-.37-5.53-3-8.19-3.24-3.24-8.54-3-8.76-3a1,1,0,0,0-.95,1C20.29,9.49,23.11,15.32,31,15.72V21a19.92,19.92,0,0,0-10.85,3.88.56.56,0,0,0-.29.21,20,20,0,0,0-7.74,18.21v0c1.28,13.36,16.71,21.9,28.72,15.6a.87.87,0,0,0,.31-.14,20.15,20.15,0,0,0,7.66-6.94A1,1,0,0,0,49.05,51.43ZM26.64,10.62l2.9,2.89c-4.77-.67-6.91-4.46-7.07-8.48,4,.15,7.81,2.31,8.49,7.07l-2.9-2.9A1,1,0,0,0,26.64,10.62Zm8.8,4c1.87-1.87,4.77-2.31,6.28-2.41-.17,4-2.31,7.81-7.07,8.48l2.89-2.9a1,1,0,1,0-1.41-1.41l-2.9,2.9A8.86,8.86,0,0,1,35.44,14.64Zm5.47,42C38.7,53.87,37.36,47.87,33.07,46c-1.16-.41-2-.2-4.38.42-3,.78-5.69,1.43-5.79,1.21s-.25-.27-.42-.8a6.36,6.36,0,0,0-1.43-2.59,9.43,9.43,0,0,0-2.3-1.73A8.16,8.16,0,0,0,14,42a17.89,17.89,0,0,1,6.49-14.88c2.4,2.44,7.9,6.41,11.28,4.91,1.34-.68,1.76-1.9,2.29-3.46a24.09,24.09,0,0,1,2.25-5.05,18.06,18.06,0,0,1,4,1.5c-2.36,2.41-3.09,6.47-2.13,7.81-.26.37,5.38,4.71,5.68,5.15s.09,1-.22,1.88c-1.74,3.78.54,8.45,3.2,11.36A18.14,18.14,0,0,1,40.91,56.63Z"
        />
        <path
          fill={color}
          d="M10.37,41a21.51,21.51,0,0,1,5.68-14.59A1,1,0,0,0,14.57,25c-8.21,8.63-8.21,23.29,0,31.9a1,1,0,0,0,1.48-1.36A21.48,21.48,0,0,1,10.37,41Z"
        />
        <path
          fill={color}
          d="M49.43,25A1,1,0,0,0,48,26.38c7.52,7.89,7.52,21.3,0,29.18a1,1,0,0,0,1.48,1.36C57.64,48.31,57.64,33.65,49.43,25Z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
