import { Box, Text } from '@chakra-ui/layout'
import React from 'react'
import Education from '../../static/svg/Education'
import Elderly from '../../static/svg/Elderly'
import Handicaped from '../../static/svg/Handicaped'
import Homeless from '../../static/svg/Homeless'
import Children from '../../static/svg/Children'
import FairTrade from '../../static/svg/FairTrade'
import Minorities from '../../static/svg/Minorities'
import Animals from '../../static/svg/Animals'
import Sports from '../../static/svg/Sports'
import Ecology from '../../static/svg/Ecology'
import Religion from '../../static/svg/Religion'
import Arts from '../../static/svg/Arts'
import Culture from '../../static/svg/Culture'
import Health from '../../static/svg/Health'
import Arrow from '../../static/svg/Arrow'
import Country from '../../static/svg/Country'
import Support from '../../static/svg/Support'
import Work from '../../static/svg/Work'

type CategoryObj = {
  name: string
  darkColor: string
  svg: React.FC<{ color: string }>
}

export type Category =
  | 'elderly'
  | 'handicap'
  | 'education'
  | 'socials'
  | 'children'
  | 'fairTrade'
  | 'minorities'
  | 'animals'
  | 'sports'
  | 'ecology'
  | 'religion'
  | 'arts'
  | 'culture'
  | 'health'
  | 'miscellaneous'
  | 'country'
  | 'support'
  | 'work'

type CategoriesMap = Record<Category, CategoryObj>

const categories: CategoriesMap = {
  elderly: {
    name: 'Personnes âgées',
    darkColor: '#54A2EA',
    svg: Elderly,
  },
  handicap: {
    name: 'Handicap',
    darkColor: '#01BE8F',
    svg: Handicaped,
  },
  education: {
    name: 'Education',
    darkColor: '#FF7D45',
    svg: Education,
  },
  socials: {
    name: 'Social',
    darkColor: '#5463EA',
    svg: Homeless,
  },
  children: {
    name: 'Children',
    darkColor: '#FE4444',
    svg: Children,
  },
  fairTrade: {
    name: 'FairTrade',
    darkColor: '#FFE700',
    svg: FairTrade,
  },
  minorities: {
    name: 'Minorities',
    darkColor: '#EA54D2',
    svg: Minorities,
  },
  animals: {
    name: 'Animals',
    darkColor: '#18EEFC',
    svg: Animals,
  },
  sports: {
    name: 'Sports',
    darkColor: '#42CA00',
    svg: Sports,
  },
  ecology: {
    name: 'Ecology',
    darkColor: '#03C8DF',
    svg: Ecology,
  },
  religion: {
    name: 'Religion',
    darkColor: '#D39F48',
    svg: Religion,
  },
  arts: {
    name: 'Arts',
    darkColor: '#1FD18F',
    svg: Arts,
  },
  culture: {
    name: 'Culture',
    darkColor: '#5667FC',
    svg: Culture,
  },
  health: {
    name: 'Santé',
    darkColor: '#EE0C71',
    svg: Health,
  },
  miscellaneous: {
    name: 'Divers',
    darkColor: '#D3B907',
    svg: Arrow,
  },
  country: {
    name: 'Territoire',
    darkColor: '#4EBD12',
    svg: Country,
  },
  support: {
    name: 'Aide',
    darkColor: '#ABAB5D',
    svg: Support,
  },
  work: {
    name: 'Emploi',
    darkColor: '#A96078',
    svg: Work,
  },
}

type cardProps = {
  categoryName?: string
  darkColor?: string
  lightColor?: string
  onClick: () => void
  isActive: boolean
  reference: Category
}

const CATEGORY_SIZE_BIG = '80px'

const CategoryCard: React.FC<cardProps> = ({
  categoryName,
  darkColor,
  lightColor,
  onClick,
  isActive,
  reference,
}) => {
  const Svg = categories[reference]
    ? categories[reference].svg
    : (undefined as unknown as React.FC<{ color: string }>)
  return (
    <Box
      backgroundColor={`${isActive ? lightColor : 'lightGrey'}`}
      min-width={[70, CATEGORY_SIZE_BIG]}
      width={[70, CATEGORY_SIZE_BIG]}
      min-height={[70, CATEGORY_SIZE_BIG]}
      height={[70, CATEGORY_SIZE_BIG]}
      display='flex'
      flexDirection='column'
      borderRadius='16px'
      marginRight='16px'
      marginBottom='16px'
      _hover={{
        borderColor: isActive ? darkColor : 'darkGrey',
        borderWidth: '2px',
        cursor: 'pointer',
      }}
      onClick={onClick}
      paddingTop={2}
      paddingBottom={2}>
      <Box flex={20} display='flex' justifyContent='center' alignItems='center'>
        <Box height={['24px', '32px']}>
          {categories[reference] ? (
            <Svg
              color={isActive ? categories[reference].darkColor : 'darkGrey'}
            />
          ) : null}
        </Box>
      </Box>
      <Box flex={15} display='flex' alignItems='center'>
        <Text
          lineHeight='1'
          textAlign='center'
          textStyle='categoryTitle'
          color={`${isActive ? darkColor : 'darkGrey'}`}
          flex='2 1 auto'>
          {categoryName}
        </Text>
      </Box>
    </Box>
  )
}
export default CategoryCard
