import { extendTheme } from '@chakra-ui/react'

const mainTheme = extendTheme({
  textStyles: {
    // you can also use responsive styles
    h1: {
      fontSize: ['26', '28'],
      fontWeight: 'bold',
      fontFamily: 'Raleway',
    },
    h2: {
      fontSize: ['20', '30'],
      fontWeight: 'bold',
      color: '#302929',
      fontFamily: 'Raleway',
    },
    h3: {
      fontSize: ['16', '24'],
      fontWeight: '400',
      color: '#302929',
      fontFamily: 'Raleway',
    },
    h4: {
      fontSize: ['12', '18'],
      fontWeight: 'bold',
      color: '#302929',
      fontFamily: 'Raleway',
    },
    pageTitle: {
      fontSize: ['23', '40'],
      color: '#302929',
      fontFamily: 'Raleway',
      fontWeight: '700',
    },
    textButton: {
      fontSize: '22',
      color: '#FFFFFF',
      fontFamily: 'Raleway',
      fontWeight: '700',
    },
    cardTitle: {
      fontSize: ['16', '20', '24'],
      color: '#302929',
      fontFamily: 'Raleway',
      fontWeight: '600',
    },
    categoryTitle: {
      fontSize: ['12', '13'],
      fontFamily: 'Raleway',
      fontWeight: '700',
    },
    cardText: {
      fontSize: ['12', '14'],
      color: '#686868',
      fontFamily: 'Raleway',
      fontWeight: '400',
    },
    body: {
      fontSize: ['14', '17'],
      color: '#000000',
      fontFamily: 'Raleway',
      fontWeight: '400',
    },
    notFound: {
      fontSize: ['14', '20'],
      color: '#302929',
      fontFamily: 'Raleway',
    },
  },
  colors: {
    primary: '#FF636F',
    textPrimary: '#302929',
    lightGrey: '#F0F0F0',
    darkGrey: '#949494',
    lightOrange: '#FEF4EF',
    primaryBlue: '#19EDFC',
    secondaryBlue: '#C9FBFF',
  },
})

export default mainTheme
