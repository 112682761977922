import React from 'react'
import { Flex, Link, Text } from '@chakra-ui/react'
import { Link as RouteLink } from 'react-router-dom'
import { useCharities } from '../contexts/charitiesContext'
import Card from '../components/molecules/Card'
import { useSessionUser } from '../contexts/sessionContext'

const Charities: React.FC = () => {
  const { charities, loading } = useCharities()
  const { token } = useSessionUser()

  return (
    <Flex
      padding={[
        '12px 32px 10px 32px',
        '16px 50px 15px 50px',
        '21px 100px 20px 100px',
        '45px 200px 50px 200px',
      ]}
      flexDirection='column'
    >
      <Text textStyle='h2' margin='20px 0px 20px 0px'>
        Toutes les associations
      </Text>
      {!token ? (
        <Flex>
          <Text>Vous êtes une association ? Cliquez </Text>
          &nbsp;
          <Link
            as={RouteLink}
            to='/login'
            textDecoration='none !important'
            _focus={{ outline: 0 }}
          >
            ici
          </Link>
        </Flex>
      ) : null}
      {charities.length > 0 ? (
        charities.map((charity, index) => {
          return (
            <Link
              as={RouteLink}
              to={`/association/${charity.id}`}
              _hover={{ textDecoration: 'none' }}
            >
              <Card
                key={index}
                name={charity.name}
                description={charity.description}
                image={charity.image.publicUrlTransformed}
                backgroundColor='#F9FAFB'
                height={[120, 177]}
                city={charity.address.formattedAddress}
              />
            </Link>
          )
        })
      ) : (
        <Text textAlign='center' textStyle='notFound' paddingTop='50px'>
          Aucune association à afficher 🤷‍♀️
        </Text>
      )}
    </Flex>
  )
}

export default Charities
