import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from './myFetcher';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CategoriesCreateInput = {
  data?: Maybe<CategoryCreateInput>;
};

export type CategoriesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<CategoryUpdateInput>;
};

/**  A keystone list  */
export type Category = {
  __typename?: 'Category';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Category List config, or
   *  2. As an alias to the field set on 'labelField' in the Category List config, or
   *  3. As an alias to a 'name' field on the Category List (if one exists), or
   *  4. As an alias to the 'id' field on the Category List.
   */
  _label_: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Maybe<CloudinaryImage_File>;
  name: Maybe<Scalars['String']>;
  lightColor: Maybe<Scalars['String']>;
  darkColor: Maybe<Scalars['String']>;
  reference: Maybe<Scalars['String']>;
};

export type CategoryCreateInput = {
  image?: Maybe<Scalars['Upload']>;
  name?: Maybe<Scalars['String']>;
  lightColor?: Maybe<Scalars['String']>;
  darkColor?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type CategoryRelateToManyInput = {
  create?: Maybe<Array<Maybe<CategoryCreateInput>>>;
  connect?: Maybe<Array<Maybe<CategoryWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<CategoryWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type CategoryUpdateInput = {
  image?: Maybe<Scalars['Upload']>;
  name?: Maybe<Scalars['String']>;
  lightColor?: Maybe<Scalars['String']>;
  darkColor?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type CategoryWhereInput = {
  AND?: Maybe<Array<Maybe<CategoryWhereInput>>>;
  OR?: Maybe<Array<Maybe<CategoryWhereInput>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lightColor?: Maybe<Scalars['String']>;
  lightColor_not?: Maybe<Scalars['String']>;
  lightColor_contains?: Maybe<Scalars['String']>;
  lightColor_not_contains?: Maybe<Scalars['String']>;
  lightColor_starts_with?: Maybe<Scalars['String']>;
  lightColor_not_starts_with?: Maybe<Scalars['String']>;
  lightColor_ends_with?: Maybe<Scalars['String']>;
  lightColor_not_ends_with?: Maybe<Scalars['String']>;
  lightColor_i?: Maybe<Scalars['String']>;
  lightColor_not_i?: Maybe<Scalars['String']>;
  lightColor_contains_i?: Maybe<Scalars['String']>;
  lightColor_not_contains_i?: Maybe<Scalars['String']>;
  lightColor_starts_with_i?: Maybe<Scalars['String']>;
  lightColor_not_starts_with_i?: Maybe<Scalars['String']>;
  lightColor_ends_with_i?: Maybe<Scalars['String']>;
  lightColor_not_ends_with_i?: Maybe<Scalars['String']>;
  lightColor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lightColor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  darkColor?: Maybe<Scalars['String']>;
  darkColor_not?: Maybe<Scalars['String']>;
  darkColor_contains?: Maybe<Scalars['String']>;
  darkColor_not_contains?: Maybe<Scalars['String']>;
  darkColor_starts_with?: Maybe<Scalars['String']>;
  darkColor_not_starts_with?: Maybe<Scalars['String']>;
  darkColor_ends_with?: Maybe<Scalars['String']>;
  darkColor_not_ends_with?: Maybe<Scalars['String']>;
  darkColor_i?: Maybe<Scalars['String']>;
  darkColor_not_i?: Maybe<Scalars['String']>;
  darkColor_contains_i?: Maybe<Scalars['String']>;
  darkColor_not_contains_i?: Maybe<Scalars['String']>;
  darkColor_starts_with_i?: Maybe<Scalars['String']>;
  darkColor_not_starts_with_i?: Maybe<Scalars['String']>;
  darkColor_ends_with_i?: Maybe<Scalars['String']>;
  darkColor_not_ends_with_i?: Maybe<Scalars['String']>;
  darkColor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  darkColor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reference?: Maybe<Scalars['String']>;
  reference_not?: Maybe<Scalars['String']>;
  reference_contains?: Maybe<Scalars['String']>;
  reference_not_contains?: Maybe<Scalars['String']>;
  reference_starts_with?: Maybe<Scalars['String']>;
  reference_not_starts_with?: Maybe<Scalars['String']>;
  reference_ends_with?: Maybe<Scalars['String']>;
  reference_not_ends_with?: Maybe<Scalars['String']>;
  reference_i?: Maybe<Scalars['String']>;
  reference_not_i?: Maybe<Scalars['String']>;
  reference_contains_i?: Maybe<Scalars['String']>;
  reference_not_contains_i?: Maybe<Scalars['String']>;
  reference_starts_with_i?: Maybe<Scalars['String']>;
  reference_not_starts_with_i?: Maybe<Scalars['String']>;
  reference_ends_with_i?: Maybe<Scalars['String']>;
  reference_not_ends_with_i?: Maybe<Scalars['String']>;
  reference_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reference_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CategoryWhereUniqueInput = {
  id: Scalars['ID'];
};

export type CharitiesCreateInput = {
  data?: Maybe<CharityCreateInput>;
};

export type CharitiesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<CharityUpdateInput>;
};

/**  A keystone list  */
export type Charity = {
  __typename?: 'Charity';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Charity List config, or
   *  2. As an alias to the field set on 'labelField' in the Charity List config, or
   *  3. As an alias to a 'name' field on the Charity List (if one exists), or
   *  4. As an alias to the 'id' field on the Charity List.
   */
  _label_: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  address: Maybe<LocationGoogle>;
  telephone: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
  image: Maybe<CloudinaryImage_File>;
  category: Array<Category>;
  _categoryMeta: Maybe<_QueryMeta>;
  missions: Array<Mission>;
  _missionsMeta: Maybe<_QueryMeta>;
  user: Maybe<User>;
};


/**  A keystone list  */
export type CharityCategoryArgs = {
  where: Maybe<CategoryWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortCategoriesBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


/**  A keystone list  */
export type Charity_CategoryMetaArgs = {
  where: Maybe<CategoryWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortCategoriesBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


/**  A keystone list  */
export type CharityMissionsArgs = {
  where: Maybe<MissionWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortMissionsBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


/**  A keystone list  */
export type Charity_MissionsMetaArgs = {
  where: Maybe<MissionWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortMissionsBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};

export type CharityCreateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  category?: Maybe<CategoryRelateToManyInput>;
  missions?: Maybe<MissionRelateToManyInput>;
  user?: Maybe<UserRelateToOneInput>;
};

export type CharityRelateToOneInput = {
  create?: Maybe<CharityCreateInput>;
  connect?: Maybe<CharityWhereUniqueInput>;
  disconnect?: Maybe<CharityWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type CharityUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  category?: Maybe<CategoryRelateToManyInput>;
  missions?: Maybe<MissionRelateToManyInput>;
  user?: Maybe<UserRelateToOneInput>;
};

export type CharityWhereInput = {
  AND?: Maybe<Array<Maybe<CharityWhereInput>>>;
  OR?: Maybe<Array<Maybe<CharityWhereInput>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  telephone?: Maybe<Scalars['String']>;
  telephone_not?: Maybe<Scalars['String']>;
  telephone_contains?: Maybe<Scalars['String']>;
  telephone_not_contains?: Maybe<Scalars['String']>;
  telephone_starts_with?: Maybe<Scalars['String']>;
  telephone_not_starts_with?: Maybe<Scalars['String']>;
  telephone_ends_with?: Maybe<Scalars['String']>;
  telephone_not_ends_with?: Maybe<Scalars['String']>;
  telephone_i?: Maybe<Scalars['String']>;
  telephone_not_i?: Maybe<Scalars['String']>;
  telephone_contains_i?: Maybe<Scalars['String']>;
  telephone_not_contains_i?: Maybe<Scalars['String']>;
  telephone_starts_with_i?: Maybe<Scalars['String']>;
  telephone_not_starts_with_i?: Maybe<Scalars['String']>;
  telephone_ends_with_i?: Maybe<Scalars['String']>;
  telephone_not_ends_with_i?: Maybe<Scalars['String']>;
  telephone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  telephone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Scalars['String']>;
  website_not?: Maybe<Scalars['String']>;
  website_contains?: Maybe<Scalars['String']>;
  website_not_contains?: Maybe<Scalars['String']>;
  website_starts_with?: Maybe<Scalars['String']>;
  website_not_starts_with?: Maybe<Scalars['String']>;
  website_ends_with?: Maybe<Scalars['String']>;
  website_not_ends_with?: Maybe<Scalars['String']>;
  website_i?: Maybe<Scalars['String']>;
  website_not_i?: Maybe<Scalars['String']>;
  website_contains_i?: Maybe<Scalars['String']>;
  website_not_contains_i?: Maybe<Scalars['String']>;
  website_starts_with_i?: Maybe<Scalars['String']>;
  website_not_starts_with_i?: Maybe<Scalars['String']>;
  website_ends_with_i?: Maybe<Scalars['String']>;
  website_not_ends_with_i?: Maybe<Scalars['String']>;
  website_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  website_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**  condition must be true for all nodes  */
  category_every?: Maybe<CategoryWhereInput>;
  /**  condition must be true for at least 1 node  */
  category_some?: Maybe<CategoryWhereInput>;
  /**  condition must be false for all nodes  */
  category_none?: Maybe<CategoryWhereInput>;
  /**  condition must be true for all nodes  */
  missions_every?: Maybe<MissionWhereInput>;
  /**  condition must be true for at least 1 node  */
  missions_some?: Maybe<MissionWhereInput>;
  /**  condition must be false for all nodes  */
  missions_none?: Maybe<MissionWhereInput>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
};

export type CharityWhereUniqueInput = {
  id: Scalars['ID'];
};

/**
 * Mirrors the formatting options [Cloudinary provides](https://cloudinary.com/documentation/image_transformation_reference).
 * All options are strings as they ultimately end up in a URL.
 */
export type CloudinaryImageFormat = {
  /**  Rewrites the filename to be this pretty string. Do not include `/` or `.`  */
  prettyName?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  crop?: Maybe<Scalars['String']>;
  aspect_ratio?: Maybe<Scalars['String']>;
  gravity?: Maybe<Scalars['String']>;
  zoom?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  fetch_format?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['String']>;
  angle?: Maybe<Scalars['String']>;
  effect?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  border?: Maybe<Scalars['String']>;
  background?: Maybe<Scalars['String']>;
  overlay?: Maybe<Scalars['String']>;
  underlay?: Maybe<Scalars['String']>;
  default_image?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  color_space?: Maybe<Scalars['String']>;
  dpr?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  density?: Maybe<Scalars['String']>;
  flags?: Maybe<Scalars['String']>;
  transformation?: Maybe<Scalars['String']>;
};

export type CloudinaryImage_File = {
  __typename?: 'CloudinaryImage_File';
  id: Maybe<Scalars['ID']>;
  path: Maybe<Scalars['String']>;
  filename: Maybe<Scalars['String']>;
  originalFilename: Maybe<Scalars['String']>;
  mimetype: Maybe<Scalars['String']>;
  encoding: Maybe<Scalars['String']>;
  publicUrl: Maybe<Scalars['String']>;
  publicUrlTransformed: Maybe<Scalars['String']>;
};


export type CloudinaryImage_FilePublicUrlTransformedArgs = {
  transformation: Maybe<CloudinaryImageFormat>;
};


export type LocationGoogle = {
  __typename?: 'LocationGoogle';
  id: Maybe<Scalars['ID']>;
  googlePlaceID: Maybe<Scalars['String']>;
  formattedAddress: Maybe<Scalars['String']>;
  lat: Maybe<Scalars['Float']>;
  lng: Maybe<Scalars['Float']>;
};

/**  A keystone list  */
export type Mission = {
  __typename?: 'Mission';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Mission List config, or
   *  2. As an alias to the field set on 'labelField' in the Mission List config, or
   *  3. As an alias to a 'name' field on the Mission List (if one exists), or
   *  4. As an alias to the 'id' field on the Mission List.
   */
  _label_: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  address: Maybe<LocationGoogle>;
  date: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['String']>;
  image: Maybe<CloudinaryImage_File>;
  category: Array<Category>;
  _categoryMeta: Maybe<_QueryMeta>;
  charity: Maybe<Charity>;
};


/**  A keystone list  */
export type MissionCategoryArgs = {
  where: Maybe<CategoryWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortCategoriesBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


/**  A keystone list  */
export type Mission_CategoryMetaArgs = {
  where: Maybe<CategoryWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortCategoriesBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};

export type MissionCreateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  category?: Maybe<CategoryRelateToManyInput>;
  charity?: Maybe<CharityRelateToOneInput>;
};

export type MissionRelateToManyInput = {
  create?: Maybe<Array<Maybe<MissionCreateInput>>>;
  connect?: Maybe<Array<Maybe<MissionWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<MissionWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type MissionUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  category?: Maybe<CategoryRelateToManyInput>;
  charity?: Maybe<CharityRelateToOneInput>;
};

export type MissionWhereInput = {
  AND?: Maybe<Array<Maybe<MissionWhereInput>>>;
  OR?: Maybe<Array<Maybe<MissionWhereInput>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date?: Maybe<Scalars['String']>;
  date_not?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['String']>;
  date_lte?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['String']>;
  date_gte?: Maybe<Scalars['String']>;
  date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  duration?: Maybe<Scalars['String']>;
  duration_not?: Maybe<Scalars['String']>;
  duration_contains?: Maybe<Scalars['String']>;
  duration_not_contains?: Maybe<Scalars['String']>;
  duration_starts_with?: Maybe<Scalars['String']>;
  duration_not_starts_with?: Maybe<Scalars['String']>;
  duration_ends_with?: Maybe<Scalars['String']>;
  duration_not_ends_with?: Maybe<Scalars['String']>;
  duration_i?: Maybe<Scalars['String']>;
  duration_not_i?: Maybe<Scalars['String']>;
  duration_contains_i?: Maybe<Scalars['String']>;
  duration_not_contains_i?: Maybe<Scalars['String']>;
  duration_starts_with_i?: Maybe<Scalars['String']>;
  duration_not_starts_with_i?: Maybe<Scalars['String']>;
  duration_ends_with_i?: Maybe<Scalars['String']>;
  duration_not_ends_with_i?: Maybe<Scalars['String']>;
  duration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  duration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**  condition must be true for all nodes  */
  category_every?: Maybe<CategoryWhereInput>;
  /**  condition must be true for at least 1 node  */
  category_some?: Maybe<CategoryWhereInput>;
  /**  condition must be false for all nodes  */
  category_none?: Maybe<CategoryWhereInput>;
  charity?: Maybe<CharityWhereInput>;
  charity_is_null?: Maybe<Scalars['Boolean']>;
};

export type MissionWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MissionsCreateInput = {
  data?: Maybe<MissionCreateInput>;
};

export type MissionsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MissionUpdateInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**  Create a single User item.  */
  createUser: Maybe<User>;
  /**  Create multiple User items.  */
  createUsers: Maybe<Array<Maybe<User>>>;
  /**  Update a single User item by ID.  */
  updateUser: Maybe<User>;
  /**  Update multiple User items by ID.  */
  updateUsers: Maybe<Array<Maybe<User>>>;
  /**  Delete a single User item by ID.  */
  deleteUser: Maybe<User>;
  /**  Delete multiple User items by ID.  */
  deleteUsers: Maybe<Array<Maybe<User>>>;
  /**  Create a single Charity item.  */
  createCharity: Maybe<Charity>;
  /**  Create multiple Charity items.  */
  createCharities: Maybe<Array<Maybe<Charity>>>;
  /**  Update a single Charity item by ID.  */
  updateCharity: Maybe<Charity>;
  /**  Update multiple Charity items by ID.  */
  updateCharities: Maybe<Array<Maybe<Charity>>>;
  /**  Delete a single Charity item by ID.  */
  deleteCharity: Maybe<Charity>;
  /**  Delete multiple Charity items by ID.  */
  deleteCharities: Maybe<Array<Maybe<Charity>>>;
  /**  Create a single Mission item.  */
  createMission: Maybe<Mission>;
  /**  Create multiple Mission items.  */
  createMissions: Maybe<Array<Maybe<Mission>>>;
  /**  Update a single Mission item by ID.  */
  updateMission: Maybe<Mission>;
  /**  Update multiple Mission items by ID.  */
  updateMissions: Maybe<Array<Maybe<Mission>>>;
  /**  Delete a single Mission item by ID.  */
  deleteMission: Maybe<Mission>;
  /**  Delete multiple Mission items by ID.  */
  deleteMissions: Maybe<Array<Maybe<Mission>>>;
  /**  Create a single Category item.  */
  createCategory: Maybe<Category>;
  /**  Create multiple Category items.  */
  createCategories: Maybe<Array<Maybe<Category>>>;
  /**  Update a single Category item by ID.  */
  updateCategory: Maybe<Category>;
  /**  Update multiple Category items by ID.  */
  updateCategories: Maybe<Array<Maybe<Category>>>;
  /**  Delete a single Category item by ID.  */
  deleteCategory: Maybe<Category>;
  /**  Delete multiple Category items by ID.  */
  deleteCategories: Maybe<Array<Maybe<Category>>>;
  /**  Authenticate and generate a token for a User with the Password Authentication Strategy.  */
  authenticateUserWithPassword: Maybe<AuthenticateUserOutput>;
  unauthenticateUser: Maybe<UnauthenticateUserOutput>;
  updateAuthenticatedUser: Maybe<User>;
};


export type MutationCreateUserArgs = {
  data: Maybe<UserCreateInput>;
};


export type MutationCreateUsersArgs = {
  data: Maybe<Array<Maybe<UsersCreateInput>>>;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  data: Maybe<UserUpdateInput>;
};


export type MutationUpdateUsersArgs = {
  data: Maybe<Array<Maybe<UsersUpdateInput>>>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersArgs = {
  ids: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateCharityArgs = {
  data: Maybe<CharityCreateInput>;
};


export type MutationCreateCharitiesArgs = {
  data: Maybe<Array<Maybe<CharitiesCreateInput>>>;
};


export type MutationUpdateCharityArgs = {
  id: Scalars['ID'];
  data: Maybe<CharityUpdateInput>;
};


export type MutationUpdateCharitiesArgs = {
  data: Maybe<Array<Maybe<CharitiesUpdateInput>>>;
};


export type MutationDeleteCharityArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCharitiesArgs = {
  ids: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMissionArgs = {
  data: Maybe<MissionCreateInput>;
};


export type MutationCreateMissionsArgs = {
  data: Maybe<Array<Maybe<MissionsCreateInput>>>;
};


export type MutationUpdateMissionArgs = {
  id: Scalars['ID'];
  data: Maybe<MissionUpdateInput>;
};


export type MutationUpdateMissionsArgs = {
  data: Maybe<Array<Maybe<MissionsUpdateInput>>>;
};


export type MutationDeleteMissionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMissionsArgs = {
  ids: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateCategoryArgs = {
  data: Maybe<CategoryCreateInput>;
};


export type MutationCreateCategoriesArgs = {
  data: Maybe<Array<Maybe<CategoriesCreateInput>>>;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  data: Maybe<CategoryUpdateInput>;
};


export type MutationUpdateCategoriesArgs = {
  data: Maybe<Array<Maybe<CategoriesUpdateInput>>>;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoriesArgs = {
  ids: Maybe<Array<Scalars['ID']>>;
};


export type MutationAuthenticateUserWithPasswordArgs = {
  email: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
};


export type MutationUpdateAuthenticatedUserArgs = {
  data: Maybe<UserUpdateInput>;
};

export type Query = {
  __typename?: 'Query';
  /**  Search for all User items which match the where clause.  */
  allUsers: Maybe<Array<Maybe<User>>>;
  /**  Search for the User item with the matching ID.  */
  User: Maybe<User>;
  /**  Perform a meta-query on all User items which match the where clause.  */
  _allUsersMeta: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the User list.  */
  _UsersMeta: Maybe<_ListMeta>;
  /**  Search for all Charity items which match the where clause.  */
  allCharities: Maybe<Array<Maybe<Charity>>>;
  /**  Search for the Charity item with the matching ID.  */
  Charity: Maybe<Charity>;
  /**  Perform a meta-query on all Charity items which match the where clause.  */
  _allCharitiesMeta: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Charity list.  */
  _CharitiesMeta: Maybe<_ListMeta>;
  /**  Search for all Mission items which match the where clause.  */
  allMissions: Maybe<Array<Maybe<Mission>>>;
  /**  Search for the Mission item with the matching ID.  */
  Mission: Maybe<Mission>;
  /**  Perform a meta-query on all Mission items which match the where clause.  */
  _allMissionsMeta: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Mission list.  */
  _MissionsMeta: Maybe<_ListMeta>;
  /**  Search for all Category items which match the where clause.  */
  allCategories: Maybe<Array<Maybe<Category>>>;
  /**  Search for the Category item with the matching ID.  */
  Category: Maybe<Category>;
  /**  Perform a meta-query on all Category items which match the where clause.  */
  _allCategoriesMeta: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Category list.  */
  _CategoriesMeta: Maybe<_ListMeta>;
  /**  Retrieve the meta-data for all lists.  */
  _ksListsMeta: Maybe<Array<Maybe<_ListMeta>>>;
  /** The version of the Keystone application serving this API. */
  appVersion: Maybe<Scalars['String']>;
  authenticatedUser: Maybe<User>;
};


export type QueryAllUsersArgs = {
  where: Maybe<UserWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortUsersBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type Query_AllUsersMetaArgs = {
  where: Maybe<UserWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortUsersBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


export type QueryAllCharitiesArgs = {
  where: Maybe<CharityWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortCharitiesBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


export type QueryCharityArgs = {
  where: CharityWhereUniqueInput;
};


export type Query_AllCharitiesMetaArgs = {
  where: Maybe<CharityWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortCharitiesBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


export type QueryAllMissionsArgs = {
  where: Maybe<MissionWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortMissionsBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


export type QueryMissionArgs = {
  where: MissionWhereUniqueInput;
};


export type Query_AllMissionsMetaArgs = {
  where: Maybe<MissionWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortMissionsBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


export type QueryAllCategoriesArgs = {
  where: Maybe<CategoryWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortCategoriesBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type Query_AllCategoriesMetaArgs = {
  where: Maybe<CategoryWhereInput>;
  search: Maybe<Scalars['String']>;
  sortBy: Maybe<Array<SortCategoriesBy>>;
  orderBy: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
};


export type Query_KsListsMetaArgs = {
  where: Maybe<_KsListsMetaInput>;
};

export enum SortCategoriesBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  LightColorAsc = 'lightColor_ASC',
  LightColorDesc = 'lightColor_DESC',
  DarkColorAsc = 'darkColor_ASC',
  DarkColorDesc = 'darkColor_DESC',
  ReferenceAsc = 'reference_ASC',
  ReferenceDesc = 'reference_DESC'
}

export enum SortCharitiesBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  TelephoneAsc = 'telephone_ASC',
  TelephoneDesc = 'telephone_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  MissionsAsc = 'missions_ASC',
  MissionsDesc = 'missions_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC'
}

export enum SortMissionsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CharityAsc = 'charity_ASC',
  CharityDesc = 'charity_DESC'
}

export enum SortUsersBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CharityAsc = 'charity_ASC',
  CharityDesc = 'charity_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IsAdminAsc = 'isAdmin_ASC',
  IsAdminDesc = 'isAdmin_DESC'
}


/**  A keystone list  */
export type User = {
  __typename?: 'User';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the User List config, or
   *  2. As an alias to the field set on 'labelField' in the User List config, or
   *  3. As an alias to a 'name' field on the User List (if one exists), or
   *  4. As an alias to the 'id' field on the User List.
   */
  _label_: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  charity: Maybe<Charity>;
  email: Maybe<Scalars['String']>;
  isAdmin: Maybe<Scalars['Boolean']>;
  password_is_set: Maybe<Scalars['Boolean']>;
};

export type UserCreateInput = {
  name?: Maybe<Scalars['String']>;
  charity?: Maybe<CharityRelateToOneInput>;
  email?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
};

export type UserRelateToOneInput = {
  create?: Maybe<UserCreateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<UserWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateInput = {
  name?: Maybe<Scalars['String']>;
  charity?: Maybe<CharityRelateToOneInput>;
  email?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<Maybe<UserWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserWhereInput>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  charity?: Maybe<CharityWhereInput>;
  charity_is_null?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isAdmin_not?: Maybe<Scalars['Boolean']>;
  password_is_set?: Maybe<Scalars['Boolean']>;
};

export type UserWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UsersCreateInput = {
  data?: Maybe<UserCreateInput>;
};

export type UsersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserUpdateInput>;
};

export type _ListAccess = {
  __typename?: '_ListAccess';
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'create' operations.
   * NOTE: 'create' can only return a Boolean.
   * It is not possible to specify a declarative Where clause for this
   * operation
   */
  create: Maybe<Scalars['Boolean']>;
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'read' operations.
   */
  read: Maybe<Scalars['JSON']>;
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'update' operations.
   */
  update: Maybe<Scalars['JSON']>;
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'delete' operations.
   */
  delete: Maybe<Scalars['JSON']>;
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'auth' operations.
   */
  auth: Maybe<Scalars['JSON']>;
};

export type _ListInputTypes = {
  __typename?: '_ListInputTypes';
  /** Input type for matching multiple items */
  whereInput: Maybe<Scalars['String']>;
  /** Input type for matching a unique item */
  whereUniqueInput: Maybe<Scalars['String']>;
  /** Create mutation input type name */
  createInput: Maybe<Scalars['String']>;
  /** Create many mutation input type name */
  createManyInput: Maybe<Scalars['String']>;
  /** Update mutation name input */
  updateInput: Maybe<Scalars['String']>;
  /** Update many mutation name input */
  updateManyInput: Maybe<Scalars['String']>;
};

export type _ListMeta = {
  __typename?: '_ListMeta';
  /** The Keystone list key */
  key: Maybe<Scalars['String']>;
  /**
   * The Keystone List name
   * @deprecated Use `key` instead
   */
  name: Maybe<Scalars['String']>;
  /** The list's user-facing description */
  description: Maybe<Scalars['String']>;
  /** The list's display name in the Admin UI */
  label: Maybe<Scalars['String']>;
  /** The list's singular display name */
  singular: Maybe<Scalars['String']>;
  /** The list's plural display name */
  plural: Maybe<Scalars['String']>;
  /** The list's data path */
  path: Maybe<Scalars['String']>;
  /** Access control configuration for the currently authenticated request */
  access: Maybe<_ListAccess>;
  /** Information on the generated GraphQL schema */
  schema: Maybe<_ListSchema>;
};

export type _ListMutations = {
  __typename?: '_ListMutations';
  /** Create mutation name */
  create: Maybe<Scalars['String']>;
  /** Create many mutation name */
  createMany: Maybe<Scalars['String']>;
  /** Update mutation name */
  update: Maybe<Scalars['String']>;
  /** Update many mutation name */
  updateMany: Maybe<Scalars['String']>;
  /** Delete mutation name */
  delete: Maybe<Scalars['String']>;
  /** Delete many mutation name */
  deleteMany: Maybe<Scalars['String']>;
};

export type _ListQueries = {
  __typename?: '_ListQueries';
  /** Single-item query name */
  item: Maybe<Scalars['String']>;
  /** All-items query name */
  list: Maybe<Scalars['String']>;
  /** List metadata query name */
  meta: Maybe<Scalars['String']>;
};

export type _ListSchema = {
  __typename?: '_ListSchema';
  /** The typename as used in GraphQL queries */
  type: Maybe<Scalars['String']>;
  /**
   * Top level GraphQL query names which either return this type, or
   * provide aggregate information about this type
   */
  queries: Maybe<_ListQueries>;
  /** Top-level GraphQL mutation names */
  mutations: Maybe<_ListMutations>;
  /** Top-level GraphQL input types */
  inputTypes: Maybe<_ListInputTypes>;
  /** Information about fields defined on this list */
  fields: Maybe<Array<Maybe<_ListSchemaFields>>>;
  /**
   * Information about fields on other types which return this type, or
   * provide aggregate information about this type
   */
  relatedFields: Maybe<Array<Maybe<_ListSchemaRelatedFields>>>;
};


export type _ListSchemaFieldsArgs = {
  where: Maybe<_ListSchemaFieldsInput>;
};

export type _ListSchemaFields = {
  __typename?: '_ListSchemaFields';
  /** The path of the field in its list */
  path: Maybe<Scalars['String']>;
  /**
   * The name of the field in its list
   * @deprecated Use `path` instead
   */
  name: Maybe<Scalars['String']>;
  /** The field type (ie, Checkbox, Text, etc) */
  type: Maybe<Scalars['String']>;
};

export type _ListSchemaFieldsInput = {
  type?: Maybe<Scalars['String']>;
};

export type _ListSchemaRelatedFields = {
  __typename?: '_ListSchemaRelatedFields';
  /** The typename as used in GraphQL queries */
  type: Maybe<Scalars['String']>;
  /** A list of GraphQL field names */
  fields: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _QueryMeta = {
  __typename?: '_QueryMeta';
  count: Maybe<Scalars['Int']>;
};

export type _KsListsMetaInput = {
  key?: Maybe<Scalars['String']>;
  /** Whether this is an auxiliary helper list */
  auxiliary?: Maybe<Scalars['Boolean']>;
};

export type AuthenticateUserOutput = {
  __typename?: 'authenticateUserOutput';
  /**  Used to make subsequent authenticated requests by setting this token in a header: 'Authorization: Bearer <token>'.  */
  token: Maybe<Scalars['String']>;
  /**  Retrieve information on the newly authenticated User here.  */
  item: Maybe<User>;
};

export type UnauthenticateUserOutput = {
  __typename?: 'unauthenticateUserOutput';
  /**
   * `true` when unauthentication succeeds.
   * NOTE: unauthentication always succeeds when the request has an invalid or missing authentication token.
   */
  success: Maybe<Scalars['Boolean']>;
};

export type CategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'name' | 'lightColor' | 'darkColor' | 'reference'>
);

export type CharityFragment = (
  { __typename?: 'Charity' }
  & Pick<Charity, 'id' | 'name' | 'description' | 'telephone' | 'website'>
  & { address: Maybe<(
    { __typename?: 'LocationGoogle' }
    & Pick<LocationGoogle, 'googlePlaceID' | 'lat' | 'lng' | 'formattedAddress'>
  )>, user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email'>
  )>, image: Maybe<(
    { __typename?: 'CloudinaryImage_File' }
    & Pick<CloudinaryImage_File, 'filename' | 'publicUrlTransformed'>
  )>, category: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>, missions: Array<(
    { __typename?: 'Mission' }
    & Pick<Mission, 'id' | 'name' | 'description' | 'date' | 'duration'>
    & { address: Maybe<(
      { __typename?: 'LocationGoogle' }
      & Pick<LocationGoogle, 'googlePlaceID' | 'lat' | 'lng' | 'formattedAddress'>
    )>, image: Maybe<(
      { __typename?: 'CloudinaryImage_File' }
      & Pick<CloudinaryImage_File, 'filename'>
    )> }
  )> }
);

export type MissionFragment = (
  { __typename?: 'Mission' }
  & Pick<Mission, 'id' | 'name' | 'description' | 'date' | 'duration'>
  & { address: Maybe<(
    { __typename?: 'LocationGoogle' }
    & Pick<LocationGoogle, 'googlePlaceID' | 'lat' | 'lng' | 'formattedAddress'>
  )>, image: Maybe<(
    { __typename?: 'CloudinaryImage_File' }
    & Pick<CloudinaryImage_File, 'filename' | 'publicUrlTransformed'>
  )>, category: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'name' | 'id'>
  )>, charity: Maybe<(
    { __typename?: 'Charity' }
    & Pick<Charity, 'name' | 'telephone' | 'website'>
    & { address: Maybe<(
      { __typename?: 'LocationGoogle' }
      & Pick<LocationGoogle, 'googlePlaceID' | 'lat' | 'lng' | 'formattedAddress'>
    )>, user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )> }
  )> }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { charity: Maybe<(
    { __typename?: 'Charity' }
    & { missions: Array<(
      { __typename?: 'Mission' }
      & MissionFragment
    )> }
    & CharityFragment
  )> }
);

export type AuthenticateUserWithPasswordMutationVariables = Exact<{
  email: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
}>;


export type AuthenticateUserWithPasswordMutation = (
  { __typename?: 'Mutation' }
  & { authenticateUserWithPassword: Maybe<(
    { __typename?: 'authenticateUserOutput' }
    & Pick<AuthenticateUserOutput, 'token'>
    & { item: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type CreateCharityMutationVariables = Exact<{
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  telephone: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
  image: Maybe<Scalars['Upload']>;
  category: Maybe<CategoryRelateToManyInput>;
  user: Maybe<UserRelateToOneInput>;
}>;


export type CreateCharityMutation = (
  { __typename?: 'Mutation' }
  & { createCharity: Maybe<(
    { __typename?: 'Charity' }
    & Pick<Charity, 'name' | 'id'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type CreateMissionMutationVariables = Exact<{
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
  image: Maybe<Scalars['Upload']>;
  duration: Maybe<Scalars['String']>;
  category: Maybe<CategoryRelateToManyInput>;
  charity: Maybe<CharityRelateToOneInput>;
}>;


export type CreateMissionMutation = (
  { __typename?: 'Mutation' }
  & { createMission: Maybe<(
    { __typename?: 'Mission' }
    & Pick<Mission, 'name' | 'description' | 'date' | 'duration'>
    & { address: Maybe<(
      { __typename?: 'LocationGoogle' }
      & Pick<LocationGoogle, 'lat' | 'lng'>
    )>, image: Maybe<(
      { __typename?: 'CloudinaryImage_File' }
      & Pick<CloudinaryImage_File, 'filename'>
    )>, category: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'name'>
    )>, charity: Maybe<(
      { __typename?: 'Charity' }
      & Pick<Charity, 'name'>
    )> }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  email: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type DeleteMissionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMissionMutation = (
  { __typename?: 'Mutation' }
  & { deleteMission: Maybe<(
    { __typename?: 'Mission' }
    & Pick<Mission, 'name'>
  )> }
);

export type UpdateMissionMutationVariables = Exact<{
  id: Scalars['ID'];
  data: Maybe<MissionUpdateInput>;
}>;


export type UpdateMissionMutation = (
  { __typename?: 'Mutation' }
  & { updateMission: Maybe<(
    { __typename?: 'Mission' }
    & Pick<Mission, 'id' | 'name' | 'description' | 'date' | 'duration'>
    & { address: Maybe<(
      { __typename?: 'LocationGoogle' }
      & Pick<LocationGoogle, 'googlePlaceID' | 'formattedAddress' | 'lat' | 'lng'>
    )> }
  )> }
);

export type GetAllQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllQuery = (
  { __typename?: 'Query' }
  & { allCharities: Maybe<Array<Maybe<(
    { __typename?: 'Charity' }
    & CharityFragment
  )>>>, allMissions: Maybe<Array<Maybe<(
    { __typename?: 'Mission' }
    & MissionFragment
  )>>> }
);

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCategoriesQuery = (
  { __typename?: 'Query' }
  & { allCategories: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'name' | 'lightColor' | 'darkColor' | 'reference'>
  )>>> }
);

export type GetAllCharitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCharitiesQuery = (
  { __typename?: 'Query' }
  & { allCharities: Maybe<Array<Maybe<(
    { __typename?: 'Charity' }
    & CharityFragment
  )>>> }
);

export type GetAllMissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMissionsQuery = (
  { __typename?: 'Query' }
  & { allMissions: Maybe<Array<Maybe<(
    { __typename?: 'Mission' }
    & MissionFragment
  )>>> }
);

export type GetAuthenticatedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthenticatedUserQuery = (
  { __typename?: 'Query' }
  & { authenticatedUser: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type GetCharitiesOfCategoryQueryVariables = Exact<{
  categoryId: Scalars['ID'];
}>;


export type GetCharitiesOfCategoryQuery = (
  { __typename?: 'Query' }
  & { allCharities: Maybe<Array<Maybe<(
    { __typename?: 'Charity' }
    & CharityFragment
  )>>> }
);

export type GetCharityByIdQueryVariables = Exact<{
  assoId: Scalars['ID'];
}>;


export type GetCharityByIdQuery = (
  { __typename?: 'Query' }
  & { Charity: Maybe<(
    { __typename?: 'Charity' }
    & Pick<Charity, 'id'>
    & { missions: Array<(
      { __typename?: 'Mission' }
      & MissionFragment
    )> }
    & CharityFragment
  )> }
);

export type GetMissionByIdQueryVariables = Exact<{
  missionId: Scalars['ID'];
}>;


export type GetMissionByIdQuery = (
  { __typename?: 'Query' }
  & { Mission: Maybe<(
    { __typename?: 'Mission' }
    & MissionFragment
  )> }
);

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserByIdQuery = (
  { __typename?: 'Query' }
  & { User: Maybe<(
    { __typename?: 'User' }
    & { charity: Maybe<(
      { __typename?: 'Charity' }
      & CharityFragment
    )> }
  )> }
);

export const CategoryFragmentDoc = `
    fragment Category on Category {
  name
  lightColor
  darkColor
  reference
}
    `;
export const CharityFragmentDoc = `
    fragment Charity on Charity {
  id
  name
  description
  address {
    googlePlaceID
    lat
    lng
    formattedAddress
  }
  user {
    email
  }
  telephone
  website
  image {
    filename
    publicUrlTransformed
  }
  category {
    id
    name
  }
  missions {
    id
    name
    description
    address {
      googlePlaceID
      lat
      lng
      formattedAddress
    }
    date
    duration
    image {
      filename
    }
  }
}
    `;
export const MissionFragmentDoc = `
    fragment Mission on Mission {
  id
  name
  description
  date
  duration
  address {
    googlePlaceID
    lat
    lng
    formattedAddress
  }
  image {
    filename
    publicUrlTransformed
  }
  category {
    name
    id
  }
  charity {
    name
    address {
      googlePlaceID
      lat
      lng
      formattedAddress
    }
    telephone
    website
    user {
      email
    }
  }
}
    `;
export const UserFragmentDoc = `
    fragment User on User {
  id
  charity {
    ...Charity
    missions {
      ...Mission
    }
  }
}
    ${CharityFragmentDoc}
${MissionFragmentDoc}`;
export const AuthenticateUserWithPasswordDocument = `
    mutation AuthenticateUserWithPassword($email: String, $password: String) {
  authenticateUserWithPassword(email: $email, password: $password) {
    token
    item {
      id
    }
  }
}
    `;
export const useAuthenticateUserWithPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AuthenticateUserWithPasswordMutation, TError, AuthenticateUserWithPasswordMutationVariables, TContext>) => 
    useMutation<AuthenticateUserWithPasswordMutation, TError, AuthenticateUserWithPasswordMutationVariables, TContext>(
      (variables?: AuthenticateUserWithPasswordMutationVariables) => fetcher<AuthenticateUserWithPasswordMutation, AuthenticateUserWithPasswordMutationVariables>(AuthenticateUserWithPasswordDocument, variables)(),
      options
    );
export const CreateCharityDocument = `
    mutation CreateCharity($name: String, $description: String, $address: String, $telephone: String, $website: String, $image: Upload, $category: CategoryRelateToManyInput, $user: UserRelateToOneInput) {
  createCharity(
    data: {name: $name, description: $description, address: $address, telephone: $telephone, website: $website, image: $image, category: $category, user: $user}
  ) {
    name
    id
    user {
      id
    }
  }
}
    `;
export const useCreateCharityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCharityMutation, TError, CreateCharityMutationVariables, TContext>) => 
    useMutation<CreateCharityMutation, TError, CreateCharityMutationVariables, TContext>(
      (variables?: CreateCharityMutationVariables) => fetcher<CreateCharityMutation, CreateCharityMutationVariables>(CreateCharityDocument, variables)(),
      options
    );
export const CreateMissionDocument = `
    mutation CreateMission($name: String, $description: String, $address: String, $date: String, $image: Upload, $duration: String, $category: CategoryRelateToManyInput, $charity: CharityRelateToOneInput) {
  createMission(
    data: {name: $name, description: $description, address: $address, date: $date, image: $image, duration: $duration, category: $category, charity: $charity}
  ) {
    name
    description
    address {
      lat
      lng
    }
    date
    image {
      filename
    }
    duration
    category {
      name
    }
    charity {
      name
    }
  }
}
    `;
export const useCreateMissionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateMissionMutation, TError, CreateMissionMutationVariables, TContext>) => 
    useMutation<CreateMissionMutation, TError, CreateMissionMutationVariables, TContext>(
      (variables?: CreateMissionMutationVariables) => fetcher<CreateMissionMutation, CreateMissionMutationVariables>(CreateMissionDocument, variables)(),
      options
    );
export const CreateUserDocument = `
    mutation CreateUser($email: String, $password: String) {
  createUser(data: {email: $email, password: $password}) {
    id
  }
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>) => 
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      (variables?: CreateUserMutationVariables) => fetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables)(),
      options
    );
export const DeleteMissionDocument = `
    mutation DeleteMission($id: ID!) {
  deleteMission(id: $id) {
    name
  }
}
    `;
export const useDeleteMissionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMissionMutation, TError, DeleteMissionMutationVariables, TContext>) => 
    useMutation<DeleteMissionMutation, TError, DeleteMissionMutationVariables, TContext>(
      (variables?: DeleteMissionMutationVariables) => fetcher<DeleteMissionMutation, DeleteMissionMutationVariables>(DeleteMissionDocument, variables)(),
      options
    );
export const UpdateMissionDocument = `
    mutation UpdateMission($id: ID!, $data: MissionUpdateInput) {
  updateMission(id: $id, data: $data) {
    id
    name
    description
    address {
      googlePlaceID
      formattedAddress
      lat
      lng
    }
    date
    duration
  }
}
    `;
export const useUpdateMissionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMissionMutation, TError, UpdateMissionMutationVariables, TContext>) => 
    useMutation<UpdateMissionMutation, TError, UpdateMissionMutationVariables, TContext>(
      (variables?: UpdateMissionMutationVariables) => fetcher<UpdateMissionMutation, UpdateMissionMutationVariables>(UpdateMissionDocument, variables)(),
      options
    );
export const GetAllDocument = `
    query getAll {
  allCharities {
    ...Charity
  }
  allMissions {
    ...Mission
  }
}
    ${CharityFragmentDoc}
${MissionFragmentDoc}`;
export const useGetAllQuery = <
      TData = GetAllQuery,
      TError = unknown
    >(
      variables?: GetAllQueryVariables, 
      options?: UseQueryOptions<GetAllQuery, TError, TData>
    ) => 
    useQuery<GetAllQuery, TError, TData>(
      variables === undefined ? ['getAll'] : ['getAll', variables],
      fetcher<GetAllQuery, GetAllQueryVariables>(GetAllDocument, variables),
      options
    );
export const GetAllCategoriesDocument = `
    query getAllCategories {
  allCategories {
    name
    lightColor
    darkColor
    reference
  }
}
    `;
export const useGetAllCategoriesQuery = <
      TData = GetAllCategoriesQuery,
      TError = unknown
    >(
      variables?: GetAllCategoriesQueryVariables, 
      options?: UseQueryOptions<GetAllCategoriesQuery, TError, TData>
    ) => 
    useQuery<GetAllCategoriesQuery, TError, TData>(
      variables === undefined ? ['getAllCategories'] : ['getAllCategories', variables],
      fetcher<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, variables),
      options
    );
export const GetAllCharitiesDocument = `
    query GetAllCharities {
  allCharities {
    ...Charity
  }
}
    ${CharityFragmentDoc}`;
export const useGetAllCharitiesQuery = <
      TData = GetAllCharitiesQuery,
      TError = unknown
    >(
      variables?: GetAllCharitiesQueryVariables, 
      options?: UseQueryOptions<GetAllCharitiesQuery, TError, TData>
    ) => 
    useQuery<GetAllCharitiesQuery, TError, TData>(
      variables === undefined ? ['GetAllCharities'] : ['GetAllCharities', variables],
      fetcher<GetAllCharitiesQuery, GetAllCharitiesQueryVariables>(GetAllCharitiesDocument, variables),
      options
    );
export const GetAllMissionsDocument = `
    query GetAllMissions {
  allMissions {
    ...Mission
  }
}
    ${MissionFragmentDoc}`;
export const useGetAllMissionsQuery = <
      TData = GetAllMissionsQuery,
      TError = unknown
    >(
      variables?: GetAllMissionsQueryVariables, 
      options?: UseQueryOptions<GetAllMissionsQuery, TError, TData>
    ) => 
    useQuery<GetAllMissionsQuery, TError, TData>(
      variables === undefined ? ['GetAllMissions'] : ['GetAllMissions', variables],
      fetcher<GetAllMissionsQuery, GetAllMissionsQueryVariables>(GetAllMissionsDocument, variables),
      options
    );
export const GetAuthenticatedUserDocument = `
    query GetAuthenticatedUser {
  authenticatedUser {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const useGetAuthenticatedUserQuery = <
      TData = GetAuthenticatedUserQuery,
      TError = unknown
    >(
      variables?: GetAuthenticatedUserQueryVariables, 
      options?: UseQueryOptions<GetAuthenticatedUserQuery, TError, TData>
    ) => 
    useQuery<GetAuthenticatedUserQuery, TError, TData>(
      variables === undefined ? ['GetAuthenticatedUser'] : ['GetAuthenticatedUser', variables],
      fetcher<GetAuthenticatedUserQuery, GetAuthenticatedUserQueryVariables>(GetAuthenticatedUserDocument, variables),
      options
    );
export const GetCharitiesOfCategoryDocument = `
    query GetCharitiesOfCategory($categoryId: ID!) {
  allCharities(where: {category_some: {id: $categoryId}}) {
    ...Charity
  }
}
    ${CharityFragmentDoc}`;
export const useGetCharitiesOfCategoryQuery = <
      TData = GetCharitiesOfCategoryQuery,
      TError = unknown
    >(
      variables: GetCharitiesOfCategoryQueryVariables, 
      options?: UseQueryOptions<GetCharitiesOfCategoryQuery, TError, TData>
    ) => 
    useQuery<GetCharitiesOfCategoryQuery, TError, TData>(
      ['GetCharitiesOfCategory', variables],
      fetcher<GetCharitiesOfCategoryQuery, GetCharitiesOfCategoryQueryVariables>(GetCharitiesOfCategoryDocument, variables),
      options
    );
export const GetCharityByIdDocument = `
    query GetCharityById($assoId: ID!) {
  Charity(where: {id: $assoId}) {
    id
    ...Charity
    missions {
      ...Mission
    }
  }
}
    ${CharityFragmentDoc}
${MissionFragmentDoc}`;
export const useGetCharityByIdQuery = <
      TData = GetCharityByIdQuery,
      TError = unknown
    >(
      variables: GetCharityByIdQueryVariables, 
      options?: UseQueryOptions<GetCharityByIdQuery, TError, TData>
    ) => 
    useQuery<GetCharityByIdQuery, TError, TData>(
      ['GetCharityById', variables],
      fetcher<GetCharityByIdQuery, GetCharityByIdQueryVariables>(GetCharityByIdDocument, variables),
      options
    );
export const GetMissionByIdDocument = `
    query GetMissionById($missionId: ID!) {
  Mission(where: {id: $missionId}) {
    ...Mission
  }
}
    ${MissionFragmentDoc}`;
export const useGetMissionByIdQuery = <
      TData = GetMissionByIdQuery,
      TError = unknown
    >(
      variables: GetMissionByIdQueryVariables, 
      options?: UseQueryOptions<GetMissionByIdQuery, TError, TData>
    ) => 
    useQuery<GetMissionByIdQuery, TError, TData>(
      ['GetMissionById', variables],
      fetcher<GetMissionByIdQuery, GetMissionByIdQueryVariables>(GetMissionByIdDocument, variables),
      options
    );
export const GetUserByIdDocument = `
    query GetUserById($userId: ID!) {
  User(where: {id: $userId}) {
    charity {
      ...Charity
    }
  }
}
    ${CharityFragmentDoc}`;
export const useGetUserByIdQuery = <
      TData = GetUserByIdQuery,
      TError = unknown
    >(
      variables: GetUserByIdQueryVariables, 
      options?: UseQueryOptions<GetUserByIdQuery, TError, TData>
    ) => 
    useQuery<GetUserByIdQuery, TError, TData>(
      ['GetUserById', variables],
      fetcher<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, variables),
      options
    );