import React, { useEffect } from 'react'
import { Box, Link, Text, Image, Flex, Spinner } from '@chakra-ui/react'
import { useParams, Link as RouteLink } from 'react-router-dom'
import { useGetCharityByIdQuery } from '../generated/graphql'
import Card from '../components/molecules/Card'
import UsefulInfos from '../components/organisms/UsefulInfos'
import mainTheme from '../theme/mainTheme'
import { Helmet } from 'react-helmet'

export interface idCharity {
  assoId: string
}

const CharityDescription: React.FC = () => {
  const { assoId } = useParams<idCharity>()
  const { data: charityData, isLoading } = useGetCharityByIdQuery({
    assoId,
  })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isLoading) {
    return (
      <Flex justifyContent='center' height='100%' alignItems='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color={mainTheme.colors.primaryBlue}
          size='xl'
        />
      </Flex>
    )
  }

  return (
    <Box
      padding={[
        '12px 32px 10px 32px',
        '16px 50px 15px 50px',
        '21px 100px 20px 100px',
        '45px 200px 50px 200px',
      ]}>
      <Helmet
        title={`Description de l'association: ${charityData.Charity.name}`}
        meta={[
          {
            name: 'description',
            content: charityData.Charity.description,
          },
          {
            name: 'keywords',
            content: 'association, bénévoles, description, adresse, mission',
          },
        ]}
      />
      <Text textStyles='body' color='#FF636F' fontWeight='700'>
        Fiche association
      </Text>
      <Text textStyle='pageTitle' marginBottom='20px'>
        {charityData?.Charity.name}
      </Text>
      <Flex justifyContent='center'>
        <Image
          src={charityData?.Charity.image.publicUrlTransformed}
          height={[150, 200, 350]}
          width='100%'
          objectFit='cover'
          borderRadius={24}
          objectPosition='60% 25%'
          marginBottom='8'
        />
      </Flex>
      <Text textStyle='body'>{charityData?.Charity.description}</Text>
      <Box>
        <Text textStyle='h2' margin='40px 0px 40px 0px'>
          Missions proposées
        </Text>
        <Flex flexWrap='wrap'>
          {charityData?.Charity.missions.length ? (
            charityData?.Charity.missions.map((mission, index) => {
              return (
                <Link
                  as={RouteLink}
                  to={`/mission/${mission.id}`}
                  key={index}
                  _hover={{ textDecoration: 'none' }}>
                  <Card
                    name={mission.name}
                    description={mission.description}
                    image={mission.image.publicUrlTransformed}
                    height={[110, 145]}
                  />
                </Link>
              )
            })
          ) : (
            <Box textAlign='center' width='100%'>
              <Text textStyles='body'>Pas de mission pour le moment 😕</Text>
              <Flex justifyContent='center' mt={3}>
                <Text fontWeight='bold'>
                  C'est votre association ? Contactez-moi &nbsp;
                </Text>
                <Link
                  fontWeight='bold'
                  textDecoration='underline'
                  href='mailto: lisa-lou.k@hotmail.fr'>
                  ici
                </Link>
              </Flex>
            </Box>
          )}
        </Flex>
      </Box>
      <UsefulInfos
        email={charityData!.Charity!.user?.email}
        telephone={charityData!.Charity!.telephone}
        website={charityData!.Charity!.website}
        address={charityData!.Charity!.address.formattedAddress}
      />
    </Box>
  )
}

export default CharityDescription
