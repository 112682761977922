import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { useDeleteMissionMutation } from '../../generated/graphql'

type props = {
  isOpen: boolean
  onClose: () => void
  missionId: string
  missionName: string
}

const ModalDeleteMission: React.FC<props> = ({
  isOpen,
  onClose,
  missionId,
  missionName,
}) => {
  const queryClient = useQueryClient()
  const { mutate: deleteMissionWithId } = useDeleteMissionMutation({
    onSuccess: () => queryClient.invalidateQueries('GetAuthenticatedUser'),
  })

  const deleteMission = async () => {
    try {
      await deleteMissionWithId({ id: missionId })
      onClose()
    } catch (err) {
      console.log('An error occured', err)
    }
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Supprimer la mission</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{missionName}</ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Non
            </Button>
            <Button variant='ghost' onClick={deleteMission}>
              Oui
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalDeleteMission
