import React from 'react'
import { Box, Text, Flex } from '@chakra-ui/react'
import EnvelopIcon from '../../static/svg/EnvelopIcon'
import PhoneIcon from '../../static/svg/PhoneIcon'
import PinIcon from '../../static/svg/PinIcon'
import WebsiteIcon from '../../static/svg/WebsiteIcon'
import mainTheme from '../../theme/mainTheme'

type Props = {
  address: string
  telephone: string
  email: string
  website: string
  isMission?: boolean
}

const UsefulInfos: React.FC<Props> = ({
  address,
  telephone,
  email,
  website,
  isMission = false,
}) => {
  const handleClickWebsite = (url: string) => {
    if (website) window.location.href = website
  }
  const handleClickPhone = (phone: string) => {
    if (phone) window.open(`tel: ${phone}`)
  }
  const handleClickEmail = (email: string) => {
    if (email) window.open(`mailto: ${email}`)
  }

  return (
    <Box>
      {isMission ? null : (
        <Text textStyle='h2' margin='40px 0px 40px 0px'>
          Informations utiles
        </Text>
      )}
      <Box>
        <Flex justifyContent='space-around' flexWrap='wrap'>
          <Flex
            height={['100', '150']}
            alignItems='center'
            padding={['0', '30']}
            _hover={{
              backgroundColor: mainTheme.colors.lightOrange,
              borderRadius: '20',
              cursor: 'pointer',
            }}
          >
            <PinIcon />
            <Box marginLeft='35'>
              <Text textStyles='h2' color='#FF636F' fontWeight='700'>
                Adresse
              </Text>
              <Text maxWidth={['200', '350']} minWidth={['200', '350']}>
                {address}
              </Text>
            </Box>
          </Flex>
          <Flex justifyContent='space-around' flexWrap='wrap'>
            <Flex
              height={['100', '150']}
              alignItems='center'
              padding={['0', '30']}
              _hover={{
                backgroundColor: mainTheme.colors.lightOrange,
                borderRadius: '20',
                cursor: 'pointer',
              }}
            >
              <PhoneIcon />
              <Box marginLeft='35' onClick={() => handleClickPhone(telephone)}>
                <Text textStyles='h2' color='#FF636F' fontWeight='700'>
                  Téléphone
                </Text>
                <Text maxWidth={['200', '350']} minWidth={['200', '350']}>
                  {telephone}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent='space-around' flexWrap='wrap'>
          <Flex
            height={['100', '150']}
            alignItems='center'
            padding={['0', '30']}
            _hover={{
              backgroundColor: mainTheme.colors.lightOrange,
              borderRadius: '20',
              cursor: 'pointer',
            }}
          >
            <EnvelopIcon />
            <Box marginLeft='35' onClick={() => handleClickEmail(email)}>
              <Text textStyles='h2' color='#FF636F' fontWeight='700'>
                Email
              </Text>
              <Text maxWidth={['200', '350']} minWidth={['200', '350']}>
                {email}
              </Text>
            </Box>
          </Flex>
          <Flex
            height={['100', '150']}
            alignItems='center'
            padding={['0', '30']}
            _hover={{
              backgroundColor: mainTheme.colors.lightOrange,
              borderRadius: '20',
              cursor: 'pointer',
            }}
          >
            <WebsiteIcon />
            <Box marginLeft='35' onClick={() => handleClickWebsite(website)}>
              <Text textStyles='h2' color='#FF636F' fontWeight='700'>
                Site web
              </Text>
              <Text maxWidth={['200', '350']} minWidth={['200', '350']}>
                {website}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

export default UsefulInfos
