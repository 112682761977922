import React, { useRef, useState } from 'react'
import {
  Button,
  Input,
  Box,
  InputGroup,
  InputRightElement,
  SlideFade,
  useDisclosure,
} from '@chakra-ui/react'
import SearchIcon from '../../static/svg/searchIcon'
import { useAllElements } from '../../contexts/allElementsContext'
import { useEffect } from 'react'

const SearchBar: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure()
  const { onSearchElement, charities, missions, searchedElement } =
    useAllElements()

  const [clickedOutside, setClickedOutside] = useState(false)
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const handleClickOutside = (e: { target: any }) => {
    if (isOpen && charities.length <= 0 && missions.length <= 0) {
      if (myRef.current) {
        if (!myRef.current.contains(e.target)) {
          setClickedOutside(true)
          onToggle()
        }
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, clickedOutside])

  return (
    <Box>
      <InputGroup
        marginTop={[0, '4']}
        marginBottom={[0, '4']}
        padding={[0, '2']}
        width='95%'
      >
        <SlideFade in={isOpen} offsetY='0px'>
          <Input
            onChange={event => onSearchElement(event.target.value)}
            value={searchedElement}
          />
        </SlideFade>
        <InputRightElement>
          <Button
            marginTop='4'
            marginRight='4'
            padding='2'
            _focus={{ outline: 0 }}
            backgroundColor='transparent'
            _hover={{
              backgroundColor: 'transparent',
            }}
            onClick={onToggle}
          >
            <SearchIcon />
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

export default SearchBar
