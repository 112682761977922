import React from 'react'

const EnvelopIcon: React.FC = () => {
  return (
    <svg
      width="39"
      height="30"
      viewBox="0 0 39 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77273 0C0.813593 0 0 0.917925 0 2V28C0 29.0821 0.813593 30 1.77273 30H37.2273C38.1864 30 39 29.0821 39 28V2C39 0.917925 38.1864 0 37.2273 0H1.77273ZM4.69498 3H34.305L19.5 18.4531L4.69498 3ZM2.65909 4.95313L11.8135 14.5L2.65909 24.8281V4.95313ZM36.3409 4.95313V24.8281L27.1865 14.5L36.3409 4.95313ZM13.7663 16.5468L18.5998 21.5938C18.8452 21.8485 19.1665 21.9898 19.5 21.9898C19.8335 21.9898 20.1548 21.8485 20.4002 21.5938L25.2337 16.5468L34.499 27H19.5H4.50104L13.7663 16.5468Z"
        fill="#FF636F"
      />
    </svg>
  )
}

export default EnvelopIcon
