import React, { useRef } from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Flex, Link, Box } from '@chakra-ui/react'
import mainTheme from '../../theme/mainTheme'
import { useSessionUser } from '../../contexts/sessionContext'
import SearchBar from '../molecules/SearchBar'

const Nav: React.FC = () => {
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const { token } = useSessionUser()
  const windowSize = window.innerWidth

  return (
    <Flex alignItems='center' ref={myRef}>
      {windowSize >= 1020 ? <SearchBar /> : null}
      <Box>
        <Link
          as={RouteLink}
          to='/missions'
          fontFamily='Raleway'
          width='100%'
          margin='4'
          padding='2'
          fontSize={['16', '18', '20']}
          textDecoration='none !important'
          _focus={{ outline: 0 }}
          _hover={{
            backgroundColor: mainTheme.colors.lightOrange,
            borderRadius: '16',
          }}
          textAlign='center'
          minWidth='fit-content'>
          Les missions
        </Link>
      </Box>
      <Box>
        <Link
          as={RouteLink}
          to='/associations'
          fontFamily='Raleway'
          width='100%'
          margin='4'
          padding='2'
          fontSize={['16', '18', '20']}
          textDecoration='none !important'
          _focus={{ outline: 0 }}
          _hover={{
            backgroundColor: mainTheme.colors.lightOrange,
            borderRadius: '16',
          }}
          textAlign='center'
          minWidth='fit-content'>
          Les associations
        </Link>
      </Box>
      <Box>
        {token ? (
          <Link
            as={RouteLink}
            to='/back-office'
            fontFamily='Raleway'
            margin='4'
            padding='2'
            fontSize={['16', '18', '20']}
            textDecoration='none !important'
            _focus={{ outline: 0 }}
            _hover={{
              backgroundColor: mainTheme.colors.lightOrange,
              borderRadius: '16',
            }}
            textAlign='center'
            minWidth='fit-content'>
            Mon asso
          </Link>
        ) : (
          <Link
            as={RouteLink}
            fontSize={['14', '16']}
            width='140px'
            to='/login'
            margin='4'
            padding='2'
            textDecoration='none !important'
            _focus={{ outline: 0 }}
            backgroundColor={mainTheme.colors.secondaryBlue}
            color={mainTheme.colors.primaryBlue}
            borderRadius={16}
            fontWeight='bold'
            height='40px'
            display='flex'
            alignItems='center'
            justifyContent='center'
            minWidth='fit-content'>
            Se connecter
          </Link>
        )}
      </Box>
    </Flex>
  )
}

export default Nav
